<template>
  <button @click="toggleFavorite" :class="(component_info.isFavorite ? 'maincolor' : 'text-gray-400')" class="border border-gray-200 text-sm rounded-md p-1 focus:outline-none" :key="component_info.key">
    <i class="fas fa-star"></i>
  </button>
</template>
  
<script setup lang="ts">
import { Book } from '@/entities';
import { ref, defineProps, onMounted } from 'vue';
import { ArticleRepository } from '@/repositories';
import { MessageBox } from '@/utility';
import { useStore } from 'vuex';

interface favoConfigProperty {
  bookid: string;
}

const property = defineProps<favoConfigProperty>();
const articleRep = new ArticleRepository();
const store = useStore();

var component_info = ref({
  key: 0,
  book: new Book(),
  isFavorite: false,
});

onMounted(async () => {
  //console.log('Favorite : ', property.bookid);

  if (property.bookid !== null && property.bookid !== undefined && String(property.bookid).trim() !== '') {
    await checkBook();
  }
  component_info.value.key += 1;
});

const checkBook = async () => {
  let rst = await articleRep.GetFavorite(property.bookid);
  if (rst.check) {
    component_info.value.book = rst.data;
    component_info.value.isFavorite = true;
    //console.log('checkBook : ', rst.data)
    store.dispatch('favorites/addFavorite', rst.data); // 스토어에 추가
  } else {
    component_info.value.book = new Book();
    component_info.value.isFavorite = false;
  }
};

const toggleFavorite = async () => {
  if (component_info.value.isFavorite) {
    let remove = await articleRep.DeleteFavorite(property.bookid);
    if (remove.check) {
      store.dispatch('favorites/removeFavorite', component_info.value.book.id); // 스토어에서 제거
      component_info.value.book = new Book();
      component_info.value.isFavorite = false;
    } else {
      MessageBox.Alert(remove.message);
    }
  } else {
    let regist = await articleRep.RegistFavorite(property.bookid);
    if (regist.check) {
      component_info.value.book = regist.data.book;
      component_info.value.isFavorite = true;
      store.dispatch('favorites/addFavorite', regist.data.book); // 스토어에 추가
    } else {
      MessageBox.Alert(regist.message);
    }
  }
}
</script>
