export class Condition {
    public createdBy:string;
    public updatedBy:string;
    public deletedBy:string;
    public registDate:Date;
    public lastUpdate:Date;
    public deletedDate:Date;
    public status:string;
    public isEnabled:boolean;

    constructor() {
        this.createdBy = "";
        this.updatedBy = "";
        this.deletedBy = "";
        this.registDate = new Date();
        this.lastUpdate = new Date();
        this.deletedDate = new Date();
        this.status = "";
        this.isEnabled = true;
    }
}