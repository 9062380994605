<template>
  <div class="w-full flex justify-between mb-1">
    <div class="ml-2">
      <h3 class="text-2xl subcolor uppercase"><b>Password Change</b></h3>
    </div>
    <div class="text-sm mr-2">
      <router-link to="/main" class="text-gray-400 uppercase text-sm"><small>HOME</small></router-link>
      <span class="px-2 text-gray-300">&gt;</span>
      <a href="#" class="text-gray-500 uppercase text-md"><b>PasswordChange</b></a>
    </div>
  </div>

<div class="bg-white shadow-sm p-4">
<form @submit.prevent="validateForm" class="space-y-4">
    <div class="form-group mt-3">
    <label for="currentPassword" class="block text-gray-700">Current Password</label>
    <input v-model="form.currentPassword" type="password" id="currentPassword" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-3/4 ease-linear transition-all duration-150" placeholder="Enter your current password">
    <p v-if="errors.currentPassword" class="text-red-500 text-xs">{{ errors.currentPassword }}</p>
    </div>
    <div class="form-group mt-3">
    <label for="newPassword" class="block text-gray-700">New Password</label>
    <input v-model="form.newPassword" type="password" id="newPassword" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-3/4 ease-linear transition-all duration-150" placeholder="Enter your new password">
    <p v-if="errors.newPassword" class="text-red-500 text-xs">{{ errors.newPassword }}</p>
    </div>
    <div class="form-group mt-3">
    <label for="confirmNewPassword" class="block text-gray-700">Confirm New Password</label>
    <input v-model="form.confirmNewPassword" type="password" id="confirmNewPassword" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-3/4 ease-linear transition-all duration-150" placeholder="Confirm your new password">
    <p v-if="errors.confirmNewPassword" class="text-red-500 text-xs">{{ errors.confirmNewPassword }}</p>
    </div>
    <div class="flex justify-center mt-6">
    <button type="submit" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Change Password</button>
    </div>
</form>
</div>
</template>

<script setup lang="ts">
import { ref, reactive } from 'vue';
import { MemberRepository } from '@/repositories';
import { MessageBox } from '@/utility';

const memberRep = new MemberRepository();

interface Form {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}

const form = reactive<Form>({
  currentPassword: '',
  newPassword: '',
  confirmNewPassword: '',
});

const errors = reactive<Partial<Form>>({});

const validateForm = async () => {
  clearErrors();

  if (!form.currentPassword) errors.currentPassword = 'Current password is required.';

  if (!form.newPassword) {
    errors.newPassword = 'New password is required.';
  } else {
    if (form.newPassword.length < 8) {
      errors.newPassword = 'New password must be at least 8 characters long.';
    }
    
    if (!/[A-Z]/.test(form.newPassword)) {
      errors.newPassword = 'New password must include at least one uppercase letter.';
    }
    
    if (!/[!@#$%^&*(),.?":{}|<>]/.test(form.newPassword)) {
      errors.newPassword = 'New password must include at least one special character.';
    }
  }

  if (form.newPassword !== form.confirmNewPassword) {
    errors.confirmNewPassword = 'Passwords do not match.';
  }

  if (Object.values(errors).every(error => !error)) {
    let rst = await memberRep.PutPasswordChange(form.currentPassword, form.newPassword);
    if (rst.check) {
      MessageBox.Success("비밀번호가 변경되었습니다.", () => {
        form.currentPassword = '';
        form.newPassword = '';
        form.confirmNewPassword = '';
      });
    } else {
      MessageBox.Alert(rst.message);
    }
  }
};

const clearErrors = () => {
  Object.keys(errors).forEach(key => {
    errors[key as keyof Form] = '';
  });
};
</script>

<style scoped>
.form-input {
  border: 1px solid #d1d5db;
  padding: 0.5rem;
  border-radius: 0.375rem;
}
.form-input:focus {
  border-color: #3b82f6;
  outline: none;
}
</style>
