<template>
  <div class="min-h-screen flex flex-col homebg">
    <!-- Top Menu -->
    <header class="bg-pink-50 shadow-md nav-bg">
      <div class="container mx-auto px-6 py-4 flex items-center justify-between">
        <div class="">
          <router-link to="/">
            <img src="/logo/Logo_Pink.png" style="width:200px;height:auto;" alt="" />
          </router-link>
        </div>
        <nav class="space-x-6 flex items-center">

        <!-- Desktop View (hidden on mobile) -->
          <div class="hidden md:flex space-x-6 items-center">
            <router-link to="/guide/youtube" class="text-pink-600 hover:text-pink-400">Guide</router-link>
            <router-link to="/Contact" class="text-pink-600 hover:text-pink-400">Contact</router-link>
            <router-link to="/Login" v-if="!layout_info.isLogin" class="text-pink-600 hover:text-pink-400">Login</router-link>
            <router-link to="/main" v-if="layout_info.isLogin" class="text-pink-600 hover:text-pink-400">Main</router-link>
            <a href="#" @click="fnLogout" v-if="layout_info.isLogin" class="text-pink-600 hover:text-pink-400">Logout</a>
          </div>

          <!-- Mobile View (shown as select box on mobile) -->
          <div class="md:hidden flex items-center">
            <select @change="navigateTo($event)" class="bg-white border border-gray-300 text-gray-900 rounded-md shadow-sm focus:ring-pink-500 focus:border-pink-500 block pl-3 pr-10 py-2 text-base">
              <option value="/Books">Books</option>
              <option value="/Reviews">Reviews</option>
              <option value="/Contact">Contact</option>
              <option v-if="!layout_info.isLogin" value="/login">Login</option>
              <option v-if="layout_info.isLogin" value="/main">Main</option>
              <option v-if="layout_info.isLogin" value="logout">Logout</option>
            </select>
          </div>

          <!-- Language Selector -->
          <div class="relative inline-block text-left">
            <select v-model="selectedLanguage" class="bg-white border border-gray-300 text-gray-900 rounded-md shadow-sm focus:ring-pink-500 focus:border-pink-500 block pl-3 pr-10 py-2 text-base outline-none opacity-65">
              <option value="en">English</option>
              <option value="ko">한국어</option>
            </select>
          </div>
        </nav>
      </div>
    </header>

    <!-- Main Content -->
    <main class="flex-1 container mx-auto px-6 py-8">
      <router-view />
    </main>

    <!-- Bottom Menu -->
    <footer class="bg-pink-50 text-gray-600 py-4 bg-pink-200 nav-bg">
  <div class="container mx-auto px-6 flex flex-col md:flex-row items-center justify-between">
    <p>&copy; {{ date }} GN2Studio.com. All rights reserved.</p>
    <nav class="space-x-6 flex flex-col md:flex-row items-center mt-4 md:mt-0">
      <div class="hidden md:flex space-x-6 items-center">
        <router-link to="/privacy" class="text-pink-600 hover:text-pink-400">{{t("system.Privacy")}}</router-link>
        <router-link to="/terms" class="text-pink-600 hover:text-pink-400">{{t("system.Terms")}}</router-link>
      </div>

      <!-- Mobile View (shown as select box on mobile) -->
      <div class="md:hidden flex items-center">
        <select @change="navigateTo($event)" class="bg-white border border-gray-300 text-gray-900 rounded-md shadow-sm focus:ring-pink-500 focus:border-pink-500 block pl-3 pr-10 py-2 text-base">
          <option value="/privacy">{{t("system.Privacy")}}</option>
          <option value="/terms">{{t("system.Terms")}}</option>
        </select>
      </div>

      <!-- Social Media Links -->
      <div class="flex space-x-4 mt-4 md:mt-0">
        <a :href="config.link.facebook" target="_blank" class="text-pink-600 hover:text-pink-400 cursor">
          <i class="fab fa-facebook-f fa-lg"></i>
        </a>
        <a :href="config.link.youtube" target="_blank" class="text-pink-600 hover:text-pink-400 cursor">
          <i class="fab fa-youtube fa-lg"></i>
        </a>
        <a :href="config.link.blog" target="_blank" class="text-pink-600 hover:text-pink-400 cursor">
          <i class="fas fa-blog fa-lg"></i>
        </a>
      </div>
    </nav>
  </div>
</footer>
  </div>
</template>


<script setup lang="ts">
  import { onMounted,computed, ref,watch,getCurrentInstance } from 'vue';
  import { AuthService } from '@/services';
  import store from '@/store';
  import { useI18n } from 'vue-i18n';
  import { MessageBox } from '@/utility';
  import { useRouter } from 'vue-router';
  import { ValidateHelper } from '@/utility';
  import config from '@/config';

  const router = useRouter();
  const { proxy } = getCurrentInstance()!;
  const { t, locale } = useI18n();
  const auth = new AuthService();
  const validate = new ValidateHelper();

  const date = ref(new Date().getFullYear());

  var selectedLanguage = ref(localStorage.getItem('selectedLanguage') || 'ko');

  var layout_info = ref({
    isLogin:false
  });

  onMounted(async () => {
    locale.value = selectedLanguage.value;
    try {
      let user = await auth.getUser();
      if (!validate.IsNullOrWhiteSpace(user?.access_token)) {
        layout_info.value.isLogin = true;
      }

      if (proxy?.LoadingClose) {
        proxy.LoadingClose();
      }
    } catch (e:any) {
      await auth.logout()
      console.log(e.message);
    } 
  });

  watch(selectedLanguage, () => {
    locale.value = selectedLanguage.value;
    localStorage.setItem('selectedLanguage', selectedLanguage.value);
  });

  const fnLogout = () => {
    MessageBox.Confirm(t('system.logout_ment'), () => {
      auth.logout();
    });
  };

  const navigateTo = (event:any) => {
    const value = event.target.value;
    if (value === 'logout') {
      fnLogout();
    } else {
      router.push(value);
    }
  }
</script>
