<template>
  <div class="w-full flex justify-between mb-1">
    <div class="ml-2">
      <h3 class="text-2xl subcolor uppercase"><b>{{ t('site.Books') }}</b></h3>
    </div>
    <div class="text-sm mr-2">
      <router-link to="/main" class="text-gray-400 uppercase text-sm"><small>HOME</small></router-link>
      <span class="px-2 text-gray-300">&gt;</span>
      <a href="#" class="text-gray-500 uppercase text-md"><b>{{ t('site.Books') }}</b></a>
    </div>
  </div>
  <div :key="pageinfo.key"
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white">
    <div class="block w-full overflow-x-auto">
      <table class="items-center w-full bg-transparent border-collapse">
        <thead>
          <tr>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="'bg-blueGray-50 text-blueGray-500 border-blueGray-100'"
            >
              {{ t('site.book_table_bookname') }}
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="'bg-blueGray-50 text-blueGray-500 border-blueGray-100'"
            >
            {{ t('site.form_select_category') }}
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="'bg-blueGray-50 text-blueGray-500 border-blueGray-100'"
            >
            {{t('site.form_select_subcategory')}}
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="'bg-blueGray-50 text-blueGray-500 border-blueGray-100'"
            >
            {{t('common.status')}}
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="'bg-blueGray-50 text-blueGray-500 border-blueGray-100'"
            >
              {{ t('site.book_table_articles')}}
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="'bg-blueGray-50 text-blueGray-500 border-blueGray-100'"
            >
              {{ t('site.book_table_completion')}}
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="'bg-blueGray-50 text-blueGray-500 border-blueGray-100'"
            ></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(book,index) in pageinfo.books" :key="`book_list_${index}`">
            <th
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center"
            >
              <img
                :src="(book.thumbnail !== null && book.thumbnail !== undefined && String(book.thumbnail).trim() !== '') ? book.thumbnail : 'https://via.placeholder.com/48x48'"
                class="h-12 w-12 bg-white rounded-full border"
                alt="..."
                @click="fnArticleManager(book.id)"
              />
              <a href="#" @click="fnArticleManager(book.id)"
                class="ml-3 font-bold light"
              >
                {{ book.title }}
              </a>
            </th>
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
            >
              {{ t(`site.${fnShowCategory(book.majorCategoryId)}`) }}
            </td>
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
            >
              {{ t(`site.${fnSubShowCategory(book.majorCategoryId, book.minorCategoryId)}`) }}
            </td>
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
            >
              <i class="fas fa-circle mr-2" :class="fnConditionColor(book)"></i> {{ t(`site.${fnStatus(book)}`) }}
            </td>
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
            >
              <div class="flex">
                {{ book.articleCount }}
              </div>
            </td>
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
            >
              <div class="flex items-center">
                <div class="relative w-full">
                  <div
                    class="overflow-hidden h-2 text-xs flex rounded bg-red-200"
                  >
                    <div
                      :style="`width: ${fnCompletion(book)}%;`"
                      class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-red-500"
                    ></div>
                  </div>
                </div>
              </div>
            </td>
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right"
            >
              <TableDropdown :siteid="book.id">
                <button
                  type="button"  @click="fnView(book.id)"
                  class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700 text-left"
                >
                  {{ t('site.book_table_button_view') }}
                </button>
                <button
                  type="button" @click="fnReview(book.id)"
                  class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700 text-left"
                >
                  {{ t('site.book_table_button_review') }}
                </button>
                <button
                  type="button" @click="fnArticleManager(book.id)"
                  class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700 text-left"
                >
                {{t('site.form_button_articles')}}
                </button>
              </TableDropdown>

            </td>
          </tr>

        </tbody>
      </table>
    </div>
    <hr/>
    <div class="py-2 flex justify-center items-center">
        <Pagination :total-count="pageinfo.totalcount" :cur-page="pageinfo.curpage" :callback="fnPaging" />
    </div>
    <div class="py-2 items-left px-2">
      <button @click="fnCreateBook" class="px-4 py-2 uppercase mainbg text-white font-semibold rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-opacity-50" type="button">{{ t('site.newBook') }}</button>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { Pagination,TableDropdown } from '@/components';
  import { onMounted, ref } from 'vue';
  import { ArticleRepository,MemberRepository } from '@/repositories';
  import { Book, Categories, MyMemberShip, Permission } from '@/entities';
  import { MessageBox } from '@/utility';
  import { useRouter } from 'vue-router';
  import { useI18n } from 'vue-i18n';

  const articleRep = new ArticleRepository();
  const memberRep = new MemberRepository();
  const router = useRouter();
  const { t, locale } = useI18n();

  var pageinfo = ref({
    key:0,
    books:[] as Book[],
    categories:[] as Categories[],
    totalcount:0,
    curpage:1,
    myMemberShip : new MyMemberShip(),
    permission: new Permission()
  });

  onMounted(async() => {
    await GetMyMemberShip();
    await fnPaging(1);
    await fnCategory();
  });

  const GetMyMemberShip = async () => {
    let rst = await memberRep.GetMyMemberShip();
    if (rst.check && rst.data !== null) {
      pageinfo.value.myMemberShip = rst.data;
      pageinfo.value.permission.Aceept(pageinfo.value.myMemberShip.memberShip);
    }
  }

  const fnPaging = async (curpage:number) => {
    pageinfo.value.curpage = curpage;
    let rst = await articleRep.GetBooks({ curPage : pageinfo.value.curpage, pageSize : 10 });
    if (rst.check) {
      pageinfo.value.books = rst.data;
    }
  };

  const fnCategory = async () => {
    let rst = await articleRep.GetCategories();
    if (rst.check) {
      pageinfo.value.categories = rst.data;
    }
  };

  const fnShowCategory = (categoryId:string):string => {
    let result = "none";
    if (pageinfo.value.categories !== null && pageinfo.value.categories !== undefined && pageinfo.value.categories.length > 0) {
      let first = pageinfo.value.categories.filter(x => x.majorId == categoryId);
      if (first !== null && first !== undefined && first.length > 0) {
        result = first[0].majorName;
      }
    }
    return result;
  };

  const fnSubShowCategory = (categoryId:string, subcategoryId:string):string => {
    let result = "none";
    if (pageinfo.value.categories !== null && pageinfo.value.categories !== undefined && pageinfo.value.categories.length > 0) {
      let first = pageinfo.value.categories.filter(x => x.majorId == categoryId);
      if (first !== null && first !== undefined && first.length > 0) {
        let second = first[0];
        if (second.subCategories !== null && second.subCategories !== undefined && second.subCategories.length > 0) {
          let arr = second.subCategories.filter(x => x.minorId === subcategoryId);
          if (arr !== null && arr !== undefined && arr.length > 0) {
            result = arr[0].minorName;
          }
        }
      }
    }
    return result;
  };

  const fnStatus = (book:Book) => {
    let result = '';

    if (book.isPublish) {
      result = "Open";
    } else if (book.isReviewOpen) {
      result = "Review";
    } else {
      result = "Close";
    }

    return result;
  };

  const fnCompletion = (book:Book):number => {
    let result = 0;
    if (book.articleCount > 0 && book.plannedCount > 0) {
      if (book.articleCount < book.plannedCount) {
        result = Math.round((book.articleCount * 100) / book.plannedCount);
      } else {
        result = 100;
      }
    }
    return result;
  };

  const fnCreateBook = () => {
    if (pageinfo.value.totalcount >= pageinfo.value.permission.WorkCount) {
      MessageBox.Alert("작품을 생성할 수 있는 멤버십이 필요합니다.", () => {
        router.push('/account/membership?tab=membership');
      });
    } else  {
      router.push(`/book/settings`);
    }
  };

  const fnConditionColor = (book:Book) => {
    let result = "";

    if (book.isPublish) {
      result = "text-green-500";
    } else if (book.isReviewOpen) {
      result = "text-orange-500";
    } else {
      result = "text-red-500";
    }

    return result;
  };
  
  const fnView = (bookid:string|null) => {
    router.push(`/book/settings/${bookid}`);
  };

  const fnReview = (bookid:string|null) => {
    router.push(`/book/${bookid}/reviews`);
  };

  const fnArticleManager = (bookid:string|null) => {
    router.push(`/book/articles/${bookid}`);
  };

</script>