<template>
  <Loading :loading="pageinfo.isLoading" />
  
  <div class="w-full flex justify-between mb-1">
    <div class="ml-2">
      <h3 class="text-2xl subcolor uppercase"><b>{{t('common.settings')}}</b></h3>
    </div>
    <div class="text-sm mr-2">
      <router-link to="/main" class="text-gray-400 uppercase text-sm"><small>HOME</small></router-link>
      <span class="px-2 text-gray-300">&gt;</span>
      <a href="#" class="text-gray-500 uppercase text-md"><b>{{t('common.settings')}}</b></a>
    </div>
  </div>

  <div :key="pageinfo.key">
    <div class="bg-white shadow-sm p-4 relative">
    <!-- Section Header -->
      <div>
        <h2 class="text-xl font-semibold mb-4">AI API Key</h2>
        <div class="bg-gray-50 p-4 border rounded-lg shadow-sm">
          <p class="text-gray-700 mb-2">{{ t('site.GenerateMent') }}</p>
          <button @click="openPopup" class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
            New AI API Key
          </button>

          <!-- Table Section -->
          <div class="mt-6">
            <h3 class="text-lg font-medium mb-4">{{ t('site.RegisteredAPIKeys') }}</h3>
            <table class="items-center w-full bg-transparent border-collapse">
              <thead>
                <tr>
                  <th
                    class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                    :class="'bg-blueGray-50 text-blueGray-500 border-blueGray-100'"
                  >
                    ApiKey
                  </th>
                  <th
                    class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                    :class="'bg-blueGray-50 text-blueGray-500 border-blueGray-100'"
                  >
                    ServiceProvider
                  </th>
                  <th
                    class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                    :class="'bg-blueGray-50 text-blueGray-500 border-blueGray-100'"
                  >
                    RegistDate
                  </th>
                  <th
                    class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                    :class="'bg-blueGray-50 text-blueGray-500 border-blueGray-100'"
                  ></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(key, index) in pageinfo.keys" :key="index">
                  <td class="py-2 px-3">{{ StringHelper.maskText(key.apiKey) }}</td>
                  <td class="py-2 px-3">{{ key.provider.name }}</td>
                  <td class="py-2 px-3">{{ dayjs.utc(key.condition.registDate).local().format('YYYY-MM-DD HH:mm') }}</td>
                  <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right">
                    <TableDropdown>
                      <!--<button
                        type="button"  
                        class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700 text-left"
                      >
                        Modify
                      </button>-->
                      <button @click="keyDelete(key.id)"
                        type="button"
                        class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700 text-left"
                      >
                        {{ t('common.Delete') }}
                      </button>
                    </TableDropdown>

                  </td>
                </tr>
              </tbody>
            </table>

            <hr/>
            <div class="py-2 flex justify-center items-center">
                <Pagination :total-count="pageinfo.totalcount" :cur-page="pageinfo.curpage" :callback="fnPaging" />
            </div>
          </div>
        </div>
      </div>


    </div>
    <div class="bg-white shadow-sm p-4 relative mt-4" v-if="pageinfo.cards !== null && pageinfo.cards !== undefined && pageinfo.cards.length > 0">

      <div>
        <h2 class="text-xl font-semibold mb-4">{{ t('site.RegistrationCards') }}</h2>
        <div class="bg-gray-50 p-4 border rounded-lg shadow-sm">
          <p class="text-gray-700 mb-2">{{ t('site.automaticPayment') }}</p>

          <!-- Table Section -->
          <div class="mt-6">
            <table class="items-center w-full bg-transparent border-collapse">
              <thead>
                <tr>
                  <th
                    class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                    :class="'bg-blueGray-50 text-blueGray-500 border-blueGray-100'"
                  >
                    {{t('site.CardCompany')}}
                  </th>
                  <th
                    class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                    :class="'bg-blueGray-50 text-blueGray-500 border-blueGray-100'"
                  >
                    {{ t('site.PaymentDate') }}
                  </th>
                  <th
                    class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                    :class="'bg-blueGray-50 text-blueGray-500 border-blueGray-100'"
                  >
                    {{ t('site.ExtensionPayment') }}
                  </th>
                  <th
                    class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                    :class="'bg-blueGray-50 text-blueGray-500 border-blueGray-100'"
                  ></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(card, index) in pageinfo.cards" :key="index">
                  <td class="py-2 px-3">{{ card.cardName }}</td>
                  <td class="py-2 px-3">{{ dayjs.utc(card.authDate).local().format('YYYY-MM-DD') }}</td>
                  <td class="py-2 px-3">{{ ((card.isMain) ? t('site.ExtensionPaymentsMent') : t('site.cardNotUsed')) }}</td>
                  <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right">
                    <TableDropdown>
                      <button v-if="!card.isMain" @click="fnChangeCard(card)"
                        type="button"  
                        class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700 text-left"
                      >
                        {{ t('site.ExtensionCard') }}
                      </button>
                      <button
                        type="button" @click="fnDeleteCard(card)"
                        class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700 text-left"
                      >
                        {{ t('common.Delete') }}
                      </button>
                    </TableDropdown>

                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>

    <!-- Popup Modal -->
    <div v-if="showPopup" class="absolute z-50 w-full h-screen bg-gray-100 flex items-center justify-center bg-opacity-60" style="top:0;left:0;">
      <div class="fixed inset-0">
      <!-- Overlay to block clicks and add gray background -->
      <div class="fixed inset-0 bg-gray-900 bg-opacity-50 z-40"></div>

      <!-- Popup Box -->
      <div class="bg-white p-8 rounded-lg shadow-lg z-50" style="width:40rem;">
        <h2 class="text-xl font-semibold mb-4">New API Key</h2>
        <label class="block mb-2">API Key Type</label>
        <select v-model="pageinfo.target.provider" class="w-full p-2 mb-4 border rounded">
          <option :value="provider.name" v-for="(provider, index) in pageinfo.providers" :key="`provider_select_${index}`">{{ provider.name }}</option>
        </select>
        <label class="block mb-2">API Key</label>
        <input v-model="pageinfo.target.apikey" type="text" placeholder="Enter API Key" class="w-full p-2 mb-4 border rounded" />

        <!-- Modal Buttons -->
        <div class="flex justify-end">
          <button @click="saveApiKey" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2">
            {{ t('common.Save') }}
          </button>
          <button @click="closePopup" class="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">
            {{ t('common.Cancel') }}
          </button>
        </div>
      </div>
    </div>
    </div>
</template>

<script setup lang="ts">
  import { ref, onMounted } from 'vue';
  import { AiRepository,MemberRepository } from '@/repositories';
  import { useRouter } from 'vue-router';
  import { useI18n } from 'vue-i18n';
  import { Pagination,Loading,TableDropdown } from '@/components';
  import { MyMemberShip, Permission,KeyMaster, BillingKey } from '@/entities';
  import dayjs from 'dayjs';
  import utc from 'dayjs/plugin/utc';
  import localizedFormat from 'dayjs/plugin/localizedFormat'; 
  import { ServiceProvider } from '@/entities/ServiceProvider';
  import { MessageBox, ValidateHelper,StringHelper } from '@/utility';

  dayjs.extend(utc);
  dayjs.extend(localizedFormat);
  const aiRep = new AiRepository();
  const memberRep = new MemberRepository();
  const router = useRouter();
  const { t, locale } = useI18n();
  const showPopup = ref(false);
  const checker = new ValidateHelper();

  var pageinfo = ref({
    key:0,
    isLoading:true,
    keys:[] as KeyMaster[],
    providers:[] as ServiceProvider[],
    cards:[] as BillingKey[],
    totalcount:0,
    curpage:1,
    myMemberShip : new MyMemberShip(),
    permission: new Permission(),
    target: {
      provider:"",
      apikey:""
    }
  });

  onMounted(async() => {
    await GetMyMemberShip();
    await GetProviders();
    await GetCards();
    await fnPaging(1);
    pageinfo.value.key += 1;
    pageinfo.value.isLoading = false;
  });

  const GetMyMemberShip = async () => {
    let rst = await memberRep.GetMyMemberShip();
    if (rst.check && rst.data !== null) {
      pageinfo.value.myMemberShip = rst.data;
      pageinfo.value.permission.Aceept(pageinfo.value.myMemberShip.memberShip);
    }
  }

  
  const GetCards = async () => {
    let rst = await memberRep.GetCards({ curPage : 1, pageSize : 10 });
    //console.log('GetCards : ', rst);
    if (rst.check && rst.data !== null) {
      pageinfo.value.cards = rst.data;
      
    }
  }

  const GetProviders = async () => {
    let rst = await aiRep.GetProviders({ curPage : pageinfo.value.curpage, pageSize : 10 });
    console.log('GetProviders : ', rst);
    if (rst.check && rst.data !== null) {
      pageinfo.value.providers = rst.data;
    }
  }

  const fnPaging = async (curpage:number) => {
    pageinfo.value.curpage = curpage;
    let rst = await aiRep.GetKeyList({ curPage : pageinfo.value.curpage, pageSize : 10 });
    console.log('fnPaging : ', rst);
    if (rst.check) {
      pageinfo.value.keys = rst.data;
      pageinfo.value.totalcount = rst.code;
    }
  };

  const keyDelete = (keyid:string) => {
    MessageBox.Confirm(t('system.RemoveConfirm'), async () => {
      let rst = await aiRep.DeleteKeyMaster(keyid);
      if (rst.check) {
        MessageBox.Success(t('system.Deleted'), () => {
          document.location.reload();
        });
      } else {
        MessageBox.Alert(rst.message);
      }
    });
  };

  const fnDeleteCard = (card:BillingKey) => {
    let message = (card.isMain) ? t('site.deleteExtendedPayment') : t('system.RemoveConfirm');

    MessageBox.Confirm(message, async () => {
      let rst = await memberRep.DeleteCard(card.id);
      if (rst.check) {
        MessageBox.Success(t('system.Deleted'), () => {
          document.location.reload();
        });
      } else {
        MessageBox.Alert(rst.message);
      }
    });
  };

  const fnChangeCard = (card:BillingKey) => {
    MessageBox.Confirm(t('site.extensionPaymentCard'), async () => {
      let rst = await memberRep.PutCard(card.id);
      if (rst.check) {
        MessageBox.Success(t('system.processed'), () => {
          for(let i = 0; i < pageinfo.value.cards.length; i++) {
            pageinfo.value.cards[i].isMain = false;
          }
          card.isMain = true;
        });
      } else {
        MessageBox.Alert(rst.message);
      }
    });
  };

// Open popup
function openPopup() {
  showPopup.value = true;
}

// Close popup
function closePopup() {
  showPopup.value = false;
}

// Save new API key and close popup
const saveApiKey = async () => {
  if (checker.IsNullOrWhiteSpace(pageinfo.value.target.apikey)) {
    MessageBox.Alert(t('system.required') + ' : ApiKey');
    return;
  }

  if (checker.IsNullOrWhiteSpace(pageinfo.value.target.provider)) {
    MessageBox.Alert(t('system.required') + ' : provider');
    return;
  }

  pageinfo.value.isLoading = true;
  let rst = await aiRep.PostKeyRegist(pageinfo.value.target.apikey, pageinfo.value.target.provider);
  pageinfo.value.isLoading = false;
  if (rst.check && rst.data !== null) {
    pageinfo.value.keys.push(rst.data);
    pageinfo.value.key += 1;

    MessageBox.Success(t('system.Registered'), () => {
      closePopup();
    });
  } else {
    MessageBox.Alert(rst.message);
  }
}
</script>

<style scoped>
/* Modal Overlay */
.modal-overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 40;
}

/* Modal Box */
.modal-content {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  z-index: 50;
}

/* Additional Utility Styles */
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
