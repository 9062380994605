export class Writer {
    public id:string;
    public photo:string;
    public nickname:string;
    public bio:string;

    constructor() {
        this.id = "";
        this.photo = "";
        this.nickname = "";
        this.bio = "";
    }
}

