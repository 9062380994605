
<template>
<div class="flex flex-wrap mr-3" :key="componentInfo.layerKey">
<div class="w-full px-1">
<div class="relative inline-flex align-middle w-full">
<select v-model="selectedLanguage" class="bg-white border border-gray-300 text-gray-900 rounded-md shadow-sm focus:ring-pink-500 focus:border-pink-500 block pl-3 pr-10 py-2 text-base outline-none opacity-65">
<option value="en" >English</option>
<option value="ko">한국어</option>
</select>
</div>
</div>
</div>
</template>
  
<script setup lang="ts">
import { ref,onMounted,watch } from 'vue';
import { useI18n } from 'vue-i18n';

const { t, locale } = useI18n();
var selectedLanguage = ref(localStorage.getItem('selectedLanguage') || 'ko');


var componentInfo = ref({
  layerKey : 0
});

onMounted(async () => {
  locale.value = selectedLanguage.value;
});

  watch(selectedLanguage, () => {
    locale.value = selectedLanguage.value;
    localStorage.setItem('selectedLanguage', selectedLanguage.value);
  });
</script>