import { BoardMaster } from "./BoardMaster";
import { Condition } from "./Condition";

export class BoardContent {
    public id:string;
    public master:BoardMaster;
    public masterId:string;
    public content:string;
    public answerDate:Date|null;
    public answer:string|null;
    public title:string;
    public anwerOwnerId:string|null;
    public readCount:number;
    public ownerId:string;
    public condition:Condition;

    constructor() {
        this.id = "";
        this.master = new BoardMaster();
        this.masterId = "";
        this.content = "";
        this.answerDate = null;
        this.answer = null;
        this.title = "";
        this.anwerOwnerId = null;
        this.readCount = 0;
        this.ownerId = "";
        this.condition = new Condition();
    }
}