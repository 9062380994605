export class Synopsis {
    public description:string;
    public title:string;
    public seq:number;

    constructor() {
        this.description = "";
        this.title = "";
        this.seq = 0;
    }
}

