<template>
  <Loading :loading="!pageinfo.isReady" />

  <div class="w-full flex justify-between mb-1">
    <div class="ml-2">
      <h3 class="text-2xl subcolor uppercase"><b>{{ t(`common.${pageinfo.master.title}`) }}</b></h3>
    </div>
    <div class="text-sm mr-2">
      <router-link to="/main" class="text-gray-400 uppercase text-sm"><small>HOME</small></router-link>
      <span class="px-2 text-gray-300">&gt;</span>
      <a href="#" class="text-gray-500 uppercase text-md"><b>{{ t(`common.${pageinfo.master.title}`) }}</b></a>
    </div>
  </div>

  <div :key="pageinfo.key"
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white">
    <div class="block w-full overflow-x-auto">
      <table class="items-center w-full bg-transparent border-collapse">
        <thead>
          <tr>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="'bg-blueGray-50 text-blueGray-500 border-blueGray-100'"
            >
            {{ t(`common.no`) }}
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="'bg-blueGray-50 text-blueGray-500 border-blueGray-100'"
            >
            {{ t(`common.title`) }}
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="'bg-blueGray-50 text-blueGray-500 border-blueGray-100'"
            >
              {{ t(`common.Read`) }}
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="'bg-blueGray-50 text-blueGray-500 border-blueGray-100'"
            >
              {{ t(`common.RegistDate`) }}
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="'bg-blueGray-50 text-blueGray-500 border-blueGray-100'"
            ></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(content,index) in pageinfo.contents" :key="`content_list_${index}`">
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
            >
            {{ pageinfo.index - index }}
            </td>
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center"
            >
              <router-link class="ml-3 font-bold light" :to="`/board/${property.code}/view/${content.id}`">
              {{ content.title }}
              </router-link>
            </td>
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
            >
              {{ content.readCount }}
            </td>
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
            >
              <div class="flex">
                {{ dayjs.utc(content.condition.registDate).local().format('YYYY-MM-DD HH:mm') }}
              </div>
            </td>
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right"
            >


            </td>
          </tr>

        </tbody>
      </table>
    </div>
    <hr/>
    <div class="py-2 flex justify-center items-center">
        <Pagination :total-count="pageinfo.totalcount" :cur-page="pageinfo.curpage" :callback="fnPaging" />
    </div>
    <div class="py-2 items-left px-2" v-if="pageinfo.master.boardType !== BoardTypes.ReadOnly || pageinfo.isOwner">
      <button @click="fnCreateContent" class="px-4 py-2 uppercase mainbg text-white font-semibold rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-opacity-50" type="button">Write</button>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { Pagination,Loading } from '@/components';
  import { onMounted, ref,defineProps,watch,computed } from 'vue';
  import { BoardRepository,MemberRepository } from '@/repositories';
  import store from '@/store';
  import { BoardContent, BoardMaster, Member } from '@/entities';
  import { MessageBox } from '@/utility';
  import { useRouter } from 'vue-router';
  import { BoardTypes } from '@/models';
  import { Authorization, UserType } from '@/models/enums';
  import dayjs from 'dayjs';
  import utc from 'dayjs/plugin/utc';
  import localizedFormat from 'dayjs/plugin/localizedFormat'; 
  import { useI18n } from 'vue-i18n';

  dayjs.extend(utc);
  dayjs.extend(localizedFormat);
  const boardRep = new BoardRepository();
  const memberRep = new MemberRepository();
  const router = useRouter();
    const { t, locale } = useI18n();

  interface BoardProperty {
    code?:string|null|undefined
  }

  const property = defineProps<BoardProperty>();

  var pageinfo = ref({
    key:0,
    userinfo:new Member(),
    master:new BoardMaster(),
    contents:[] as BoardContent[],
    totalcount:0,
    curpage:1,
    index:0,
    isReady:false,
    isOwner : false
  });

  onMounted(async() => {
    console.log('property.code : ', property.code);
    await getUser();
    await GetMaster();
    pageinfo.value.index = getLastIndex();
    pageinfo.value.key += 1;
    pageinfo.value.isReady = true;
    pageinfo.value.isOwner = Authorization.isRequired(UserType.LocalManager, pageinfo.value.userinfo.type);
  });

  const getUser = async () => {
    let rst = await memberRep.GetUser();
    if (rst.check && rst.data !== null) {
      pageinfo.value.userinfo = rst.data;
    }
  };

  watch(property, async () => {
    await GetMaster();
    pageinfo.value.index = getLastIndex();
    pageinfo.value.key += 1;
    pageinfo.value.isReady = true;
    pageinfo.value.isOwner = Authorization.isRequired(UserType.LocalManager, pageinfo.value.userinfo.type);
  });

  const GetMaster = async () => {
    if (property.code !== null && property.code !== undefined) {
      let rst = await boardRep.GetMaster(property.code);
      if (rst.check && rst.data !== null) {
        pageinfo.value.master = rst.data;
        await fnPaging(1);
      }
    }
  };

  const fnPaging = async (curpage:number) => {
    pageinfo.value.curpage = curpage;
    if (pageinfo.value.master.boardType === BoardTypes.Question) {
      await fnUserPaging(1);
    } else {
      await fnPublicPaging(1);
    }
  };

  const fnPublicPaging = async (curpage:number) => {
    pageinfo.value.curpage = curpage;
    if (property.code !== null && property.code !== undefined) {
      let rst = await boardRep.GetBoards(property.code, { curPage : pageinfo.value.curpage, pageSize : 10 });
      if (rst.check && rst.data !== null) {
        pageinfo.value.contents = rst.data;
        pageinfo.value.totalcount = rst.code;
      }
    }
  };

  const fnUserPaging = async (curpage:number) => {
    pageinfo.value.curpage = curpage;
    if (property.code !== null && property.code !== undefined) {
      let rst = await boardRep.GetUserBoards(property.code, { curPage : pageinfo.value.curpage, pageSize : 10 });
      if (rst.check && rst.data !== null) {
        pageinfo.value.contents = rst.data;
        pageinfo.value.totalcount = rst.code;
      }
    }
  };

  const fnCreateContent = () => {
    router.push(`/board/${property.code}/form`);
  };

  const getLastIndex = ():number => {
    let result = 0;
    if (pageinfo.value.totalcount > 0) {
      result = pageinfo.value.totalcount - ((pageinfo.value.curpage - 1) * 10);
    }
    return result;
  };
</script>