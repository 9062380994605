export class CardInfo {
    public cardNo:string;
    public expYear:string;
    public expMonth:string;
    public idNo:string;
    public cardPw:string;

    constructor() {
        this.cardNo = "";
        this.expYear = "";
        this.expMonth = "";
        this.idNo = "";
        this.cardPw = "";
    }
}