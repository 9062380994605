import { createStore } from 'vuex';
import chatlog from './chatlog';
import messages from './messagelog';
import favorites from './favorites';

export default createStore({
  modules: {
    chatlog,messages,favorites
  },
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
});