export class ValidateHelper {
    IsNullOrWhiteSpace(value:any):boolean {
        if (value !== null && value !== undefined && String(value).trim() !== '') {
            return false;
        } else {
            return true;
        }
    }

    countCharacters(value: string): number {
        let count = 0;

        for (const char of value) {
            if (this.isKorean(char)) {
                count += 2; // 한글은 2글자로 카운트
            } else {
                count += 1; // 영문과 숫자는 1글자로 카운트
            }
        }

        return count;
    }

    // 한글인지 확인하는 헬퍼 함수
    private isKorean(char: string): boolean {
        const code = char.charCodeAt(0);
        return (code >= 0xAC00 && code <= 0xD7A3) || // 현대 한글 음절
               (code >= 0x1100 && code <= 0x11FF) || // 한글 자모
               (code >= 0x3130 && code <= 0x318F);   // 한글 호환 자모
    }
}