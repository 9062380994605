<template>
    <div>
      <a
        class="text-blueGray-500 py-1 px-3"
        href="#"
        ref="btnDropdownRef"
        @click="toggleDropdown"
      >
        <i class="fas fa-ellipsis-v"></i>
      </a>
      <div
        ref="popoverDropdownRef"
        class="bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"
        :class="{ hidden: !dropdownPopoverShow, block: dropdownPopoverShow }"
      >
      <slot></slot>
      </div>
    </div>
  </template>
  
<script setup lang="ts">
  import { ref } from 'vue';
  import { createPopper } from '@popperjs/core';

  const dropdownPopoverShow = ref(false);
  const btnDropdownRef = ref<HTMLElement | null>(null);
  const popoverDropdownRef = ref<HTMLElement | null>(null);

  const toggleDropdown = (event: Event) => {
    event.preventDefault();
    if (dropdownPopoverShow.value) {
      dropdownPopoverShow.value = false;
    } else {
      dropdownPopoverShow.value = true;
      if (btnDropdownRef.value && popoverDropdownRef.value) {
        createPopper(btnDropdownRef.value, popoverDropdownRef.value, {
          placement: 'bottom-start',
        });
      }
    }
  };

</script>
    