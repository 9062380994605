<template>
  <Loading :loading="pageinfo.isLoading" />
  <div class="w-full flex justify-between mb-1">
    <div class="ml-2">
      <h3 class="text-2xl subcolor uppercase"><b>{{ t('site.Books')}}</b></h3>
    </div>
    <div class="text-sm mr-2">
      <router-link to="/main" class="text-gray-400 uppercase text-sm"><small>HOME</small></router-link>
      <span class="px-2 text-gray-300">&gt;</span>
      <a href="#" class="text-gray-500 uppercase text-md"><b>{{ t('site.Books')}}</b></a>
    </div>
  </div>

  <div class="w-full flex justify-between space-x-3" :key="`siteconfig_${pageinfo.key}`">
    <div class="bg-white shadow-sm p-4 w-5/6">
      <h1 class="mb-6"><span class="text-2xl font-bold maincolor">{{ t('site.booksetup') }}</span> 
        <FavoriteButton :bookid="pageinfo.book.id" v-if="pageinfo.book.id !== null && pageinfo.book.id !== undefined && String(pageinfo.book.id).trim() !== ''" />
        <span class="float-right text-xs font-semibold inline-block py-1 px-2 uppercase rounded maincolor bg-indigo-200 uppercase last:mr-0 mr-1">
        {{ pageinfo.myMemberShip.memberShip.title }}
        </span>
      </h1>

      <!-- Main Tab -->
        <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
          <!-- Title -->
          <div>
            <label for="title" class="block text-sm font-medium text-gray-700">{{t('site.form_label_title')}}</label>
            <input v-model="pageinfo.book.title" type="text" id="title" class="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm" :placeholder="t('site.form_title_placeholder')">
          </div>
  
          <!-- Template ID -->
          <div>
            <label for="customDomain" class="block text-sm font-medium text-gray-700">{{t('site.form_label_writer')}}</label>
            <select style="width:40%" class="p-2 border border-gray-300 rounded-md shadow-sm p-3 mr-2" v-model="pageinfo.book.writerId">
              <option value="">{{t('site.form_select_writer')}}</option>
              <option :value="writer.id" v-for="(writer,index) in pageinfo.writers" :key="`template_selectbox_${index}`">{{ writer.nickname }}</option>
            </select>
            <button class="px-4 py-2 uppercase bg-red-600 text-white font-semibold rounded-lg shadow-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50" @click="newWriter">
              <i class="fa-solid fa-file-signature"></i> {{ t('site.NewWriter') }}
            </button>
          </div>

          <div class="w-full">
            <label for="slug" class="block text-sm font-medium text-gray-700">{{ t('site.form_label_Categories') }}</label>
            <div class="w-full justify-center">
            <select style="width:40%" class="border border-gray-300 rounded-md shadow-sm p-3 mr-2" v-model="pageinfo.book.majorCategoryId" @change="fnChangeCategory">
              <option value="">{{ t('site.form_select_category') }}</option>
              <option :value="category.majorId" v-for="(category,index) in pageinfo.categories" :key="`template_selectbox_${index}`">{{ t(`site.${category.majorName}`) }}</option>
            </select>

            <select style="width:40%" class="border border-gray-300 rounded-md shadow-sm p-3" v-model="pageinfo.book.minorCategoryId">
              <option value="">{{t('site.form_select_subcategory')}}</option>
              <option :value="subcategory.minorId" v-for="(subcategory,index) in pageinfo.subcategories" :key="`template_selectbox_${index}`">{{ t(`site.${subcategory.minorName}`) }}</option>
            </select>
            </div>
          </div>

          <div class="mt-3 mb-8 py-4">
            <div class="float-left mr-4">
              <label for="isReviewOpen" class="flex items-center text-sm font-medium text-gray-700">
                <Toggle v-model="pageinfo.book.isReviewOpen" :disabled="false"/>
                <span class="p-1 text-md text-gray-500">{{t('site.form_label_reviewOpen')}}</span>
              </label>
            </div>
    
            <div class="float-left mr-4">
              <label for="isPublish" class="flex items-center text-sm font-medium text-gray-700">
                <Toggle v-model="pageinfo.book.isPublish" :disabled="false" />
                <span class="p-1 text-md text-gray-500">{{ t('site.form_label_publish') }}</span>
              </label>
            </div>

            <div class="float-left">
              <label for="isUseAssistant" class="flex items-center text-sm font-medium text-gray-700">
                <Toggle v-model="pageinfo.book.isUseAssistant" :disabled="!pageinfo.permission.Assistant" />
                <span class="p-1 text-md text-gray-500">{{ t('site.form_label_Assistant')}} </span>
              </label>
            </div>
          </div>
        </div>

        <div>
          <label for="plannedCount" class="block text-sm font-medium text-gray-700">{{t('site.form_label_plannedCount')}}</label>
          <input v-model.number="pageinfo.book.plannedCount" type="range" id="plannedCount" min="0" max="2000" step="10" class="mt-1 w-full" @input="updateCount">
          <div class="flex justify-between text-sm text-gray-500">
            <span>0</span>
            <span>{{ pageinfo.book.plannedCount }}</span>
            <span>2000</span>
          </div>
        </div>
        <div class="w-full flex justify-between space-x-6 mt-8">
          <div class="flex" style="width:190px;">
            <div class="w-full">
            <label for="Cover" class="block text-sm font-medium text-gray-700">{{ t('site.form_label_cover') }}</label>
            <div class="bookcover shadow-lg">
              <BackgroundImage :url="pageinfo.book.cover" :width="180" :height="260" @imageSelected="handleImageSelected" />
            </div>
          </div>
          </div>
          <div class="flex" style="width:calc(100% - 190px)">
            <div class="w-full">
              <label for="Description" class="block text-sm font-medium text-gray-700">{{t('site.form_label_description')}}</label>
              <textarea class="w-full border border-gray-300 rounded-md shadow-sm p-3" rows="10" v-model="pageinfo.book.description">
              </textarea>
            </div>
          </div>
        </div>

      <!-- Submit Button -->
      <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div class="mt-6">
          <button v-if="pageinfo.book.id !== null && pageinfo.book.id !== undefined && String(pageinfo.book.id).trim() !== ''" @click="fnRemove" class="px-4 py-2 uppercase bg-red-600 text-white font-semibold rounded-lg shadow-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50">
            <i class="fa-solid fa-trash"></i> {{t('common.Delete')}}
          </button>
        </div>
        <div class="mt-6 flex justify-end">
          <button @click="goBookList" class="px-4 py-2 mr-2 uppercase bg-gray-500 text-white font-semibold rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-opacity-50">
            <i class="fa-solid fa-folder"></i> {{ t('common.List') }}
          </button>
          <button v-if="pageinfo.book.id !== null && pageinfo.book.id !== undefined && String(pageinfo.book.id).trim() !== ''" @click="goReviews" class="px-4 py-2 mr-2 uppercase bg-blue-600 text-white font-semibold rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-opacity-50">
            <i class="fa-solid fa-book-open-reader"></i> {{t('site.book_table_button_review')}}
          </button>
          <button v-if="pageinfo.book.id !== null && pageinfo.book.id !== undefined && String(pageinfo.book.id).trim() !== ''" @click="fnStory" class="px-4 py-2 mr-2 uppercase bg-orange-500 text-white font-semibold rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-opacity-50">
            <i class="fa-solid fa-pencil"></i> {{t('site.stodymanager')}}
          </button>
          <button v-if="pageinfo.book.id !== null && pageinfo.book.id !== undefined && String(pageinfo.book.id).trim() !== ''" @click="goArticles" class="px-4 py-2 mr-2 uppercase subbg text-white font-semibold rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-opacity-50">
            <i class="fa-solid fa-pencil"></i> {{t('site.form_button_articles')}}
          </button>
          <button @click="submitForm" class="px-4 py-2 uppercase mainbg text-white font-semibold rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-opacity-50">
            <i class="fa-solid fa-floppy-disk"></i> {{ t('common.Save') }}
          </button>
        </div>
      </div>
    </div>
    <div class="w-1/6 bg-white shadow-sm p-4">
      <h3 class="mb-1"><span class="text-lg font-bold subcolor">{{ t('site.useplatform') }}</span></h3>
      <hr class="mb-3" />
      <div class="block w-full overflow-x-auto">
        <table class="items-center w-full bg-transparent border-collapse">
        <thead>
          <tr>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="'bg-blueGray-50 text-blueGray-500 border-blueGray-100'"
            >
              {{ t('site.platform') }}
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-right"
              :class="'bg-blueGray-50 text-blueGray-500 border-blueGray-100'"
            >
            <button @click="fnPlatformRegist(pageinfo.book.id)">{{ t('common.ButtonAdd') }}</button>
          </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(platform,index) in pageinfo.book.usePlatforms" :key="`book_platform_${index}`">
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
            >
              {{ platform.name }}
            </td>
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right"
            >
              <TableDropdown :siteid="platform.id">
                <button
                  type="button"  @click="fnPlatformEdit(platform)"
                  class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700 text-left"
                >
                  {{ t('common.Edit') }}
                </button>
                <button
                  type="button" @click="fnPlatformRemove(platform.id)"
                  class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700 text-left"
                >
                  {{ t('common.Delete') }}
                </button>
              </TableDropdown>

            </td>
          </tr>

        </tbody>
      </table>
      </div>
    </div>
    <div class="absolute inset-0 flex items-center bg-opacity-10 justify-center w-full h-screen" style="top:0px;left:-10px;z-index:100" v-show="pageinfo.platformedit.isShow">
      <div class="relative w-full">
        <div class="bg-white text-white p-4 rounded shadow-lg" style="width:40%;margin:0 auto;">
          <select v-model="pageinfo.platformedit.selectValue" @change="applyPlatform" class="w-full p-2 border border-gray-300 rounded mb-1 text-gray-500">
            <option value="">{{ t('site.directInput') }}</option>
            <option v-for="(platform,index) in pageinfo.platforms" :key="`platform_${index}`" :value="platform.name">{{ platform.name }}</option>
          </select>
          <div class="w-full mt-1">
            <input type="text" v-model="pageinfo.platformedit.target.name" maxlength="100" class="w-full border-1 border-gray-200 text-gray-500" :placeholder="t('site.platformname')" />
          </div>
          <div class="w-full mt-1">
            <input type="text" v-model="pageinfo.platformedit.target.url" maxlength="255" class="w-full border-1 border-gray-200 text-gray-500" :placeholder="t('site.producturl')" />
          </div>
          <div class="flex justify-center mt-3">
            <button class="mainbg px-4 py-1 text-white rounded-md mr-2" @click="fnPlatformSave">{{ t('common.Save') }}</button>
            <button class="bg-orange-400 text-white px-4 py-1 rounded-md" @click="fnPlatformCancel">{{ t('common.Cancel') }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
<div v-if="pageinfo.permission.Assistant && pageinfo.book.isUseAssistant">
  <FloatingChat :targetId="pageinfo.book.id" />
</div>
</template>
  
<script setup lang="ts">
  import { onMounted, ref,defineProps } from 'vue';
  import { BackgroundImage,Loading,TableDropdown,FavoriteButton,FloatingChat } from '@/components';
  import Toggle from '@vueform/toggle'
  import { Categories,Book, SubCategories,Writer, MyMemberShip, Permission, Member,Platform,UsePlatform } from '@/entities';
  import { MessageBox, ValidateHelper,Notification } from '@/utility';
  import { useI18n } from 'vue-i18n';
  import { ArticleRepository,MemberRepository,NotifyRepository } from '@/repositories';
  import { ReturnValues } from '@/models';
  import { useRoute,useRouter } from 'vue-router';
   
  const validate = new ValidateHelper();
  const { t } = useI18n();
  const articleRep = new ArticleRepository();
  const memberRep = new MemberRepository();
  const nofifyRep = new NotifyRepository();
  const route = useRoute();
  const router = useRouter();

  interface bookConfigProperty {
    bookid?:string|null|undefined
  }

  const property = defineProps<bookConfigProperty>();

  var pageinfo = ref({
    key:1,
    isLoading:true,
    user:null as Member|null,
    categories:[] as Categories[],
    myMemberShip : new MyMemberShip(),
    permission: new Permission(),
    subcategories:[] as SubCategories[],
    platforms:[] as Platform[],
    book:new Book(),
    writers:[] as Writer[],
    platformedit:{
      selectValue:"",
      isShow:false,
      target: new UsePlatform()
    }
  });

  onMounted(async () => {
    await GetUser();
    await GetMyMemberShip();
    await GetCategories();
    await GetWriters();
    await GetBookInfo();
    await GetPlatforms();
    pageinfo.value.isLoading = false;
    pageinfo.value.key += 1;
  });

  const GetBookInfo = async () => {
    if (property.bookid !== null && property.bookid !== undefined && property.bookid.trim() !== '') {
      let rst = await articleRep.GetBook(property.bookid);
      if (rst.check) {
        pageinfo.value.book = rst.data;
        pageinfo.value.subcategories = pageinfo.value.categories.filter(x => x.majorId == pageinfo.value.book.majorCategoryId)[0].subCategories;
      }
    }
  };

  const GetPlatforms = async () => {
    let rst = await articleRep.GetPlatforms();
    if (rst.check && rst.data !== null) {
      pageinfo.value.platforms = rst.data;
    }
  };

  const GetUser = async () => {
    let rst = await memberRep.GetUser();
    if (rst.check && rst.data !== null) {
      pageinfo.value.user = rst.data;
    }
  }

  const GetMyMemberShip = async () => {
    let rst = await memberRep.GetMyMemberShip();
    if (rst.check && rst.data !== null) {
      pageinfo.value.myMemberShip = rst.data;
      pageinfo.value.permission.Aceept(pageinfo.value.myMemberShip.memberShip);
    } else {
      MessageBox.Alert(t('common.requiredmembership'), () => {
        router.push('/account/membership?tab=membership');
      });
    }
  }

  const applyPlatform = () => {
    pageinfo.value.platformedit.target.name = pageinfo.value.platformedit.selectValue;
    pageinfo.value.key += 1;
  };

  const handleImageSelected = (image:string) => {
    pageinfo.value.book.cover = image;
    pageinfo.value.book.thumbnail = image;
  };

  const GetCategories = async () => {
    let rst = await articleRep.GetCategories();
    if (rst.check) {
      pageinfo.value.categories = rst.data as Categories[];
      pageinfo.value.key += 1;
    }
  };

  const GetWriters = async () => {
    let rst = await articleRep.GetWriters();
    if (rst.check) {
      pageinfo.value.writers = rst.data as Writer[];
      pageinfo.value.key += 1;
    }
  };

  const newWriter = () => {
    MessageBox.Console("New Writer Name:", async (nickname:string) => {
      if (validate.IsNullOrWhiteSpace(nickname)) {
        MessageBox.Alert("Required Nickname");
      } else {
        pageinfo.value.isLoading = true;
        let rst = await articleRep.PostWriter(nickname);
        pageinfo.value.isLoading = false;
        if (rst.check) {
          pageinfo.value.writers.push(rst.data);
          pageinfo.value.book.writerId = rst.data.id;
          pageinfo.value.key += 1;
        } else {
          MessageBox.Alert(rst.message);
        }
      }
    })
  };

  // Handle page width slider input
  const updateCount = () => {
    pageinfo.value.book.plannedCount = Math.round(pageinfo.value.book.plannedCount / 10) * 10;
  };
  
  const fnChangeCategory = () => {
    let arr = pageinfo.value.categories.filter(x => x.majorId === pageinfo.value.book.majorCategoryId);
    if (arr !== null && arr.length > 0) {
      pageinfo.value.subcategories = arr[0].subCategories;
    }
  };

  // Form submission function
  const submitForm = async () => {
    if (validate.IsNullOrWhiteSpace(pageinfo.value.book.title)) {
      MessageBox.Alert(t('system.required') + ' : title');
      return;
    }

    pageinfo.value.isLoading = true;
    try {
      let rst = new ReturnValues();
      if (pageinfo.value.book.id !== null && pageinfo.value.book.id !== undefined && String(pageinfo.value.book.id).trim() !== '') {
        rst = await articleRep.PutBook(pageinfo.value.book);
        pageinfo.value.isLoading = false;
      } else {
        rst = await articleRep.PostBook(pageinfo.value.book);
        pageinfo.value.isLoading = false;
      }

      if (rst.check) {
        pageinfo.value.book = rst.data as Book;
        MessageBox.Success(t('system.Saved'));
      } else {
        MessageBox.Alert(rst.message);
      }
    } catch (e:any) {
      let body = `##Description \r\n`;
      body += `position : Articles/BookConfig.vue\r\n`;
      body += `\r\n`;
      body += `###Summary\r\n`;
      body += `${e.stack}`;
      await nofifyRep.PostGithubIssue(`[Studio] Error : ${e.message}`, body, `bug`);
    }
  }

  const fnRemove = () => {
    MessageBox.Confirm(t("system.RemoveConfirm"), async () => {
      pageinfo.value.isLoading = true;
      let rst = await articleRep.DeleteBook(String(pageinfo.value.book.id));
      if (rst.check) {
        pageinfo.value.isLoading = false;
        MessageBox.Success(t(`system.Deleted`), () => {
          router.push(`/book/list`);
        });
      } else {
        pageinfo.value.isLoading = false;
        MessageBox.Alert(rst.message);
      }
    });
  };

  const goArticles = () => {
    router.push(`/book/articles/${pageinfo.value.book.id}`);
  };

  const goReviews = () => {
    router.push(`/book/${pageinfo.value.book.id}/reviews`);
  };

  const goBookList = () => {
    router.push('/book/list');
  };

  const fnStory = () => {
    router.push(`/book/story/${pageinfo.value.book.id}`);
  };

  const fnPlatformRegist = (bookId:string) => {
    fnPlatformClear();
    pageinfo.value.platformedit.target.bookId = bookId;
    pageinfo.value.platformedit.isShow = true;
  };

  const fnPlatformEdit = (platform:UsePlatform) => {
    pageinfo.value.platformedit.target = JSON.parse(JSON.stringify(platform)) as UsePlatform;
    console.log('pageinfo.value.platformedit.target : ', pageinfo.value.platformedit.target)
    pageinfo.value.platformedit.isShow = true;
  };

  const fnPlatformRemove = (platformId:string) => {
    MessageBox.Confirm(t('system.RemoveConfirm'), async () => {
      let rst = await articleRep.DeletePlatform(platformId);
      if (rst.check) {
        Notification.Success(t('system.Deleted'));
        for(let i = 0; i < pageinfo.value.book.usePlatforms.length; i++) {
          if (pageinfo.value.book.usePlatforms[i].id === platformId) {
            pageinfo.value.book.usePlatforms.splice(i, 1);
            break;
          }
        }
        fnPlatformClear();
        pageinfo.value.platformedit.isShow = false;
        pageinfo.value.key += 1;
      } else {
        MessageBox.Alert(rst.message);
      }
    });
  };

  const fnPlatformSave = async () => {
    let rst = new ReturnValues();
    if (validate.IsNullOrWhiteSpace(pageinfo.value.platformedit.target.id)) {
      rst = await articleRep.PostPlatformRegist(pageinfo.value.platformedit.target);
      pageinfo.value.book.usePlatforms.push(rst.data);
    } else {
      rst = await articleRep.PutPlatformUpdate(pageinfo.value.platformedit.target);
      for(let i = 0; i < pageinfo.value.book.usePlatforms.length; i++) {
          if (pageinfo.value.book.usePlatforms[i].id === rst.data.id) {
            pageinfo.value.book.usePlatforms[i] = rst.data;
            break;
          }
        }
    }
    
    if (rst.check) {
      Notification.Success(t('system.Saved'));
      
      fnPlatformClear();
      pageinfo.value.platformedit.isShow = false;
      pageinfo.value.key += 1;
    } else {
      MessageBox.Alert(rst.message);
    }
  };

  const fnPlatformCancel = () => {
    MessageBox.Confirm(t('common.cancelWriting'), () => {
      fnPlatformClear();
      pageinfo.value.platformedit.isShow = false;
    });
  };

  const fnPlatformClear = () => {
    pageinfo.value.platformedit.selectValue = "";
    pageinfo.value.platformedit.target = new UsePlatform();
  };
</script>

<style src="@vueform/toggle/themes/default.css"></style>
