<template>
  <transition name="fade" @before-enter="beforeEnter" @enter="enter" @leave="leave">
    <div v-if="loading" class="loading-overlay">
      <div class="spinner"></div>
    </div>
  </transition>
</template>

<script setup lang="ts">
import { defineProps } from 'vue';

const props = defineProps<{
  loading: boolean;
}>();

const beforeEnter = (el: Element) => {
  el.classList.add('opacity-0');
};

const enter = (el: Element, done: () => void) => {
  el.classList.remove('opacity-0');
  el.classList.add('opacity-100');
  done();
};

const leave = (el: Element, done: () => void) => {
  el.classList.remove('opacity-100');
  el.classList.add('opacity-0');
  setTimeout(() => {
    done();
  }, 1000); // 1초 후에 사라지도록 설정
};
</script>

<style scoped>
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.spinner {
  border: 8px solid rgba(0, 0, 0, 0.1); /* Light gray background */
  border-radius: 50%;
  border-top: 8px solid #fff; /* White spinner color */
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

/* Spin animation */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Fade transition */
.fade-enter-active, .fade-leave-active {
  transition: opacity 1s ease; /* 1초 동안의 opacity 변화 */
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
</style>
