<template>
  <div class="w-full flex justify-between mb-1">
    <div class="ml-2">
      <h3 class="text-2xl subcolor uppercase"><b>{{ t('site.Memos') }}</b></h3>
    </div>
    <div class="text-sm mr-2">
      <router-link to="/main" class="text-gray-400 uppercase text-sm"><small>HOME</small></router-link>
      <span class="px-2 text-gray-300">&gt;</span>
      <a href="#" class="text-gray-500 uppercase text-md"><b>{{ t('site.Memos') }}</b></a>
    </div>
  </div>
  
  <div :key="pageinfo.key" class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white">
    <div class="block w-full overflow-x-auto">
      <h3 class="text-xl p-3 text-blueGray-500" v-if="pageinfo.book.id !== null && pageinfo.book.id !== undefined && String(pageinfo.book.id) !== ''"><b>{{ pageinfo.book.title }}</b> <small>{{t('site.bookmemo')}}</small></h3>
      <div>
        <input type="text" name="title" class="focus:outline-none w-full px-4 py-2 border-2 border-gray-100" v-model="pageinfo.memo.title" :placeholder="t('common.requiredtitlement')" />
      </div>
      <div>
        <WebEditor :content="pageinfo.memo.content" :multiupload="false" :key="`memo_${pageinfo.key}`" :callback="handleUpdate" />
      </div>
    </div>
    <hr/>

    <div class="p-4">
      <div class="w-full flex justify-between">
        <div><button @click="fnSave" class="px-4 py-2 uppercase mainbg text-white font-semibold rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-opacity-50" type="button">{{t('common.Save')}}</button></div>
        <div><button class="px-4 py-2 uppercase bg-gray-400 text-white font-semibold rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-opacity-50" @click="goList">{{t('common.Back')}}</button></div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { onMounted, ref,defineProps } from 'vue';
  import { ArticleRepository,NotifyRepository } from '@/repositories';
  import { WebEditor } from '@/components/Editor';
  import { Book, Memo } from '@/entities';
  import { MessageBox } from '@/utility';
  import { useRouter } from 'vue-router';
  import { useI18n } from 'vue-i18n';
  import dayjs from 'dayjs';
  import utc from 'dayjs/plugin/utc';
  import localizedFormat from 'dayjs/plugin/localizedFormat'; 
  import { ReturnValues } from '@/models';
  import { ValidateHelper } from '@/utility';

  dayjs.extend(utc);
  dayjs.extend(localizedFormat);

  const articleRep = new ArticleRepository();
  const nofifyRep = new NotifyRepository();
  const router = useRouter();
  const { t, locale } = useI18n();
  const validate = new ValidateHelper();

  interface bookConfigProperty {
    bookid?:string|null|undefined,
    memoid?:string|null|undefined
  }

  const property = defineProps<bookConfigProperty>();

  var pageinfo = ref({
    key:0,
    book:new Book(),
    memo : new Memo()
  });

  onMounted(async() => {
    await GetBookInfo();
    await GetMemo();
  });

  const GetBookInfo = async () => {
    if (property.bookid !== null && property.bookid !== undefined && property.bookid.trim() !== '') {
      let rst = await articleRep.GetBook(property.bookid);
      if (rst.check) {
        pageinfo.value.book = rst.data;
        pageinfo.value.key += 1;
      }
    } else {
      pageinfo.value.book = new Book();
      pageinfo.value.key += 1;
    }
  };

  const GetMemo = async () => {
    if (property.memoid !== null && property.memoid !== undefined && property.memoid.trim() !== '') {
      let rst = await articleRep.GetMemo(property.memoid);
      if (rst.check) {
        pageinfo.value.memo = rst.data;
        pageinfo.value.key += 1;
      }
    } else {
      pageinfo.value.memo = new Memo();
      pageinfo.value.key += 1;
    }
  };

  const handleUpdate = (content:string) => {
    pageinfo.value.memo.content = content;
  };

  const fnSave = async () => {
    if (property.bookid !== null && property.bookid !== undefined && property.bookid.trim() !== '') {
      pageinfo.value.memo.bookId = property.bookid;
    }

    if (validate.IsNullOrWhiteSpace(pageinfo.value.memo.title)) {
      MessageBox.Alert(t('system.required') + ' : title');
      return;
    }

    if (validate.IsNullOrWhiteSpace(pageinfo.value.memo.content)) {
      MessageBox.Alert(t('system.required') + ' : content');
      return;
    }


    try {
      let rst = new ReturnValues();
      if (property.memoid !== null && property.memoid !== undefined && property.memoid.trim() !== '') {
        rst = await articleRep.PutMemoRegist(pageinfo.value.memo);
      } else {
        rst = await articleRep.PostMemoRegist(pageinfo.value.memo);
      }
      
      if (rst.check) {
        MessageBox.Success(t('system.Saved'), () => {
          if (property.bookid !== null && property.bookid !== undefined && property.bookid.trim() !== '') {
            router.push(`/memo/${pageinfo.value.book.id}/list`);
          } else {
            router.push(`/memo/list`);
          }
        });
      } else {
        MessageBox.Alert(rst.message);
      }
    } catch (e:any) {
      let body = `##Description \r\n`;
      body += `position : Articles/MemoForm.vue\r\n`;
      body += `\r\n`;
      body += `###Summary\r\n`;
      body += `${e.stack}`;
      await nofifyRep.PostGithubIssue(`[Studio] Error : ${e.message}`, body, `bug`);
    }
  };

  const goList = () => {
    MessageBox.Confirm(t('common.requiredsave'), () => {
        if (property.bookid !== null && property.bookid !== undefined && property.bookid.trim() !== '') {
          router.push(`/memo/${pageinfo.value.book.id}/list`);
        } else {
          router.push(`/memo/list`);
        }
    });
  };
</script>