<template>
  <Loading :loading="pageinfo.isLoading" />

  <div class="w-full flex justify-between mb-1">
    <div class="ml-2">
      <h3 class="text-2xl subcolor uppercase"><b>Story Manager</b></h3>
    </div>
    <div class="text-sm mr-2">
      <router-link to="/main" class="text-gray-400 uppercase text-sm"><small>HOME</small></router-link>
      <span class="px-2 text-gray-300">&gt;</span>
      <router-link to="/book/list" class="text-gray-400 uppercase text-sm"><small>Books</small></router-link>
      <span class="px-2 text-gray-300">&gt;</span>
      <a href="#" class="text-gray-500 uppercase text-md"><b>Story Manager</b></a>
    </div>
  </div>

  <div class="min-h-screen flex flex-col p-8 bg-gray-100" :key="pageinfo.key">
    <!-- 작품 정보 섹션 -->
    <div class="flex mb-8">
      <!-- 표지 -->
        <div class="shadow-lg mr-4 bg-white p-2">
          <img :src="pageinfo.book.cover" style="width:180px;height: 260px;" />
        </div>
      <!-- 작품 정보 -->
      <div class="flex flex-col justify-center ml-4 w-full break-words">
        <h1 class="text-4xl font-bold text-gray-800 mb-4">{{ pageinfo.book.title }}</h1>
        <p class="text-lg text-gray-600">{{ pageinfo.book.description }}</p>
      </div>
    </div>

    <!-- 버튼 섹션 -->
    <div class="flex justify-between items-center mb-6">
      <button @click="goBookList" class="px-4 py-2 mr-2 uppercase bg-gray-500 text-white font-semibold rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-opacity-50">
        <i class="fa-solid fa-folder"></i> {{ t('common.List') }}
      </button>
      <div class="space-x-4">
        <button @click="createSynop" class="px-4 py-2 mr-2 uppercase subbg text-white font-semibold rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-opacity-50">
          <i class="fa-solid fa-layer-group"></i> 시놉생성
        </button>
        <button @click="fnSave" class="px-4 py-2 mr-2 uppercase mainbg text-white font-semibold rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-opacity-50">
          <i class="fa-solid fa-floppy-disk"></i> {{ t('common.Save') }}
        </button>
      </div>
    </div>

    <!-- 구분선 -->
    <hr class="border-gray-300 mb-6" />

    <!-- 시놉시스 입력 섹션 -->
    <ul class="flex flex-col space-y-4 flex-grow overflow-y-auto">
      <li
        v-for="(synopsis, index) in pageinfo.synopsisList"
        :key="index"
        class="bg-white p-4 rounded-md shadow-md flex items-center draggable-item mt-1 mb-1"
        draggable="true"
        @dragstart="onDragStart(index)"
        @dragover.prevent
        @drop="onDrop(index)"
      >
        <div class="w-full flex-grow">
          <input type="text" maxlength="100" class="w-full border-gray-200 rounded-md mr-4 focus:outline-none" v-model="synopsis.title" />
          <textarea v-model="synopsis.description" rows="4" class="textarea w-full border-gray-200 rounded-md mr-4 mt-1 focus:outline-none"></textarea>
        </div>
        <button class="icon-btn ml-1" @click="removeSynopsis(index)"><i class="fa-solid fa-trash text-gray-400 text-xl focus:outline-none"></i></button>
      </li>
    </ul>


  </div>
</template>

<script setup lang="ts">
  import { onMounted, ref,defineProps } from 'vue';
  import { Loading } from '@/components';
  import { Book,Synopsis, Chapter } from '@/entities';
  import { MessageBox, ValidateHelper } from '@/utility';
  import { useI18n } from 'vue-i18n';
  import { ArticleRepository,MemberRepository } from '@/repositories';
  import { useRoute,useRouter } from 'vue-router';

  const validate = new ValidateHelper();
  const { t, locale } = useI18n();
  const articleRep = new ArticleRepository();
  const memberRep = new MemberRepository();
  const route = useRoute();
  const router = useRouter();

  interface bookConfigProperty {
    bookid?:string|null|undefined
  }

  const property = defineProps<bookConfigProperty>();

  var pageinfo = ref({
    key:1,
    isLoading:true,
    book:new Book(),
    chapters:[] as Chapter[],
    synopsisList: [] as Synopsis[]
  });

  onMounted(async () => {
    await GetBookInfo();
    await GetChapters();
    pageinfo.value.isLoading = false;
    pageinfo.value.key += 1;
  });

  const GetBookInfo = async () => {
    if (property.bookid !== null && property.bookid !== undefined && property.bookid.trim() !== '') {
      let rst = await articleRep.GetBook(property.bookid);
      if (rst.check) {
        pageinfo.value.book = rst.data;
        if (!validate.IsNullOrWhiteSpace(pageinfo.value.book.synopsis)) {
          pageinfo.value.synopsisList = JSON.parse(pageinfo.value.book.synopsis);
        }
        pageinfo.value.key += 1;
      }
    }
  };

  const GetChapters = async () => {
    if (property.bookid !== null && property.bookid !== undefined && property.bookid.trim() !== '') {
      let rst = await articleRep.GetChapters(property.bookid);
      if (rst.check) {
        pageinfo.value.chapters = rst.data;
        pageinfo.value.key += 1;
      }
    }
  };

  let draggedItemIndex = ref<number | null>(null);

  const onDragStart = (index: number) => {
    draggedItemIndex.value = index;
  };

  const onDrop = (index: number) => {
    if (draggedItemIndex.value !== null && draggedItemIndex.value !== index) {
      const temp = pageinfo.value.synopsisList[draggedItemIndex.value];
      pageinfo.value.synopsisList.splice(draggedItemIndex.value, 1);
      pageinfo.value.synopsisList.splice(index, 0, temp);
      draggedItemIndex.value = null;
    }
  };

  const removeSynopsis = (index: number) => {
    pageinfo.value.synopsisList.splice(index, 1);
  };

  const goBookList = () => {
    MessageBox.Confirm("저장하지 않은 내용은 유실됩니다.", () => {
      router.push(`/book/articles/${pageinfo.value.book.id}`);
    });
  };

  const createSynop = () => {
    if (pageinfo.value.synopsisList.length === 0 && pageinfo.value.chapters.length > 0) {
      MessageBox.Confirm("챕터수 만큼의 시놉시스를 생성하시겠습니까?", () => {
        for(let i = 0; i < pageinfo.value.chapters.length; i++) {
          let item = new Synopsis();
          item.title = pageinfo.value.chapters[i].title;
          pageinfo.value.synopsisList.push(item);
        }
      });
    } else {
      pageinfo.value.synopsisList.push(new Synopsis());
    }
  };

  const fnSave = async () => {
    pageinfo.value.isLoading = true;
    
    pageinfo.value.synopsisList.map((synopsis, index) => {
      synopsis.seq = index;
    });

    pageinfo.value.book.synopsis = JSON.stringify(pageinfo.value.synopsisList);

    
    let rst = await articleRep.PutBook(pageinfo.value.book);
    pageinfo.value.isLoading = false;

    if (rst.check) {
      pageinfo.value.book = rst.data as Book;
      MessageBox.Success(t('system.Saved'));
    } else {
      MessageBox.Alert(rst.message);
    }
  };
</script>

<!-- Tailwind CSS 활용 -->
<style scoped>
.textarea {
  @apply border border-gray-300 p-2 rounded shadow-md focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300;
  outline: none;
}

.icon-btn {
  @apply text-gray-600 hover:text-red-500 transition duration-300;
  outline: none;
}

.draggable-item {
  @apply cursor-move;
}

.draggable-item.dragging {
  @apply opacity-50;
}
</style>
