export class Partner {
    title: string;
    name: string;
    phone: string;
    email: string;
    companyName: string;
    content: string;
  
    constructor() {
      this.title = '';
      this.name = '';
      this.phone = '';
      this.email = '';
      this.companyName = '';
      this.content = '';
    }
  }
  