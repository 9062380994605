import { Condition } from "./Condition";
import { MemberShip } from "./MemberShip";

export class Order {
    public id:string;
    public orderNo:string;
    public memberShipId:string;
    public status:string;
    public completeDate:Date|null;
    public memberShip:MemberShip;
    public condition:Condition;

    constructor() {
        this.id = "";
        this.orderNo = "";
        this.memberShipId = "";
        this.status = "";
        this.completeDate = null;
        this.memberShip = new MemberShip();
        this.condition = new Condition();
    }
}

