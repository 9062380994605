<template>
  <div class="w-full flex justify-between mb-1">
    <div class="ml-2">
      <h3 class="text-2xl subcolor uppercase"><b>{{ t('site.movieguide') }}</b></h3>
    </div>
    <div class="text-sm mr-2">
      <router-link to="/main" class="text-gray-400 uppercase text-sm"><small>HOME</small></router-link>
      <span class="px-2 text-gray-300">&gt;</span>
      <a href="#" class="text-gray-500 uppercase text-md"><b>{{ t('site.movieguide') }}</b></a>
    </div>
  </div>

  <!-- Tabs -->
  <div class="flex justify-center mb-6">
    <button class="text-gray-600 px-4 py-2 mx-2 focus:outline-none border-b-2 border-transparent hover:border-blue-500" @click="onTagb(1)" :class="pageinfo.tab === 1 ? `maincolor` : ''">
      시스템이용
    </button>
    <button class="text-gray-600 px-4 py-2 mx-2 focus:outline-none border-b-2 border-transparent hover:border-blue-500" @click="onTagb(2)" :class="pageinfo.tab === 2 ? `maincolor` : ''">
      AI활용
    </button>
  </div>

  <div :key="pageinfo.key" class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white">
    <div class="block w-full overflow-x-auto p-5" v-if="pageinfo.tab === 1">
      <div class="grid gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
          <div class="bg-white border rounded-lg overflow-hidden shadow-lg">
            <div class="cursor-pointer" @click="openVideo('https://www.youtube.com/embed/vKJh-zB9bDw')">
              <img src="https://img.youtube.com/vi/vKJh-zB9bDw/0.jpg" alt="Video Thumbnail" class="w-full h-48 object-cover">
              <div class="p-4">
                <h3 class="text-lg font-bold">GN2Studio의 멤버십 차이</h3>
                <p class="text-sm text-gray-600">GN2Studio의 멤버십간 차이에 대해서 알려드립니다.</p>
              </div>
            </div>
          </div>
        </div>


    </div>

    <div class="block w-full overflow-x-auto p-5" v-if="pageinfo.tab === 2">
      <div class="grid gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
        
        <div class="bg-white border rounded-lg overflow-hidden shadow-lg">
          <div class="cursor-pointer" @click="openVideo('https://www.youtube.com/embed/LnVnG1xR_us')">
            <img src="https://img.youtube.com/vi/LnVnG1xR_us/0.jpg" alt="Video Thumbnail" class="w-full h-48 object-cover">
            <div class="p-4">
              <h3 class="text-lg font-bold">작품설정에서 AI활용하기.</h3>
              <p class="text-sm text-gray-600">새로운 작품을 써볼까요? 작품을 새로 쓸 때, 고민을 AI와 의논해 보세요.</p>
            </div>
          </div>
        </div>

        <div class="bg-white border rounded-lg overflow-hidden shadow-lg">
          <div class="cursor-pointer" @click="openVideo('https://www.youtube.com/embed/Gs829SBZ_i0')">
            <img src="https://img.youtube.com/vi/Gs829SBZ_i0/0.jpg" alt="Video Thumbnail" class="w-full h-48 object-cover">
            <div class="p-4">
              <h3 class="text-lg font-bold">AI토론 기능을 소개해 드립니다.</h3>
              <p class="text-sm text-gray-600">GN2Studio 에서 제공하는 AI관련 다양한 기능들 중에 AI 토론 기능을 소개해 드립니다.</p>
            </div>
          </div>
        </div>


      </div>

    </div>

  </div>


  <div v-if="pageinfo.showVideo" class="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50 w-full h-screen" style="top:0;left:0;">
    <div class="bg-white p-5 rounded-lg relative">
      <button @click="closeVideo" class="absolute text-white bg-black shadow-md" style="text-align:center;top:-10px;right:-10px;border-radius: 50%;padding:1px 3px 1px 4px;">
        &times;
      </button>
      <iframe width="900" height="600" :src="pageinfo.currentVideoUrl" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { onMounted, ref } from 'vue';
  import { useRouter } from 'vue-router';
  import { useI18n } from 'vue-i18n';

  const router = useRouter();
  const { t } = useI18n();

  var pageinfo = ref({
    key : 0,
    tab : 1,
    showVideo : false,
    currentVideoUrl : ''
  });

  onMounted(async() => {
    pageinfo.value.key += 1;
  });

  const onTagb = (num:number) => {
    pageinfo.value.tab = num;
    pageinfo.value.key += 1;
  }

  const openVideo = (url: string) => {
    pageinfo.value.currentVideoUrl = url;
    pageinfo.value.showVideo = true;
  };

  const closeVideo = () => {
    pageinfo.value.showVideo = false;
    pageinfo.value.currentVideoUrl = '';
  };
</script>