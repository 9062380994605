<template>
  <Loading :loading="!pageinfo.isReady" />
  <div class="flex flex-col md:flex-row py-8 px-4 max-w-6xl mx-auto gap-8 nanum-gothic lg:space-x-6 md:space-x-3">
    <!-- 왼쪽: 회사 정보 및 연락처 -->
    <div class="flex-1 bg-gray-50 p-6 rounded-lg shadow-md bg-opacity-40 mb-4">
      <h2 class="text-white text-2xl font-bold mb-2">GN2STUDIO</h2>
      <p class="text-gray-700 mb-4">
        <a :href="`mailto:${config.info.email}`" class="text-white hover:underline">{{ config.info.email }}</a><br />
      </p>

      <h3 class="text-white text-xl font-semibold mb-2 mt-8">{{ t('common.sns') }}</h3>
      <div class="flex space-x-4">
        <a :href="config.link.facebook" target="_blank" class="text-pink-600 hover:text-pink-400 shadow-md bg-white py-2 px-4 rounded-md">
          <i class="fab fa-facebook-f fa-lg"></i>
        </a>
        <a :href="config.link.youtube" target="_blank" class="text-pink-600 hover:text-pink-400 shadow-md bg-white py-2 px-3 rounded-md">
          <i class="fab fa-youtube fa-lg"></i>
        </a>
        <a :href="config.link.blog" class="text-pink-600 hover:text-pink-400 shadow-md bg-white py-2 px-3 rounded-md">
          <i class="fas fa-blog fa-lg"></i>
        </a>
      </div>
    </div>

    <!-- 오른쪽: 문의 폼 -->
    <div class="flex-1 bg-white p-6 rounded-lg shadow-md">
      <h2 class="text-2xl font-bold mb-4">{{ t('common.question') }}</h2>
      <form @submit.prevent="submitForm" class="space-y-4">
        <div>
          <label for="title" class="block text-sm font-medium text-gray-700 mb-1">{{ t('common.title') }}</label>
          <input
            id="title"
            v-model="form.title"
            type="text"
            class="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>

        <div class="mt-3">
          <label for="name" class="block text-sm font-medium text-gray-700 mb-1">{{ t('common.name') }}</label>
          <input
            id="name"
            v-model="form.name"
            type="text"
            class="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>

        <div class="mt-3">
          <label for="phone" class="block text-sm font-medium text-gray-700 mb-1">{{ t('common.phone') }}</label>
          <input
            id="phone"
            v-model="form.phone"
            type="tel"
            class="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-blue-500"
            
          />
        </div>

        <div class="mt-3">
          <label for="email" class="block text-sm font-medium text-gray-700 mb-1">{{ t('common.email') }}</label>
          <input
            id="email"
            v-model="form.email"
            type="email"
            class="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-blue-500"
            
          />
        </div>
        
        <div class="mt-3">
          <label for="companyName" class="block text-sm font-medium text-gray-700 mb-1">{{ t('common.companyName') }}</label>
          <input
            id="companyName"
            v-model="form.companyName"
            type="text"
            class="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-blue-500"
            
          />
        </div>

        <div class="mt-3">
          <label for="content" class="block text-sm font-medium text-gray-700 mb-1">{{ t('common.content') }}</label>
          <textarea
            id="content"
            v-model="form.content"
            rows="6"
            class="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-blue-500"
            style="overflow-y:scroll;"
            required
          ></textarea>
        </div>

        <button
          type="submit"
          class="mt-3 w-full inline-flex justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
        >
        {{ t('system.submit') }}
        </button>
      </form>
    </div>
  </div>
</template>


<script setup lang="ts">
import { Loading } from "@/components";
import { onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import config from "@/config";
import { BoardRepository } from "@/repositories";
import { MessageBox, ValidateHelper } from "@/utility";
import { Partner } from "@/entities";

const { t, locale } = useI18n();
const boardRep = new BoardRepository();
const validate = new ValidateHelper();

var pageinfo = ref({
  isReady:false
});

const form = ref<Partner>(new Partner());

onMounted(() => {
  pageinfo.value.isReady = true;
});

const submitForm = async () => {
  if (validate.IsNullOrWhiteSpace(form.value.title)) {
    MessageBox.Alert(t('common.requiredtitlement'));
    return;
  } 

  if (validate.IsNullOrWhiteSpace(form.value.name)) {
    MessageBox.Alert(t('common.requiredName'));
    return;
  } 

  if (validate.IsNullOrWhiteSpace(form.value.content)) {
    MessageBox.Alert(t('common.requiredContent'));
    return;
  } 

  let rst = await boardRep.RegistPartner(form.value);
  if (rst.check) {
    MessageBox.Success(t('common.Registered'), () => {
      form.value = new Partner();
    });
  } else {
    MessageBox.Alert(rst.message);
  }
};
</script>