<template>
  <Loading :loading="!pageinfo.isReady" />
  <div class="flex flex-col items-center py-8 px-4">
      <!-- Hero Section -->
      <section class="relative shadow-lg rounded-lg p-8 md:p-12 mb-8 max-w-3xl w-full text-center mx-auto card-bg">
      <h1 class="text-4xl font-extrabold mb-4 maincolor">{{ t("site.Welcome") }}</h1>
      <div class="absolute bottom-0 left-0 w-full py-4 px-8">
      <p class="text-lg text-gray-700">
          {{ t("site.description") }}
      </p>
      <p class="text-lg text-gray-700 mb-6">
        {{ t("site.description2") }}
      </p>
      </div>
      </section>

      <!-- Features Section -->
      <section class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 max-w-3xl w-full mb-8 mx-auto bg-opacity-0">
      <div class="flex flex-col items-center bg-pink-100 p-6 rounded-lg shadow-xl transition-transform transform hover:scale-105 overflow-hidden">
          <img src="/images/thumbnail/editor.jpg" alt="" class="w-full mb-4">
          <h3 class="text-xl font-semibold text-pink-700">{{ t('site.home_desc_title_1') }}</h3>
          <p class="text-gray-600 text-center p-8">{{ t('site.home_desc_1') }}</p>
      </div>
      <div class="flex flex-col items-center bg-purple-100 p-6 rounded-lg shadow-xl transition-transform transform hover:scale-105 overflow-hidden">
          <div class="absolute top-0 left-0 bg-red-500 text-white text-xs font-bold px-2 py-1 rounded-br-lg">
              Coming Soon
          </div>
          <img src="/images/thumbnail/memo.jpg" alt="" class="w-full mb-2">
          <h3 class="text-xl font-semibold text-purple-700">{{ t('site.home_desc_title_2') }}</h3>
          <p class="text-gray-600 text-center p-8">{{ t('site.home_desc_2') }}</p>
      </div>
      <div class="flex flex-col items-center bg-pink-100 p-6 rounded-lg shadow-xl transition-transform transform hover:scale-105 overflow-hidden">
          <div class="absolute top-0 left-0 bg-red-500 text-white text-xs font-bold px-2 py-1 rounded-br-lg">
              BETA
          </div>
          <img src="/images/thumbnail/ai.jpg" alt="" class="w-full mb-2">
          <h3 class="text-xl font-semibold text-pink-700">{{ t('site.home_desc_title_3') }}</h3>
          <p class="text-gray-600 text-center p-8">{{ t('site.home_desc_3') }}</p>
      </div>
      <div class="flex flex-col items-center bg-purple-100 p-6 rounded-lg shadow-xl transition-transform transform hover:scale-105 overflow-hidden">
          <img src="/images/thumbnail/review.jpg" alt="" class="w-full mb-2">
          <h3 class="text-xl font-semibold text-purple-700">{{ t('site.home_desc_title_4') }}</h3>
          <p class="text-gray-600 text-center p-8">{{ t('site.home_desc_4') }}</p>
      </div>
      <div class="flex flex-col items-center bg-pink-100 p-6 rounded-lg shadow-xl transition-transform transform hover:scale-105 overflow-hidden">
        <div class="absolute top-0 left-0 bg-red-500 text-white text-xs font-bold px-2 py-1 rounded-br-lg">
          BETA
        </div>
        <img src="/images/thumbnail/chapter.jpg" alt="" class="w-full mb-2">
        <h3 class="text-xl font-semibold text-pink-700">{{ t('site.home_desc_title_5') }}</h3>
        <p class="text-gray-600 text-center p-8">{{ t('site.home_desc_5') }}</p>
      </div>
      <div class="flex flex-col items-center bg-purple-100 p-6 rounded-lg shadow-xl transition-transform transform hover:scale-105 overflow-hidden">
        <div class="absolute top-0 left-0 bg-red-500 text-white text-xs font-bold px-2 py-1 rounded-br-lg">
          BETA
        </div>
        <img src="/images/thumbnail/cloud.jpg" alt="" class="w-full mb-2">
        <h3 class="text-xl font-semibold text-purple-700">{{ t('site.home_desc_title_6') }}</h3>
        <p class="text-gray-600 text-center p-8">{{ t('site.home_desc_6') }}</p>
      </div>
      </section>

      <!-- Latest Works -->
      <section class="w-full mb-12 rounded-lg p-8 bg-opacity-25">
        <h2 class="text-3xl font-semibold text-white mb-6 text-center">{{ t('site.home_tab_1') }}</h2>
        <div class="overflow-x-auto">
            <RecentWorksScroller :works="pageinfo.recents"></RecentWorksScroller>
        </div>
      </section>


      <section class="w-full mb-12 rounded-lg p-8 bg-opacity-25">
        <h2 class="text-3xl font-semibold text-white mb-6 text-center">{{ t('site.home_tab_2') }}</h2>
        <div class="overflow-x-auto">
        <RecentWorksScroller :works="pageinfo.reviews"></RecentWorksScroller>
        </div>
      </section>

      <!-- Publishers -->
      <!--
      <section class="w-full mb-12 rounded-lg p-8 bg-opacity-25">
        <h2 class="text-3xl font-semibold text-white mb-6 text-center">{{ t('site.home_tab_3') }}</h2>
        <div class="overflow-x-auto">
          <RecentWorksScroller :works="works"></RecentWorksScroller>
          </div>
      </section>
      -->
  </div>
  <CardBanner />
</template>

<script setup lang="ts">
import { RecentWorksScroller,CardBanner } from '@/components';
import { Loading } from "@/components";
import { Book } from '@/entities';
import { onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { ArticleRepository } from '@/repositories';

const { t, locale } = useI18n();
const articleRep = new ArticleRepository();

var pageinfo = ref({
  isReady:false,
  recents:[] as Book[],
  reviews:[] as Book[]
});

onMounted(async () => {
  await getOpenBooks();
  await GetBookswithReview();
  pageinfo.value.isReady = true;
});

const getOpenBooks = async () => {
  let rst = await articleRep.GetOpenBooks({ curPage : 1, pageSize : 10 });
  if (rst.check) {
    pageinfo.value.recents = rst.data;
  }
};

const GetBookswithReview = async () => {
  let rst = await articleRep.GetBookswithReview({ curPage : 1, pageSize : 10 });
  if (rst.check) {
    pageinfo.value.reviews = rst.data;
  }
};
</script>

<style scoped>
/* Section background colors */
section:nth-of-type(odd) {
background-color: #f9f9f9; /* Light gray for alternating sections */
}

section:nth-of-type(even) {
background-color: #ffffff; /* White for alternating sections */
}

/* Header and Footer background color */
header, footer {
background-color: #fbeff8; /* Light pink */
}
</style>
