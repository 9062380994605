<template>
  <Loading :loading="!layout_info.isReady" />
  <div v-show="layout_info.isReady" :key="layout_info.key">
    <Sidebar />
    <div class="relative md:ml-64 bg-blueGray-100">
      <AdminNavbar />
      <div class="relative mainbg hidden md:flex lg:flex" style="padding-top:75px"></div>
      <div class="px-4 md:px-10 mx-auto w-full pt-8" style="min-height:80vh;">
        <router-view />
      </div>
      <div class="px-4 md:px-10 mx-auto w-full pt-8">
        <FooterAdmin />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { AdminNavbar,Sidebar,FooterAdmin } from "@/components";
  import { onMounted,getCurrentInstance, ref } from 'vue';
  import { AuthService } from '@/services';
  import store from '@/store';
  import { Loading } from "@/components";
  import { ValidateHelper } from '@/utility';

  const auth = new AuthService();
  const validate = new ValidateHelper();
  const { proxy } = getCurrentInstance()!;
  
  var layout_info = ref({
    key:0,
    isReady:false
  })

  onMounted(async () => {
    let user = await auth.getUser();
    if (!validate.IsNullOrWhiteSpace(user?.access_token)) {
      layout_info.value.isReady = true;
      layout_info.value.key += 1;

      if (proxy?.LoadingClose) {
        proxy.LoadingClose();
      }
    } else {
      auth.logout();
    }
  });
</script>
