<template>
    <Loading :loading="!pageinfo.isReady" />
  <div :key="pageinfo.key">
    <!-- Card stats -->
    <div class="flex flex-wrap">
      <div class="w-full lg:w-6/12 xl:w-3/12 px-2">
        <CardStats
          statSubtitle="Articles"
          :statTitle="`${pageinfo.stats.articles}`"
          :statDescripiron="`${t('site.articlecount')}`"
          statIconName="fa-regular fa-file-lines"
          statIconColor="bg-red-500"
        />
      </div>
      <div class="w-full lg:w-6/12 xl:w-3/12 px-2">
        <CardStats
          statSubtitle="WRITERS"
          :statTitle="`${pageinfo.stats.writers}`"
          :statDescripiron="`${t('site.writerCount')}`"
          statIconName="fa-solid fa-book"
          statIconColor="bg-orange-500"
        />
      </div>
      <div class="w-full lg:w-6/12 xl:w-3/12 px-2">
        <CardStats
          statSubtitle="REVIEWS"
          :statTitle="`${pageinfo.stats.reviews}`"
          :statDescripiron="`${t('site.reviewCount')}`"
          statIconName="fa-solid fa-book-open-reader"
          statIconColor="bg-emerald-500"
        />
      </div>
      <div class="w-full lg:w-6/12 xl:w-3/12 px-2">
        <CardStats
          statSubtitle="LIKES"
          :statTitle="`${pageinfo.stats.likes}`"
          :statDescripiron="`${t('site.likeCount')}`"
          statIconName="fa-solid fa-heart"
          statIconColor="bg-pink-500"
        />
      </div>
    </div>
  </div>
  <div class="bg-white shadow-lg p-6 mt-5 rounded-lg">
  <!-- Hero Section -->
  <div class="bg-orange-100 text-white p-8 rounded-lg mb-8">
    <h1 class="text-3xl font-bold mb-4 maincolor">{{ t('site.WelcomeCreators') }}</h1>
    <p class="text-lg subcolor">{{ t('site.welcomement') }}</p>
    <button class="mt-6 bg-white maincolor font-semibold py-2 px-4 rounded-lg shadow hover:bg-subbg" @click="fnStart">
      {{ t('site.start') }}
    </button>
  </div>

  <!-- Main Content Grid -->
  <div class="flex flex-wrap -mx-4">
    <!-- Latest Projects -->
    <div class="w-full xl:w-8/12 px-4 mb-8">
      <div class="bg-blueGray-100 p-6 rounded-lg shadow">
        <h2 class="text-xl font-semibold text-maincolor mb-4">{{ t('site.LatestBook') }}</h2>
        <div class="space-y-4">
          <div class="flex justify-between p-4 bg-subbg rounded-lg " v-for="(book,index) in pageinfo.books" :key="`book_${index}`">
            <div class="w-3/4">
              <h3 class="text-lg font-semibold text-subcolor ">{{ book.title }}</h3>
              <div class="text-sm text-gray-600 break-words w-full">{{ StringHelper.truncateText(book.description, 120) }}</div>
            </div>
            <div class="w-1/4">
              <router-link :to="`/book/publish/${book.id}`" class="mainbg text-white py-2 px-4 rounded-lg shadow hover:bg-subcolor">
                {{ t('common.ViewDetails') }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Resources & Tips -->
    <div class="w-full xl:w-4/12 px-4 mb-8">
      <div class="bg-gray-100 p-6 rounded-lg shadow-lg mb-8">
  <h2 class="text-2xl font-bold text-maincolor mb-6">{{ t('site.todaybest') }}</h2>
  <ul class="divide-y divide-gray-100">
    <li class="py-4 flex items-center" v-for="(best,index) in pageinfo.bests" :key="`best_${index}`">
      <img :src="getCover(best)" alt="Book Cover 1" class="w-16 h-24 object-cover p-1 bg-white shadow-md mr-4">
      <div>
        <h3 class="text-lg font-semibold text-subcolor">{{ best.title }}</h3>
        <p class="text-gray-600 text-sm"><WriterName :writerId="best.writerId" /></p>
        <p class="text-gray-600 text-sm">{{ t(`site.${fnShowCategory(best.majorCategoryId)}`) }}</p>
      </div>
    </li>
  </ul>
</div>

    </div>
  </div>
</div>

</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { CardStats,Loading,WriterName } from '@/components';
import { Book, MainStats,Categories } from '@/entities';
import { ArticleRepository } from '@/repositories';
import router from '@/router';
import { StringHelper } from '@/utility';
import { useI18n } from 'vue-i18n';
import { ValidateHelper } from '@/utility';

const articleRep = new ArticleRepository();
const { t, locale } = useI18n();
const validate = new ValidateHelper();

var pageinfo = ref({
  isReady : false,
  key : 0,
  books:[] as Book[],
  bests:[] as Book[],
  categories:[] as Categories[],
  stats : new MainStats()
});


onMounted(async () => {
  pageinfo.value.isReady = true;
  await getStats();
  await fnCategory();
  await getBooks();
  await getBests();
  pageinfo.value.key += 1;
});

const getStats = async () => {
  let rst = await articleRep.GetMainStats();
  if (rst.check) {
    pageinfo.value.stats = rst.data;
  }
};

const fnStart = () => {
  router.push(`/book/settings`);
};

const getBooks = async () => {
  let rst = await articleRep.GetOpenBooks({ curPage : 1, pageSize : 5 });
  if (rst.check) {
    pageinfo.value.books = rst.data;
  }
};

const getBests = async () => {
  let rst = await articleRep.GetBests({ curPage : 1, pageSize : 5 });
  if (rst.check) {
    pageinfo.value.bests = rst.data;
  }
};

const getCover = (book:Book) => {
  let result = 'https://via.placeholder.com/64x96';

  if (!validate.IsNullOrWhiteSpace(book.cover)) {
    result = book.cover
  } else if (!validate.IsNullOrWhiteSpace(book.thumbnail)) {
    result = book.thumbnail;
  }

  return result;
};

const fnCategory = async () => {
    let rst = await articleRep.GetCategories();
    if (rst.check) {
      pageinfo.value.categories = rst.data;
    }
  };

  const fnShowCategory = (categoryId:string):string => {
    let result = "none";
    if (pageinfo.value.categories !== null && pageinfo.value.categories !== undefined && pageinfo.value.categories.length > 0) {
      let first = pageinfo.value.categories.filter(x => x.majorId == categoryId);
      if (first !== null && first !== undefined && first.length > 0) {
        result = first[0].majorName;
      }
    }
    return result;
  };

  const fnSubShowCategory = (categoryId:string, subcategoryId:string):string => {
    let result = "none";
    if (pageinfo.value.categories !== null && pageinfo.value.categories !== undefined && pageinfo.value.categories.length > 0) {
      let first = pageinfo.value.categories.filter(x => x.majorId == categoryId);
      if (first !== null && first !== undefined && first.length > 0) {
        let second = first[0];
        if (second.subCategories !== null && second.subCategories !== undefined && second.subCategories.length > 0) {
          let arr = second.subCategories.filter(x => x.minorId === subcategoryId);
          if (arr !== null && arr !== undefined && arr.length > 0) {
            result = arr[0].minorName;
          }
        }
      }
    }
    return result;
  };
</script>
