const UserType = {
    User : "User",
    LocalManager : "LocalManager",
    Manager : "Manager",
    Admin : "Admin"
};

const Authorization = {
    isRequired : (requiredtype:string, usertype:string):boolean => {
        if (usertype.trim().toUpperCase() === UserType.Admin.trim().toUpperCase()) {
            return true;
        }

        if (usertype.trim().toUpperCase() === UserType.Manager) {
            if (requiredtype.trim().toUpperCase() === UserType.Admin.trim().toUpperCase()) {
                return false;
            } else {
                return true;
            }
        }

        if (usertype.trim().toUpperCase() === UserType.LocalManager) {
            if (requiredtype.trim().toUpperCase() === UserType.User.trim().toUpperCase()) {
                return false;
            } else {
                return true;
            }
        }

        if (usertype.trim().toUpperCase() === UserType.User) {
            if (requiredtype.trim().toUpperCase() === UserType.User.trim().toUpperCase()) {
                return true;
            } else {
                return false;
            }
        }

        return false;
    }
}

export { UserType,Authorization }