import config from "@/config";
import { KeyMaster } from "@/entities/KeyMaster";
import { PagingParameter, ReturnValues } from "@/models";
import { ApiHelper } from "@/utility"

export class AiRepository {
    async GetKeyList(paging:PagingParameter):Promise<ReturnValues> {
        let url = `${config.apis.ai}/api/KeyMaster/List?CurPage=${paging.curPage}&PageSize=${paging.pageSize}`;
        //console.log('AiRepository GetKeyList : ', url);
        return await ApiHelper.GetAsync(url);
    }

    async GetKeyView(keymasterId:string):Promise<ReturnValues> {
        let url = `${config.apis.ai}/api/KeyMaster/View/${keymasterId}`;
        //console.log('AiRepository GetKeyView : ', url);
        return await ApiHelper.GetAsync(url);
    }

    async PutKeyMaster(keymaster:KeyMaster):Promise<ReturnValues> {
        let url = `${config.apis.ai}/api/KeyMaster/Modify/${keymaster.id}`;
        //console.log('AiRepository PostKeyRegist : ', url);
        let jsonData = {
            keyMasterId: keymaster.id,
            apiKey: keymaster.apiKey,
            serviceProvider: keymaster.provider
          };
        return await ApiHelper.PutAsync(url, jsonData);
    }

    async DeleteKeyMaster(keymasterId:string):Promise<ReturnValues> {
        let url = `${config.apis.ai}/api/KeyMaster/Remove/${keymasterId}`;
        //console.log('AiRepository PostKeyRegist : ', url);
        return await ApiHelper.DeleteAsync(url);
    }

    async GetProviders(paging:PagingParameter):Promise<ReturnValues> {
        let url = `${config.apis.ai}/api/ServiceProvider/List?CurPage=${paging.curPage}&PageSize=${paging.pageSize}`;
        //console.log('AiRepository GetProviders : ', url);
        return await ApiHelper.GetAsync(url);
    }

    async GetProvider(providerId:string):Promise<ReturnValues> {
        let url = `${config.apis.ai}/api/ServiceProvider/View/${providerId}`;
        //console.log('AiRepository GetProvider : ', url);
        return await ApiHelper.GetAsync(url);
    }

    async PostKeyRegist(apikey:string, providerName:string):Promise<ReturnValues> {
        let url = `${config.apis.ai}/api/KeyMaster/Regist`;
        //console.log('AiRepository PostKeyRegist : ', url);
        let jsonData = {
            apiKey: apikey,
            serviceProvider: providerName
          };
        return await ApiHelper.PostAsync(url, jsonData);
    }

    async PostAgentIntroduction(body:string):Promise<ReturnValues> {
        let url = `${config.apis.ai}/api/Agent/AI/novelist/introduction`;
        //console.log('AiRepository PostAgentIntroduction : ', url);
        let jsonData = { message: encodeURIComponent(body) };
        return await ApiHelper.PostAsync(url, jsonData);
    }

    async PostAgentContinuation(body:string):Promise<ReturnValues> {
        let url = `${config.apis.ai}/api/Agent/AI/novelist/Continuation`;
        //console.log('AiRepository PostAgentContinuation : ', url);
        let jsonData = { message: encodeURIComponent(body) };
        return await ApiHelper.PostAsync(url, jsonData);
    }

    async PostAgentProsePolish(body:string):Promise<ReturnValues> {
        let url = `${config.apis.ai}/api/Agent/AI/novelist/ProsePolish`;
        //console.log('AiRepository PostAgentProsePolish : ', url);
        let jsonData = { message: encodeURIComponent(body) };
        return await ApiHelper.PostAsync(url, jsonData);
    }
    
    async PostAgentRecommend(body:string):Promise<ReturnValues> {
        let url = `${config.apis.ai}/api/Agent/AI/novelist/recommend`;
        //console.log('AiRepository PostAgentRecommend : ', url);
        let jsonData = { message: encodeURIComponent(body) };
        return await ApiHelper.PostAsync(url, jsonData);
    }

    async PostAgentCorrect(body:string):Promise<ReturnValues> {
        let url = `${config.apis.ai}/api/Agent/AI/editor/Correct`;
        //console.log('AiRepository PostAgentCorrect : ', url);
        let jsonData = { message: encodeURIComponent(body) };
        return await ApiHelper.PostAsync(url, jsonData);
    }
}
