<template>
<svg 
  width="10" 
  height="10" 
  viewBox="0 0 100 100" 
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
>
  <circle 
    cx="50" 
    cy="50" 
    r="40" 
    stroke="#3498db" 
    stroke-width="8" 
    fill="none" 
    stroke-dasharray="251.2" 
    stroke-dashoffset="0" 
    transform="rotate(-90 50 50)"
  >
    <animate
      attributeName="stroke-dashoffset"
      values="0;251.2"
      dur="1.5s"
      repeatCount="indefinite"
    />
  </circle>
</svg>
</template>