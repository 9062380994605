<template>
    <div class="w-full flex justify-between mb-1">
    <div class="ml-2">
      <h3 class="text-2xl subcolor uppercase"><b>Request Reviews</b></h3>
    </div>
    <div class="text-sm mr-2">
      <router-link to="/main" class="text-gray-400 uppercase text-sm"><small>HOME</small></router-link>
      <span class="px-2 text-gray-300">&gt;</span>
      <a href="#" class="text-gray-500 uppercase text-md"><b>RequestReviews</b></a>
    </div>
  </div>
  
  <div :key="pageinfo.key" class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white">
    <Bookinfo :book="pageinfo.book" />
    <div class="block w-full overflow-x-auto">
      <div>
        <input type="text" name="title" class="focus:outline-none w-full px-4 py-2 border-2 border-gray-100" v-model="pageinfo.review.title" placeholder="리뷰 제목을 입력하세요." />
      </div>
      <div>
        <WebEditor :content="pageinfo.review.content" :multiupload="false" :key="`review_${pageinfo.key}`" :callback="handleUpdate" />
      </div>
    </div>
    <hr/>

    <div class="p-4">
      <div class="w-full flex justify-between">
        <div><button @click="fnSave" class="px-4 py-2 uppercase mainbg text-white font-semibold rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-opacity-50" type="button">SAVE</button></div>
        <div><button class="px-4 py-2 uppercase bg-gray-400 text-white font-semibold rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-opacity-50" @click="goList">Back</button></div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { onMounted, ref,defineProps } from 'vue';
  import { ArticleRepository,MemberRepository,NotifyRepository } from '@/repositories';
  import { WebEditor } from '@/components/Editor';
  import { Book, MyMemberShip, Permission, Review } from '@/entities';
  import { MessageBox } from '@/utility';
  import { useRouter } from 'vue-router';
  import { useI18n } from 'vue-i18n';
  import dayjs from 'dayjs';
  import utc from 'dayjs/plugin/utc';
  import localizedFormat from 'dayjs/plugin/localizedFormat'; 
  import { ReturnValues } from '@/models';
  import { ProductType } from '@/models/enums';
  import { ValidateHelper } from '@/utility';
  import { Bookinfo } from '@/components';

  dayjs.extend(utc);
  dayjs.extend(localizedFormat);

  const articleRep = new ArticleRepository();
  const memberRep = new MemberRepository();
  const nofifyRep = new NotifyRepository();
  const router = useRouter();
  const { t, locale } = useI18n();
  const validate = new ValidateHelper();

  interface bookConfigProperty {
    bookid?:string|null|undefined,
    reviewId?:string|null|undefined
  }

  const property = defineProps<bookConfigProperty>();

  var pageinfo = ref({
    key:0,
    myMemberShip : new MyMemberShip(),
    permission: new Permission(),
    book:new Book(),
    review : new Review()
  });

  onMounted(async() => {
    await GetMyMemberShip();
    await GetBookInfo();
    await GetReview();
  });

  const GetBookInfo = async () => {
    if (property.bookid !== null && property.bookid !== undefined && property.bookid.trim() !== '') {
      let rst = await articleRep.GetBook(property.bookid);
      if (rst.check) {
        pageinfo.value.book = rst.data;
        pageinfo.value.key += 1;
      }
    } else {
      pageinfo.value.book = new Book();
      pageinfo.value.key += 1;
    }
  };

  const GetReview = async () => {
    if (property.reviewId !== null && property.reviewId !== undefined && property.reviewId.trim() !== '') {
      let rst = await articleRep.GetReview(property.reviewId);
      if (rst.check) {
        pageinfo.value.review = rst.data;
        pageinfo.value.key += 1;
      }
    } else {
      pageinfo.value.review = new Review();
      pageinfo.value.key += 1;
    }
  };

  const handleUpdate = (content:string) => {
    pageinfo.value.review.content = content;
  };

  const GetMyMemberShip = async () => {
    let rst = await memberRep.GetMyMemberShip();
    if (rst.check && rst.data !== null) {
      pageinfo.value.myMemberShip = rst.data;
      pageinfo.value.permission.Aceept(pageinfo.value.myMemberShip.memberShip);
      console.log('pageinfo.value.permission : ', pageinfo.value.permission);
    } else {
      MessageBox.Alert("멤버십이 필요합니다.", () => {
        router.push('/account/membership?tab=membership');
      });
    }
  }

  const fnSave = async () => {
    if (property.bookid !== null && property.bookid !== undefined && property.bookid.trim() !== '') {
      pageinfo.value.review.targetId = property.bookid;
      pageinfo.value.review.targetType = ProductType.Book;
    }

    if (validate.IsNullOrWhiteSpace(pageinfo.value.review.title)) {
      MessageBox.Alert(t('system.required') + ' : title');
      return;
    }

    if (validate.IsNullOrWhiteSpace(pageinfo.value.review.content)) {
      MessageBox.Alert(t('system.required') + ' : content');
      return;
    }

    try {
      let rst = new ReturnValues();
      if (property.reviewId !== null && property.reviewId !== undefined && property.reviewId.trim() !== '') {
        rst = await articleRep.PutReviewUpdate(pageinfo.value.review);
      } else {
        rst = await articleRep.PostReviewRegist(pageinfo.value.review);
      }
      
      if (rst.check) {
        MessageBox.Success(t('system.Saved'), () => {
          router.push(`/myreview/list`);
        });
      } else {
        MessageBox.Alert(rst.message);
      }
    } catch (e:any) {
      let body = `##Description \r\n`;
      body += `position : Articles/ReviewBookForm.vue\r\n`;
      body += `\r\n`;
      body += `###Summary\r\n`;
      body += `${e.stack}`;
      await nofifyRep.PostGithubIssue(`[Studio] Error : ${e.message}`, body, `bug`);
    }
  };

  const goList = () => {
    MessageBox.Confirm("저장하지 않은 정보는 유실됩니다.", () => {
      router.push(`/review/book/${pageinfo.value.book.id}`);
    });
  };
</script>