import { ProductType } from "@/models/enums";
import { Condition } from "./Condition";

export class Review {
    public id:string;
    public targetId:string;
    public targetType:string;
    public title:string;
    public content:string;
    public condition:Condition;

    constructor() {
        this.id = "";
        this.targetId = "";
        this.targetType = ProductType.Book;
        this.title = "";
        this.content = "";
        this.condition = new Condition();
    }
}