// src/store/modules/favorites.ts
import { Book } from '@/entities';
import { ArticleRepository } from '@/repositories';

interface FavoritesState {
  books: Book[];
}

const state: () => FavoritesState = () => {
  const savedFavorites = localStorage.getItem('favorites');
  return {
    books: savedFavorites ? JSON.parse(savedFavorites) : [],
  };
};

const getters = {
  getFavorites(state: FavoritesState) {
    return state.books;
  },
};

const mutations = {
  setFavorites(state: FavoritesState, books: Book[]) {
    state.books = books;
    localStorage.setItem('favorites', JSON.stringify(state.books));
  },
  addFavorite(state: FavoritesState, book: Book) {
    state.books.push(book);
    localStorage.setItem('favorites', JSON.stringify(state.books));
  },
  removeFavorite(state: FavoritesState, bookId: string) {
    state.books = state.books.filter(book => book.id !== bookId);
    localStorage.setItem('favorites', JSON.stringify(state.books));
  },
  clearFavorites(state: FavoritesState) {
    state.books = [];
    localStorage.removeItem('favorites');
  },
};

const actions = {
  async fetchFavorites({ commit }: { commit: Function }) {
    const articleRep = new ArticleRepository();
    let rst = await articleRep.GetFavorites({ curPage: 1, pageSize: 20 });
    if (rst.check) {
      commit('setFavorites', rst.data);
    }
  },
  addFavorite({ commit }: { commit: Function }, book: Book) {
    commit('addFavorite', book);
  },
  removeFavorite({ commit }: { commit: Function }, bookId: number) {
    commit('removeFavorite', bookId);
  },
  clearFavorites({ commit }: { commit: Function }) {
    commit('clearFavorites');
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
