import config from '../config';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { MemberRepository } from '@/repositories';
import store from '@/store';

const memberRep = new MemberRepository();
const signalr = new HubConnectionBuilder()
    .withUrl(config.apis.notification + '/message')
    .build();

(async () => {
    try {
        await signalr.start();
        console.log('Connected to SignalR Hub');

        // SignalR 이벤트 핸들링: ReceiveMessage
        signalr.on('ReceiveMessage', async (msgType, subscriber, message, option) => {
            let rst = await memberRep.GetUser();
            if (rst.check) {
                if (rst.data) {
                    switch (String(msgType).trim().toUpperCase()) {
                        case "NOTIFY":
                            if (String(subscriber).trim() === "*" || String(subscriber).trim().toLowerCase() === String(rst.data.id).trim().toLocaleLowerCase()) {
                                console.log(`Received message: Type=${msgType}, Subscriber=${subscriber}, Message=${message}, Option=${option}`);
                                try {
                                    store.dispatch('messages/addMessages', message);
                                } catch (e:any) {
                                    console.log(e.stack);
                                }
                            }
                            break;
                    }
                }
            }
        });

    } catch (error) {
        console.error('Error connecting to SignalR Hub:', error);
    }
})();

export default signalr;
