const StringHelper = {
  maskText(input: string): string {
    const visibleChars = 8;
    if (input.length <= visibleChars) {
      return input; // 텍스트 길이가 8글자 이하일 경우 그대로 반환
    }
    const visiblePart = input.slice(0, visibleChars); // 앞부분 8글자
    const maskedPart = '*'.repeat(input.length - visibleChars); // 나머지 부분을 *로 대체
    return visiblePart + maskedPart;
  },
  truncateText(input: string, maxLength: number): string {
    if (input.length <= maxLength) {
      return input; // 텍스트 길이가 maxLength 이하일 경우 그대로 반환
    }
    return input.slice(0, maxLength) + '..'; // 텍스트 길이가 maxLength보다 크면 자르고 '..' 추가
  },
  convertNewlinesToBr(input: string): string {
    return input.replace(/\n/g, '<br>'); // 줄내림 문자를 <br> 태그로 대체
  },
  formatNumberWithCommas(value: number): string {
    return value.toLocaleString('en-US');
  }
};

export default StringHelper;
