interface MessagesState {
  messages: string[];
}

const state: () => MessagesState = () => {
  // 로컬 스토리지에서 상태 복원
  const savedMessages = localStorage.getItem('messages');
  return {
    messages: savedMessages ? JSON.parse(savedMessages) : [],
  };
};

const getters = {
  getMessages(state: MessagesState) {
    return state.messages;
  },
};

const mutations = {
  setMessages(state: MessagesState, messages: string[]) {
    state.messages = messages;
    // 상태를 로컬 스토리지에 저장
    localStorage.setItem('messages', JSON.stringify(state.messages));
  },
  addMessages(state: MessagesState, message: string) {
    console.log('message : ', message)
    state.messages.push(message);
    // 상태를 로컬 스토리지에 저장
    localStorage.setItem('messages', JSON.stringify(state.messages));
  },
  clearMessages(state: MessagesState) {
    state.messages = [];
    // 상태를 로컬 스토리지에 저장
    localStorage.removeItem('messages');
  },
};

const actions = {
  addMessages({ commit }: { commit: Function }, message: string) {
    console.log('message : ', message)
    commit('addMessages', message);
  },
  clearMessages({ commit }: { commit: Function }) {
    commit('clearMessages');
  },
};

export default {
  namespaced: true, // 모듈을 네임스페이스로 설정
  state,
  getters,
  mutations,
  actions,
};
