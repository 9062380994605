import { Condition } from "./Condition";

export class BoardMaster {
    public id:string;
    public masterCode:string;
    public siteSlug:string;
    public boardType:string;
    public title:string;
    public ownerId:string;
    public condition:Condition;

    constructor() {
        this.id = "";
        this.masterCode = "";
        this.siteSlug = "";
        this.boardType = "";
        this.title = "";
        this.ownerId = "";
        this.condition = new Condition();
    }
}