<template>
    <div>
      <!-- 메신저 이모티콘 버튼 -->
      <button
        @click="toggleChat"
        class="chat-button"
        aria-label="Open Chat"
      >
        <i class="fa-solid fa-comment-dots chat-icon"></i>
      </button>
  
      <!-- 플로팅 채팅창 -->
      <transition name="slide">
        <div
          v-if="isOpen"
          class="chat-window"
        >
          <!-- 채팅창 헤더 -->
          <div class="bg-pink-500 text-white p-4 flex justify-between items-center rounded-t-lg">
            <h3 class="text-lg font-semibold">Chat with Us</h3>
            <button @click="toggleChat" class="text-white focus:outline-none">
              <i class="fa-solid fa-times"></i>
            </button>
          </div>
          <!-- 채팅 내용 -->
          <div class="chat-messages p-4 overflow-y-auto flex-grow" ref="chatContainer">
            <div v-for="(msg, index) in pageinfo.messages" :key="`msg_${index}`" class="mb-2">
              <div v-if="msg.owner === 'system'" class="flex justify-start mb-4">
                <div class="bg-blue-500 text-white p-3 rounded-lg max-w-xs shadow-sm">
                  <div v-html="msg.message"></div>
                </div>
              </div>
              <div v-else class="flex justify-end mb-4">
                <div class="bg-gray-200 text-gray-800 p-3 rounded-lg max-w-xs shadow-sm">
                  <div v-html="msg.message"></div>
                </div>
              </div>
            </div>
            <!-- 로딩 상태 표시 -->
            <div v-if="pageinfo.isChatLoading" class="flex justify-start mb-4">
              <div class="bg-gray-300 text-gray-800 p-3 rounded-lg max-w-xs shadow-sm">
                <i class="fa-solid fa-spinner fa-spin mr-2"></i> {{ t('system.chatwait') }}
              </div>
            </div>
          </div>
          <!-- 메시지 입력 영역 -->
          <div class="py-4 border-t border-gray-200 w-full px-4">
            <form @submit.prevent="handleSubmit" class="flex items-center w-full">
              <input 
                id="inputTextArea"
                type="text" 
                placeholder="Type a message..."
                class="flex-1 p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-pink-500"
                v-model="pageinfo.inputmessage"
              />
              <button 
                type="submit"
                class="ml-2 bg-pink-500 text-white p-2 rounded-lg hover:bg-pink-600 shadow-md"
              >
                <i class="fa-solid fa-paper-plane"></i>
              </button>
            </form>
          </div>
        </div>
      </transition>
    </div>
  </template>
  
  <script setup lang="ts">
  import { ref, onMounted, nextTick, defineProps, computed } from 'vue';
  import { MyMemberShip, Permission } from '@/entities';
  import { MemberRepository, CopilotRepository, AiRepository } from '@/repositories';
  import { useRouter } from 'vue-router';
  import { useI18n } from 'vue-i18n';
  import { useStore } from 'vuex';
  import { MessageBox } from '@/utility';
  import { ChatLog,ChatLogItem } from '@/models';
  
  const isOpen = ref(false);
  const chatContainer = ref<HTMLElement | null>(null);
  const memberRep = new MemberRepository();
  const copilotRep = new CopilotRepository();
  const aiRep = new AiRepository();
  const router = useRouter();
  const { t } = useI18n();
  const store = useStore();
  
  interface chatConfigProperty {
    targetId: string;
  }
  
  const property = defineProps<chatConfigProperty>();
  const chatLog = computed(() => store.getters['chatlog/getLogById'](property.targetId) as ChatLog);
  
  var pageinfo = ref({
    targetId: property.targetId,
    myMemberShip: new MyMemberShip(),
    permission: new Permission(),
    messages: [] as ChatLogItem[],
    isChatLoading: false,
    inputmessage: ''
  });
  
  // 중복 메시지 전송 방지 상태
  let isSendingMessage = false;
  
  onMounted(async () => {
    await GetMyMemberShip();
  
    if (chatLog.value !== null && chatLog.value !== undefined) {
      if (chatLog.value.logs !== null && chatLog.value.logs !== undefined && chatLog.value.logs.length > 0) {
        pageinfo.value.messages = chatLog.value.logs;
      }
    }
  });
  
  const GetMyMemberShip = async () => {
    let rst = await memberRep.GetMyMemberShip();
    if (rst.check && rst.data !== null) {
      pageinfo.value.myMemberShip = rst.data;
      pageinfo.value.permission.Aceept(pageinfo.value.myMemberShip.memberShip);
      if (!(pageinfo.value.permission.AITalk || pageinfo.value.permission.Assistant)) {
        MessageBox.Alert(t('common.nopermission'), () => {
          router.push('/account/membership?tab=membership');
        });
      }
    } else {
      MessageBox.Alert(t('common.requiredmembership'), () => {
        router.push('/account/membership?tab=membership');
      });
    }
  };
  
  const SystemMessage = (message: string) => {
    pageinfo.value.messages.push({ message: message, owner: 'system' });
    pageinfo.value.isChatLoading = false;
    fnScrollDown();
  
    store.dispatch('chatlog/addLog', {
      id: property.targetId,
      message: message,
      owner: 'system',
    });
  };
  
  const UserMessage = async (message: string) => {
    if (isSendingMessage) return; // 중복 방지
  
    isSendingMessage = true;
    pageinfo.value.messages.push({ message: message, owner: 'user' });
    store.dispatch('chatlog/addLog', {
      id: property.targetId,
      message: message,
      owner: 'user',
    });
  
    pageinfo.value.isChatLoading = true;
    let rst;
    if (pageinfo.value.permission.AITalk) {
      rst = await copilotRep.BookTalk(pageinfo.value.targetId, message);
    } else if (pageinfo.value.permission.Assistant) {
      rst = await aiRep.PostAgentRecommend(message);
    } else {
      MessageBox.Alert(t('system.invalid_access'));
      pageinfo.value.isChatLoading = false;
      isSendingMessage = false;
      return;
    }
  
    if (rst.check) {
      SystemMessage(rst.value);
    }
    pageinfo.value.isChatLoading = false;
    isSendingMessage = false;
  };
  
  const handleSubmit = () => {
    if (!pageinfo.value.inputmessage.trim()) return; // 빈 메시지 전송 방지
    UserMessage(pageinfo.value.inputmessage);
    pageinfo.value.inputmessage = '';
  };
  
  const fnScrollDown = () => {
    nextTick(() => {
      if (chatContainer.value) {
        chatContainer.value.scrollTop = chatContainer.value.scrollHeight;
      }
    });
  };
  
  const toggleChat = () => {
    isOpen.value = !isOpen.value;
  };
  </script>
  
  <style scoped>
  .chat-button {
    position: fixed;
    bottom: 16px;
    right: 16px;
    background-color: #ec4899; /* 핑크색 */
    color: white;
    padding: 12px;
    border-radius: 50%;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .chat-button:hover {
    background-color: #db2777; /* 조금 더 진한 핑크색 */
  }
  
  .chat-icon {
    font-size: 1.5rem;
  }
  
  .chat-window {
    position: fixed;
    bottom: 90px; /* 버튼 위로 위치 조정 */
    right: 16px;
    width: 25vw; /* 화면의 1/4 크기 */
    height: 70vh; /* 화면의 70% 높이 */
    background-color: white; /* 흰색 배경 */
    border-radius: 10px;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
    z-index: 50;
    display: flex;
    flex-direction: column;
  }
  
  .chat-messages {
    max-height: 60vh;
    padding: 1rem; /* 패딩 추가 */
    overflow-y: auto;
  }
  </style>
  
  <style scoped>
  /* 슬라이드 애니메이션 */
  .slide-enter-active,
  .slide-leave-active {
    transition: transform 0.3s ease-in-out;
  }
  .slide-enter-from,
  .slide-leave-to {
    transform: translateX(100%);
  }
  </style>
  