<template>
    <div
      class="relative flex flex-col min-w-0 break-words bg-white rounded mb-6 xl:mb-0 shadow-lg"
    >
      <div class="flex-auto p-4">
        <div class="flex flex-wrap">
          <div class="relative w-full pr-4 max-w-full flex-grow flex-1">
            <h5 class="text-blueGray-400 uppercase font-bold text-xs">
              {{ componentInfo.statSubtitle }}
            </h5>
            <span class="font-semibold text-xl text-blueGray-700">
              {{ componentInfo.statTitle }}
            </span>
          </div>
          <div class="relative w-auto pl-4 flex-initial">
            <div
              class="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 shadow-lg rounded-full"
              :class="[componentInfo.statIconColor]"
            >
              <i :class="[componentInfo.statIconName]"></i>
            </div>
          </div>
        </div>
        <p class="text-sm text-blueGray-400 mt-4">
          <span class="whitespace-nowrap">{{ componentInfo.statDescripiron }}</span>
        </p>
      </div>
    </div>
  </template>

<script setup lang="ts">
import { ref,defineProps } from 'vue';

interface CardStatsParameter {
    statSubtitle: string;
    statTitle: string;
    statDescripiron: string;
    statIconName: string;
    statIconColor: string;
}

const property = defineProps<CardStatsParameter>();

var componentInfo = ref({
    statSubtitle : property.statSubtitle || 'Traffic',
    statTitle : property.statTitle || '',
    statDescripiron : property.statDescripiron || 'Since last month',
    statIconName : property.statIconName || 'far fa-chart-bar',
    statIconColor : property.statIconColor || 'bg-red-500'
});

</script>
  