<template>
  <div class="w-full flex justify-between mb-1">
    <div class="ml-2">
      <h3 class="text-2xl subcolor uppercase"><b>{{ t('site.payment_feature_6') }}</b></h3>
    </div>
    <div class="text-sm mr-2">
      <router-link to="/main" class="text-gray-400 uppercase text-sm"><small>HOME</small></router-link>
      <span class="px-2 text-gray-300">&gt;</span>
      <a href="#" class="text-gray-500 uppercase text-md"><b>{{ t('site.payment_feature_6') }}</b></a>
    </div>
  </div>
  <div :key="pageinfo.key" class="w-full flex justify-between space-x-3">
    <div
      class="relative flex flex-col min-w-0 break-words w-1/4 mb-6 shadow-lg rounded bg-white">
      <div class="block w-full overflow-x-hidden">
        <table class="items-center w-full bg-transparent border-collapse">
          <tbody>
            <tr v-for="(book,index) in pageinfo.books" :key="`book_${index}`">
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center"
              >
                <img
                  :src="(book.thumbnail !== null && book.thumbnail !== undefined && String(book.thumbnail).trim() !== '') ? book.thumbnail : 'https://via.placeholder.com/48x48'"
                  class="h-12 w-12 bg-white rounded-full border"
                  alt="..."
                  @click="fnView(book)"
                />
                <a href="#" @click="fnView(book)"
                  class="ml-3 font-bold light"
                >
                  {{ book.title }}
                </a>
              </td>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
                <WriterName :writer-id="book.writerId" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <hr/>
      <div class="py-2 flex justify-center items-center">
          <Pagination :total-count="pageinfo.totalcount" :cur-page="pageinfo.curpage" :callback="fnPaging" />
      </div>
    </div>
    
    <div
      class="relative flex flex-col min-w-0 break-words w-3/4 mb-6 shadow-lg rounded bg-white">
      <Bookinfo :book="pageinfo.target" v-if="pageinfo.target !== null" />
      <div class="block w-full overflow-x-hidden p-4">
  <div v-if="pageinfo.target === null">
    <p class="text-orange-500 text-lg">{{ t('site.selectedyourbook') }}</p>
  </div>
  <div v-else>
    <MessageLayer :target-id="pageinfo.target.id" v-if="pageinfo.target !== null && pageinfo.target !== undefined" />
  </div>
</div>

    </div>
  </div>

</template>

<script setup lang="ts">
  import { Pagination,Bookinfo,MessageLayer } from '@/components';
  import { onMounted, ref } from 'vue';
  import { ArticleRepository,MemberRepository,CopilotRepository } from '@/repositories';
  import { Book, MyMemberShip, Permission } from '@/entities';
  import { MessageBox } from '@/utility';
  import { useRouter, useRoute } from 'vue-router';
  import { useI18n } from 'vue-i18n';
  import dayjs from 'dayjs';
  import utc from 'dayjs/plugin/utc';
  import localizedFormat from 'dayjs/plugin/localizedFormat'; 
  import WriterName from '@/components/Atom/WriterName.vue';
  import { ValidateHelper } from '@/utility';

  dayjs.extend(utc);
  dayjs.extend(localizedFormat);
  const articleRep = new ArticleRepository();
  const memberRep = new MemberRepository();
  const copilotRep = new CopilotRepository();
  const router = useRouter();
  const route = useRoute();
  const { t, locale } = useI18n();
  const validate = new ValidateHelper();

  var pageinfo = ref({
    key:0,
    myMemberShip : new MyMemberShip(),
    permission: new Permission(),
    books:[] as Book[],
    messages:[] as any[],
    target: null as Book|null,
    totalcount:0,
    isChatLoading : false,
    inputmessage:'',
    curpage:1,
    keyword : "" as string|null|undefined
  });

  onMounted(async() => {
    await GetMyMemberShip();
    await GetBooks(1);

    pageinfo.value.key += 1;
  });

  const fnPaging = async (curpage:number) => {
    console.log(curpage)
  };

  const fnView = (item:Book) => {
    pageinfo.value.target = item;
    pageinfo.value.messages = [];
    pageinfo.value.key += 1;

    SystemMessage(t('site.ment_talk_01').replaceAll('{{title}}',pageinfo.value.target.title));
  };

  const GetMyMemberShip = async () => {
    let rst = await memberRep.GetMyMemberShip();
    if (rst.check && rst.data !== null) {
      pageinfo.value.myMemberShip = rst.data;
      pageinfo.value.permission.Aceept(pageinfo.value.myMemberShip.memberShip);
      console.log('pageinfo.value.permission : ', pageinfo.value.permission);
      if (!pageinfo.value.permission.AITalk) {
        MessageBox.Alert(t('common.nopermission'), () => {
          router.push('/account/membership?tab=membership');
        });
      }
    } else {
      MessageBox.Alert(t('common.requiredmembership'), () => {
        router.push('/account/membership?tab=membership');
      });
    }
  }

  const GetBooks = async (curpage:number) => {
    let rst = await articleRep.GetBooks({ curPage : curpage, pageSize : 20 });
    console.log('GetBooks : ', rst);
    if (rst.check) {
      pageinfo.value.books = rst.data;
      pageinfo.value.totalcount = rst.code;
    }
  };

  const SystemMessage = (message:string) => {
    pageinfo.value.messages.push({ message : message, owner : 'system' });
    pageinfo.value.isChatLoading = false;
    pageinfo.value.key += 1;
  };

  const UserMessage = async (message:string) => {
    pageinfo.value.messages.push({ message : message, owner : 'user' });
    pageinfo.value.key += 1;

    if (pageinfo.value.target?.id !== null && pageinfo.value.target?.id !== undefined) {
      pageinfo.value.isChatLoading = true;
      let rst = await copilotRep.BookTalk(pageinfo.value.target?.id, message);
      if (rst.check) {
        SystemMessage(rst.value);
        setTimeout(() => {
          document.getElementById("inputTextArea")?.focus();
        }, 100);
      }
    } else {
      MessageBox.Alert(t('site.firstBookSelected'));
    }
  };

  const handleSubmit = () => {
    UserMessage(pageinfo.value.inputmessage);
    pageinfo.value.inputmessage = '';
  };
</script>