<template>
  <nav :key="pageinfo.key"
    class="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl bg-white flex flex-wrap items-center justify-between relative md:w-64 z-10 py-4 px-6"
  >
    <div
      class="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto"
    >
      <!-- Toggler -->
      <button
        class="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
        type="button"
        v-on:click="toggleCollapseShow('bg-white m-2 py-3 px-6')"
      >
        <i class="fas fa-bars"></i>
      </button>
      <!-- Brand -->
      <router-link class="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0 flex items-center" to="/main">
        <img src="/logo/Logo_pink.png" alt="gn2studio" class="w-full" />
      </router-link>

      <!-- User -->
      <ul class="md:hidden items-center flex flex-wrap list-none">
        <li class="inline-block relative">
          <LanguageDropdown />
        </li>
        <li class="inline-block relative">
          <NotificationDropdown />
        </li>
        <li class="inline-block relative">
          <UserDropdown />
        </li>
      </ul>
      <!-- Collapse -->
      <div
        class="md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded"
        v-bind:class="pageinfo.collapseShow"
      >
        <!-- Collapse header -->
        <div
          class="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-blueGray-200"
        >
          <div class="flex flex-wrap">
            <div class="w-6/12">
              <router-link class="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0" to="/main">
                GN2STUDIO
              </router-link>
            </div>
            <div class="w-6/12 flex justify-end">
              <button
                type="button"
                class="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                v-on:click="toggleCollapseShow('hidden')"
              >
                <i class="fas fa-times"></i>
              </button>
            </div>
          </div>
        </div>
        <!-- Form -->
        <form class="mt-6 mb-4 md:hidden">
          <div class="mb-3 pt-0">
            <input
              type="text"
              placeholder="Search"
              class="border-0 px-3 py-2 h-12 border border-solid border-blueGray-500 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-base leading-snug shadow-none outline-none focus:outline-none w-full font-normal"
            />
          </div>
        </form>

        <!-- Divider -->
        <hr class="my-4 md:min-w-full" />

        <ul class="md:flex-col md:min-w-full flex flex-col list-none mb-4">
          <li class="inline-block relative">
            <SiteDropdown />
          </li>
        </ul>

        <!-- Divider -->
        <hr class="my-4 md:min-w-full" />
        <!-- Heading -->
        <h6
          class="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline"
        >
        {{ t('site.myarticles') }}
        </h6>
        <!-- Navigation -->

        <ul class="md:flex-col md:min-w-full flex flex-col list-none">
          <li class="items-center">
            <router-link
              to="/book/list"
              v-slot="{ href, navigate, isActive }"
            >
              <a
                :href="href"
                @click="() => { navigate(); toggleCollapseShow('hidden'); }"
                class="text-xs uppercase py-3 font-bold block"
                :class="[
                  isActive
                    ? 'text-pink-500 hover:text-pink-600'
                    : 'text-gray-700 hover:text-gray-500',
                ]"
              >
                <i
                  class="fa-solid fa-book mr-2 text-sm"
                  :class="[isActive ? 'opacity-75' : 'text-gray-300']"
                ></i>
                {{ t('site.Books') }}
              </a>
            </router-link>
          </li>

          <li class="items-center">
            <router-link to="/memo/list" v-slot="{ href, navigate, isActive }">
              <a
                :href="href"
                @click="() => { navigate(); toggleCollapseShow('hidden'); }"
                class="text-xs uppercase py-3 font-bold block"
                :class="[
                  isActive
                    ? 'text-pink-500 hover:text-pink-600'
                    : 'text-gray-700 hover:text-gray-500',
                ]"
              >
                <i
                  class="fa-solid fa-toolbox mr-2 text-sm"
                  :class="[isActive ? 'opacity-75' : 'text-gray-300']"
                ></i>
                {{ t('site.Memos') }}
              </a>
            </router-link>
          </li>

          <li class="items-center">
            <router-link to="/myreview/list" v-slot="{ href, navigate, isActive }">
              <a
                :href="href"
                @click="() => { navigate(); toggleCollapseShow('hidden'); }"
                class="text-xs uppercase py-3 font-bold block"
                :class="[
                  isActive
                    ? 'text-pink-500 hover:text-pink-600'
                    : 'text-gray-700 hover:text-gray-500',
                ]"
              >
                <i
                  class="fa-solid fa-book-open-reader mr-2 text-sm"
                  :class="[isActive ? 'opacity-75' : 'text-gray-300']"
                ></i>
                {{ t('site.MyReviews') }}
              </a>
            </router-link>
          </li>
        </ul>



        <hr class="my-4 md:min-w-full" />
        <!-- Heading -->
        <h6
          class="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline"
        >
        {{ t('site.AI') }}
        </h6>
        <!-- Navigation -->
        <ul class="md:flex-col md:min-w-full flex flex-col list-none">
          <li class="items-center">
            <router-link
              to="/agent/talk"
              v-slot="{ href, navigate, isActive }"
            >
              <a
                :href="href"
                @click="() => { navigate(); toggleCollapseShow('hidden'); }"
                class="text-xs uppercase py-3 font-bold block"
                :class="[
                  isActive
                    ? 'text-pink-500 hover:text-pink-600'
                    : 'text-gray-700 hover:text-gray-500',
                ]"
              >
                <i
                  class="fas fa-comments mr-2 text-sm"
                  :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"
                ></i>
                {{ t('site.payment_feature_6') }}
              </a>
            </router-link>
          </li>
          <li class="items-center">
            <router-link
              to="/agent/trend"
              v-slot="{ href, navigate, isActive }"
            >
              <a
                :href="href"
                @click="() => { navigate(); toggleCollapseShow('hidden'); }"
                class="text-xs uppercase py-3 font-bold block"
                :class="[
                  isActive
                    ? 'text-pink-500 hover:text-pink-600'
                    : 'text-gray-700 hover:text-gray-500',
                ]"
              >
                <i
                  class="fas fa-chart-simple mr-2 text-sm"
                  :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"
                ></i>
                {{ t('site.payment_feature_7') }}
              </a>
            </router-link>
          </li>
          <li class="items-center">
            <router-link
              to="/agent/review"
              v-slot="{ href, navigate, isActive }"
            >
              <a
                :href="href"
                @click="() => { navigate(); toggleCollapseShow('hidden'); }"
                class="text-xs uppercase py-3 font-bold block"
                :class="[
                  isActive
                    ? 'text-pink-500 hover:text-pink-600'
                    : 'text-gray-700 hover:text-gray-500',
                ]"
              >
                <i
                  class="fas fa-message mr-2 text-sm"
                  :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"
                ></i>
                {{ t('site.payment_feature_8') }}
              </a>
            </router-link>
          </li>
        </ul>

        <!-- Divider -->
        <hr class="my-4 md:min-w-full" />
        <!-- Heading -->
        <h6
          class="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline"
        >
          {{ t('site.ACTIVITY') }}
        </h6>
        <!-- Navigation -->

        <ul class="md:flex-col md:min-w-full flex flex-col list-none">
          <li class="items-center">
            <router-link to="/book/publish/list" v-slot="{ href, navigate, isActive }">
              <a
                :href="href"
                @click="() => { navigate(); toggleCollapseShow('hidden'); }"
                class="text-xs uppercase py-3 font-bold block"
                :class="[
                  isActive
                    ? 'text-pink-500 hover:text-pink-600'
                    : 'text-gray-700 hover:text-gray-500',
                ]"
              >
                <i
                  class="fa-solid fa-book mr-2 text-sm"
                  :class="[isActive ? 'opacity-75' : 'text-gray-300']"
                ></i>
                {{ t('site.OpenBooks') }}
              </a>
            </router-link>
          </li>
          <li class="items-center">
            <router-link to="/review/list" v-slot="{ href, navigate, isActive }">
              <a
                :href="href"
                @click="() => { navigate(); toggleCollapseShow('hidden'); }"
                class="text-xs uppercase py-3 font-bold block"
                :class="[
                  isActive
                    ? 'text-pink-500 hover:text-pink-600'
                    : 'text-gray-700 hover:text-gray-500',
                ]"
              >
                <i
                  class="fa-solid fa-file-lines mr-2 text-sm"
                  :class="[isActive ? 'opacity-75' : 'text-gray-300']"
                ></i>
                {{ t('site.RequestReviews') }}
              </a>
            </router-link>
          </li>
        </ul>

        <!-- Divider -->
        <hr class="my-4 md:min-w-full" />
        <!-- Heading -->
        <h6
          class="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline"
        >
          {{ t('common.Boards') }}
        </h6>
        <!-- Navigation -->

        <ul class="md:flex-col md:min-w-full flex flex-col list-none">
          <li class="items-center" v-for="(board, index) in pageinfo.masters" :key="`board_${index}`">
            <router-link
              :to="`/board/list/${board.masterCode}`"
              v-slot="{ href, navigate, isActive }"
            >
              <a
                :href="href"
                @click="() => { navigate(); toggleCollapseShow('hidden'); }"
                class="text-xs py-3 font-bold block"
                :class="[
                  isActive
                    ? 'text-pink-500 hover:text-pink-600'
                    : 'text-gray-700 hover:text-gray-500',
                ]"
              >
                <i
                  class="fa-solid fa-list mr-2 text-sm"
                  :class="[isActive ? 'opacity-75' : 'text-gray-300']"
                ></i>
                {{ t(`common.${board.title}`) }}
              </a>
            </router-link>
          </li>
        </ul>

        <!-- Divider -->
        <hr class="my-4 md:min-w-full" />
        <!-- Heading -->
        <h6
          class="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline"
        >
        {{ t('common.MyPage') }}
        </h6>
        <!-- Navigation -->
        <ul class="md:flex-col md:min-w-full flex flex-col list-none">
          <li class="items-center">
            <router-link
              to="/account/membership?tab=membership"
              v-slot="{ href, navigate, isActive }"
            >
              <a
                :href="href"
                @click="() => { navigate(); toggleCollapseShow('hidden'); }"
                class="text-xs uppercase py-3 font-bold block"
                :class="[
                  isActive
                    ? 'text-pink-500 hover:text-pink-600'
                    : 'text-gray-700 hover:text-gray-500',
                ]"
              >
                <i
                  class="fas fa-credit-card mr-2 text-sm"
                  :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"
                ></i>
                {{ t('common.membership') }}
              </a>
            </router-link>
          </li>
          <li class="items-center">
            <router-link
              to="/account/settings"
              v-slot="{ href, navigate, isActive }"
            >
              <a
                :href="href"
                @click="() => { navigate(); toggleCollapseShow('hidden'); }"
                class="text-xs uppercase py-3 font-bold block"
                :class="[
                  isActive
                    ? 'text-pink-500 hover:text-pink-600'
                    : 'text-gray-700 hover:text-gray-500',
                ]"
              >
                <i
                  class="fas fa-gear mr-2 text-sm"
                  :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"
                ></i>
                {{ t('common.settings') }}
              </a>
            </router-link>
          </li>

        </ul>

        <!-- Divider -->
        <hr class="my-4 md:min-w-full" />
        <!-- Heading -->
        <h6
          class="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline"
        >
        {{ t('site.guide') }}
        </h6>
        <!-- Navigation -->
        <ul class="md:flex-col md:min-w-full flex flex-col list-none">
          <li class="items-center">
            <router-link
              to="/document/aiuse"
              v-slot="{ href, navigate, isActive }"
            >
              <a
                :href="href"
                @click="() => { navigate(); toggleCollapseShow('hidden'); }"
                class="text-xs uppercase py-3 font-bold block"
                :class="[
                  isActive
                    ? 'text-pink-500 hover:text-pink-600'
                    : 'text-gray-700 hover:text-gray-500',
                ]"
              >
                <i
                  class="fas fa-credit-card mr-2 text-sm"
                  :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"
                ></i>
                {{ t('site.aiuseguide') }}
              </a>
            </router-link>
          </li>
          <li class="items-center">
            <router-link
              to="/document/youtube"
              v-slot="{ href, navigate, isActive }"
            >
              <a
                :href="href"
                @click="() => { navigate(); toggleCollapseShow('hidden'); }"
                class="text-xs uppercase py-3 font-bold block"
                :class="[
                  isActive
                    ? 'text-pink-500 hover:text-pink-600'
                    : 'text-gray-700 hover:text-gray-500',
                ]"
              >
                <i
                  class="fa-brands fa-youtube text-sm"
                  :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"
                ></i>
                {{ t('site.movieguide') }}
              </a>
            </router-link>
          </li>

        </ul>
      </div>
    </div>
  </nav>
</template>


<script setup lang="ts">
import { onMounted, ref } from "vue";
import { NotificationDropdown,UserDropdown,SiteDropdown,LanguageDropdown } from '../Dropdowns'
import { MessageBox } from "@/utility";
import { BoardMaster } from "@/entities"; 
import { BoardRepository } from '@/repositories';
import { useI18n } from 'vue-i18n';

const boardRep = new BoardRepository();
const { t, locale } = useI18n();

var pageinfo = ref({
  key:0,
  collapseShow: "hidden",
  masters:[] as BoardMaster[]
})

onMounted(async () => {
  await GetMasters();
  pageinfo.value.key += 1;
});

const toggleCollapseShow = (classes:string) => {
  pageinfo.value.collapseShow = classes;
};

const GetMasters = async () => {
  let rst = await boardRep.GetMasters({ curPage : 1, pageSize : 30 });
  if (rst.check && rst.data != null) {
    pageinfo.value.masters = rst.data.reverse();
  }
};

</script>
