<template>
    <div class="flex flex-col" style="height:100%;">
      <div class="flex-grow overflow-x-hidden overflow-y-scroll" ref="chatContainer">
        <div class="p-4" v-for="(msg,index) in pageinfo.messages" :key="`msg_${index}`">
            <div class="flex justify-start mb-4" v-if="msg.owner === 'system'">
            <div class="bg-blue-500 text-white p-2 rounded-lg max-w-xs">
                <div v-html="msg.message"></div>
            </div>
            </div>
            <div class="flex justify-end mb-4" v-else>
            <div class="bg-gray-200 text-gray-800 p-2 rounded-lg max-w-xs">
                <div v-html="msg.message"></div>
            </div>
            </div>
        </div>
      </div>


      <div class="w-full chatui">
        <div v-show="pageinfo.isChatLoading" class="flex justify-start"><Wait /><div style="font-size:9px;margin-left:4px;">{{ t('system.chatwait') }}</div></div>
        <!-- 입력 필드 영역 -->
        <div class="py-4 border-t border-gray-200 w-full">
            <div class="flex items-center w-full">
            <form @submit.prevent="handleSubmit" class="flex items-center w-full">
                <input 
                id="inputTextArea"
                type="text" 
                placeholder="Type a message..."
                class="flex-1 p-2 border border-gray-300 rounded-lg"
                v-model="pageinfo.inputmessage"
                />
                <button 
                type="submit"
                class="ml-2 bg-blue-500 text-white p-2 rounded-lg hover:bg-blue-600 send"
                >
                Send
                </button>
            </form>
            </div>
        </div>
        
      </div>
    </div>
</template>

<script setup lang="ts">
import { Wait } from '@/components';
import { onMounted,ref,defineProps,nextTick,computed } from 'vue';
import { MemberRepository,CopilotRepository,AiRepository } from '@/repositories';
import { MyMemberShip, Permission } from '@/entities';
import { MessageBox } from '@/utility';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { ChatLog,ChatLogItem } from '@/models';
import { useStore } from 'vuex';

const store = useStore();
const memberRep = new MemberRepository();
const copilotRep = new CopilotRepository();
const aiRep = new AiRepository();
const router = useRouter();
const { t } = useI18n();

interface chatConfigProperty {
    targetId:string
}

const property = defineProps<chatConfigProperty>();
const chatContainer = ref<HTMLElement | null>(null);
const chatLog = computed(() => store.getters['chatlog/getLogById'](property.targetId) as ChatLog);

var pageinfo = ref({
    key:0,
    targetId:property.targetId,
    myMemberShip : new MyMemberShip(),
    permission: new Permission(),
    messages:[] as ChatLogItem[],
    isChatLoading : false,
    inputmessage:''
});

onMounted(async() => {
    await GetMyMemberShip();
    pageinfo.value.key += 1;

    if (chatLog.value !== null && chatLog.value !== undefined) {
        if (chatLog.value.logs !== null && chatLog.value.logs !== undefined && chatLog.value.logs.length > 0) {
            pageinfo.value.messages = chatLog.value.logs;
        }
    }
});

const GetMyMemberShip = async () => {
    let rst = await memberRep.GetMyMemberShip();
    if (rst.check && rst.data !== null) {
        pageinfo.value.myMemberShip = rst.data;
        pageinfo.value.permission.Aceept(pageinfo.value.myMemberShip.memberShip);
        console.log('pageinfo.value.permission : ', pageinfo.value.permission);
        if (!(pageinfo.value.permission.AITalk || pageinfo.value.permission.Assistant)) {
        MessageBox.Alert(t('common.nopermission'), () => {
            router.push('/account/membership?tab=membership');
        });
        }
    } else {
        MessageBox.Alert(t('common.requiredmembership'), () => {
            router.push('/account/membership?tab=membership');
        });
    }
}

const SystemMessage = (message:string) => {
    pageinfo.value.messages.push({ message : message, owner : 'system' });
    pageinfo.value.isChatLoading = false;
    pageinfo.value.key += 1;
    fnScrollDown();

    store.dispatch('chatlog/addLog', {
      id: property.targetId,
      message: message,
      owner: 'system',
    });
};

const UserMessage = async (message:string) => {
    pageinfo.value.messages.push({ message : message, owner : 'user' });
    pageinfo.value.key += 1;

    store.dispatch('chatlog/addLog', {
      id: property.targetId,
      message: message,
      owner: 'user',
    });

    pageinfo.value.isChatLoading = true;
    if (pageinfo.value.targetId !== null && pageinfo.value.targetId !== undefined && String(pageinfo.value.targetId).trim() !== '') {
        if (pageinfo.value.permission.AITalk) {
            let rst = await copilotRep.BookTalk(pageinfo.value.targetId, message);
            if (rst.check) {
                SystemMessage(rst.value);
                setTimeout(() => {
                    fnScrollDown();
                    document.getElementById("inputTextArea")?.focus();
                }, 100);
            }
        } else if (pageinfo.value.permission.Assistant) {
            let rst = await aiRep.PostAgentRecommend(message);
            console.log('rst : ', rst);
            if (rst.check) {
                SystemMessage(rst.value);
                setTimeout(() => {
                    fnScrollDown();
                    document.getElementById("inputTextArea")?.focus();
                }, 100);
            }
        } else {
            MessageBox.Alert(t('system.invalid_access'));
        }
    } else {
        let rst = await copilotRep.UserTalk(message);
        if (rst.check) {
            SystemMessage(rst.value);
            setTimeout(() => {
                fnScrollDown();
                document.getElementById("inputTextArea")?.focus();
            }, 100);
        }
    }
};

const handleSubmit = () => {
    UserMessage(pageinfo.value.inputmessage);
    pageinfo.value.inputmessage = '';
};

const fnScrollDown = () => {
    nextTick(() => {
        if (chatContainer.value) {
            chatContainer.value.scrollTop = chatContainer.value.scrollHeight;
        }
    });
};
</script>

<style scoped>
.chatui {
    margin-top:0px;
}

.chatui input[type=text] {
    width:calc(100% - 80px);
}

.chatui button.send {
    width:80px;
}
</style>