import { useToast } from 'vue-toastification';

const toast = useToast();

const MessageBox = {
  Alert: function (msg: string, callback?: Function) {
    toast.error(msg, {
      onClose: () => {
        if (callback !== null && callback !== undefined) callback();
      },
    });
  },
  Confirm: function (msg: string, callback?: Function) {
    const confirmCallback = callback || (() => { console.log('empty'); });
    toast.warning(msg, {
      timeout: false, // 사용자의 확인을 기다리기 위해 타임아웃 비활성화
      closeOnClick: true,
      pauseOnFocusLoss: false,
      onClick: () => {
        confirmCallback();
        toast.clear(); // 알림이 클릭되면 수동으로 제거
      },
    });
  },
  Success: function (msg: string, callback?: Function) {
    console.log('Notification Success : ', msg);
    toast.success(msg, {
      onClose: () => {
        if (callback !== null && callback !== undefined) callback();
      },
    });
  },
  Info: function (msg: string, callback?: Function) {
    toast.info(msg, {
      onClose: () => {
        if (callback !== null && callback !== undefined) callback();
      },
    });
  },
  Warning: function (msg: string, callback?: Function) {
    toast.warning(msg, {
      onClose: () => {
        if (callback !== null && callback !== undefined) callback();
      },
    });
  },
};

export default MessageBox;
