<template>
  <div class="w-full flex justify-between mb-1">
    <div class="ml-2">
      <h3 class="text-2xl subcolor uppercase"><b>{{ t('site.OpenBooks') }}</b></h3>
    </div>
    <div class="text-sm mr-2">
      <router-link to="/main" class="text-gray-400 uppercase text-sm"><small>HOME</small></router-link>
      <span class="px-2 text-gray-300">&gt;</span>
      <a href="#" class="text-gray-500 uppercase text-md"><b>{{ t('site.OpenBooks') }}</b></a>
    </div>
  </div>
  
  <div :key="pageinfo.key" class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white">
    <Bookinfo :book="pageinfo.book" />
    <div class="block w-full overflow-x-auto">
      <div>
        <input type="text" name="title" class="focus:outline-none w-full px-4 py-2 border-2 border-gray-100" v-model="pageinfo.article.title" :placeholder="t('site.requiredrefviewtitle')" />
      </div>
      <div>
        <div class="w-full p-5 break-words" v-html="HtmlHelper.write(pageinfo.article.content)"></div>
      </div>
    </div>
    <hr/>

    <div class="p-4">
      <div class="w-full flex justify-between">
        <div>
          <button class="px-4 py-2 uppercase mainbg text-white font-semibold rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-opacity-50 mr-2" type="button">
            <i class="fa-solid fa-heart"></i> {{ t('common.Like') }}
          </button>
        </div>
        

        <div><button class="px-4 py-2 uppercase bg-gray-400 text-white font-semibold rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-opacity-50" @click="goList">{{t('common.List')}}</button></div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { onMounted, ref,defineProps } from 'vue';
  import { ArticleRepository } from '@/repositories';
  import { Article, Book } from '@/entities';
  import { useRouter } from 'vue-router';
  import { useI18n } from 'vue-i18n';
  import dayjs from 'dayjs';
  import utc from 'dayjs/plugin/utc';
  import localizedFormat from 'dayjs/plugin/localizedFormat'; 
  import { HtmlHelper } from '@/utility';
  import { Bookinfo } from '@/components';

  dayjs.extend(utc);
  dayjs.extend(localizedFormat);

  const articleRep = new ArticleRepository();
  const router = useRouter();
  const { t, locale } = useI18n();

  interface bookConfigProperty {
    bookid?:string|null|undefined,
    articleId?:string|null|undefined
  }

  const property = defineProps<bookConfigProperty>();

  var pageinfo = ref({
    key:0,
    book:new Book(),
    article : new Article()
  });

  onMounted(async() => {
    await GetBookInfo();
    await GetArticle();
  });

  const GetBookInfo = async () => {
    if (property.bookid !== null && property.bookid !== undefined && property.bookid.trim() !== '') {
      let rst = await articleRep.GetBook(property.bookid);
      if (rst.check) {
        pageinfo.value.book = rst.data;
        pageinfo.value.key += 1;
      }
    } else {
      pageinfo.value.book = new Book();
      pageinfo.value.key += 1;
    }
  };

  const GetArticle = async () => {
    if (property.articleId !== null && property.articleId !== undefined && property.articleId.trim() !== '') {
      let rst = await articleRep.GetArticle(property.articleId);
      if (rst.check) {
        pageinfo.value.article = rst.data;
        pageinfo.value.key += 1;
      }
    } else {
      pageinfo.value.article = new Article();
      pageinfo.value.key += 1;
    }
  };

  const goList = () => {
    router.push(`/book/publish/${pageinfo.value.book.id}`);
  };
</script>