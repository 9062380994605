import { ChatLogItem } from "./ChatLogItem";

export class ChatLog {
    public id:string;
    public logs:ChatLogItem[];

    constructor() {
        this.id = "";
        this.logs = [] as ChatLogItem[];
    }
}