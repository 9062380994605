<template>
  <div>
    <AuthNavbar />
    <main>
      <section class="relative w-full h-full py-40 min-h-screen">
        <div
          class="absolute top-0 w-full h-full bg-blueGray-800 bg-no-repeat bg-full"
          :style="`background-image: url('/images/bg/homeBg.jpg');`"
        ></div>
        <router-view />
        <FooterSmall absolute />
      </section>
    </main>
  </div>
</template>

<script setup lang="ts">
import { onMounted,getCurrentInstance } from 'vue';
import { AuthNavbar,FooterSmall } from '@/components'

const { proxy } = getCurrentInstance()!;


onMounted(async () => {
  if (proxy?.LoadingClose) {
    proxy.LoadingClose();
  }
});

</script>
