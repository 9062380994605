import { Condition } from "./Condition";

export class Article {
    public id:string;
    public title:string;
    public content:string;
    public thumbnail:string;
    public readCount:number;
    public likeCount:number;
    public shareCount:number;
    public originURL:string;
    public writer:string;
    public publishedAt:string;
    public originKey:string;
    public writerId:string;
    public ownerId:string;
    public seq:number;
    public siteId:string;
    public chapterId:string;
    public tags:string;
    public condition:Condition;

    constructor() {
        this.id = "";
        this.title = "";
        this.content = "";
        this.thumbnail = "";
        this.readCount = 0;
        this.likeCount = 0;
        this.shareCount = 0;
        this.originURL = "";
        this.writer = "";
        this.publishedAt = "";
        this.originKey = "";
        this.writerId = "";
        this.ownerId = "";
        this.seq = 0;
        this.siteId = "";
        this.chapterId = "";
        this.tags = "";
        this.condition = new Condition();
    }
}