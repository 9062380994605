export class PaymentHistory {
    public id:string;
    public resultCode:string;
    public resultMsg:string;
    public orderId:string;
    public status:string;
    public paidAt:Date;
    public amount:number;
    public goodsName:string;
    public tid:string;

    constructor() {
        this.id = "";
        this.resultCode = "";
        this.resultMsg = "";
        this.orderId = "";
        this.status = "";
        this.paidAt = new Date();
        this.amount = 0;
        this.goodsName = "";
        this.tid = "";
    }
}