<template>
  <Loading :loading="pageinfo.isLoading" />
  
  <div class="w-full flex justify-between mb-1">
    <div class="ml-2">
      <h3 class="text-2xl subcolor uppercase"><b>{{t('common.membership')}}</b></h3>
    </div>
    <div class="text-sm mr-2">
      <router-link to="/main" class="text-gray-400 uppercase text-sm"><small>HOME</small></router-link>
      <span class="px-2 text-gray-300">&gt;</span>
      <a href="#" class="text-gray-500 uppercase text-md"><b>{{t('common.membership')}}</b></a>
    </div>
  </div>

  <div class="bg-white shadow-sm p-4" :key="pageinfo.key">
      <div class="mb-4">
        <ul class="flex border-b">
          <li class="-mb-px mr-1">
            <a :class="{'border-l border-t border-r rounded-t text-blue-700': activeTab === 'current'}" class="bg-white inline-block py-2 px-4 font-semibold cursor-pointer bg-white" @click="activeTab = 'current'">{{t('common.MyMembership')}}</a>
          </li>
          <li class="mr-1">
            <a :class="{'border-l border-t border-r rounded-t text-blue-700': activeTab === 'membership'}" class="bg-white inline-block py-2 px-4 font-semibold cursor-pointer bg-white" @click="activeTab = 'membership'">{{t('common.SelectMembership')}}</a>
          </li>
        </ul>
      </div>
      <div v-if="activeTab === 'current'">
        <div class="mb-6" v-if="pageinfo.myMemberShip !== null && pageinfo.myMemberShip !== undefined && String(pageinfo.myMemberShip.id).trim() !== ''">
          <h2 class="text-2xl font-bold mb-4">{{t('common.CurrentMembership')}}</h2>
          <p class="text-gray-700"><b class="text-lg text-pink-700">{{ pageinfo.myMemberShip.memberShip?.title }}</b> {{t('common.membership')}}</p>
          <p class="text-gray-500">{{t('common.ExpiresOn')}}: {{ dayjs.utc(pageinfo.myMemberShip.expiredDate).local().format('YYYY-MM-DD HH:mm') }}</p>
          <p class="mt-4 mb-4">
            <router-link class="text-white border-1 shadow-md px-4 py-2 bg-blueGray-500 mt-4" to="/document/aiuse">{{ t('site.HowtouseAI') }}</router-link>
          </p>
        </div>
        <div class="mb-6" v-else>
          <h2 class="text-2xl font-bold mb-4">{{t('common.CurrentMembership')}}</h2>
          <p class="text-gray-700">{{t('common.Nohavemembership')}}</p>
          <p class="text-gray-500"><a href="#" @click="activeTab = 'membership'">{{t('common.Paymembership')}}</a></p>
        </div>
        <div class="mt-4">
          <h2 class="text-2xl font-bold mb-4">{{t('common.PurchaseHistory')}}</h2>
          <table class="min-w-full bg-white">
            <thead>
              <tr>
                <th class="py-2 px-4 border-b border-gray-200 bg-gray-50 text-left text-sm leading-4 font-medium text-gray-500 uppercase tracking-wider">{{t('common.membership')}}</th>
                <th class="py-2 px-4 border-b border-gray-200 bg-gray-50 text-left text-sm leading-4 font-medium text-gray-500 uppercase tracking-wider">{{t('common.Price')}}</th>
                <th class="py-2 px-4 border-b border-gray-200 bg-gray-50 text-left text-sm leading-4 font-medium text-gray-500 uppercase tracking-wider">{{ t('common.OrderNo') }}</th>
                <th class="py-2 px-4 border-b border-gray-200 bg-gray-50 text-left text-sm leading-4 font-medium text-gray-500 uppercase tracking-wider">{{t('common.RegistDate')}}</th>
                <th class="py-2 px-4 border-b border-gray-200 bg-gray-50 text-left text-sm leading-4 font-medium text-gray-500 uppercase tracking-wider">{{t('common.PaymentCancel')}}</th>
              </tr>
            </thead>
            <tbody v-if="pageinfo.myHistorian !== null && pageinfo.myHistorian !== undefined && pageinfo.myHistorian.length > 0">
              <tr v-for="(history, index) in pageinfo.myHistorian" :key="`history_list_${index}`">
                <td class="py-2 px-4 border-b border-gray-200">{{ history.goodsName }}</td>
                <td class="py-2 px-4 border-b border-gray-200">￦{{ StringHelper.formatNumberWithCommas(history.amount) }}</td>
                <td class="py-2 px-4 border-b border-gray-200">{{ history.orderId }}</td>
                <td class="py-2 px-4 border-b border-gray-200">{{ dayjs.utc(history.paidAt).local().format('YYYY-MM-DD HH:mm') }}</td>
                <td class="py-2 px-4 border-b border-gray-200">
                  <button class="py-1 px-2 bg-orange-400 text-white text-mini" @click="fnCancel(history)" v-if="history.status !== null && history.status !== undefined && String(history.status).trim() === 'paid'">{{t('common.PaymentCancel')}}</button>
                  <span class="text-red-500 text-sm" v-if="history.status !== null && history.status !== undefined && String(history.status).trim() === 'cancel'">{{ t('common.CancelComplete') }}</span>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="3" class="py-2 px-4 border-b border-gray-200">No History</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="py-2 flex justify-center items-center">
            <Pagination :total-count="pageinfo.totalcount" :cur-page="pageinfo.curpage" :callback="fnPaging" />
        </div>
      </div>
          <section v-else class="text-gray-700 body-font overflow-hidden">
<div class="container px-5 py-8 mx-auto flex flex-wrap" v-if="pageinfo.isReady">
  <div class="w-1/4" style="margin-top:118px">
    <div class="mt-px border-t border-gray-300 border-b border-l rounded-tl-lg rounded-bl-lg overflow-hidden">
      <p class="bg-gray-100 text-gray-900 h-12 text-center px-4 flex items-center justify-start -mt-px">{{ t('site.payment_feature_1') }}</p>
      <p class="text-gray-900 h-12 text-center px-4 flex items-center justify-start">{{ t('site.payment_feature_2') }}</p>
      <p class="bg-gray-100 text-gray-900 h-12 text-center px-4 flex items-center justify-start">{{ t('site.payment_feature_3') }}</p>
      <p class="text-gray-900 h-12 text-center px-4 flex items-center justify-start">{{ t('site.payment_feature_4') }}</p>
      <p class="bg-gray-100 text-gray-900 h-12 text-center px-4 flex items-center justify-start">{{ t('site.payment_feature_5') }}</p>
      <p class="text-gray-900 h-12 text-center px-4 flex items-center justify-start">{{ t('site.payment_feature_6') }}</p>
      <p class="bg-gray-100 text-gray-900 h-12 text-center px-4 flex items-center justify-start">{{ t('site.payment_feature_7') }}</p>
      <p class="text-gray-900 h-12 text-center px-4 flex items-center justify-start">{{ t('site.payment_feature_8') }}</p>
      <p class="bg-gray-100 text-gray-900 h-12 text-center px-4 flex items-center justify-start">{{ t('site.payment_feature_9') }}</p>
    </div>
  </div>
  <div class="flex w-3/4 flex-wrap lg:border border-gray-300 rounded-lg">
    <div class="w-1/3 lg:mt-px mb-10 lg:mb-0 border-2 border-gray-300 lg:border-none rounded-lg lg:rounded-none">
      <div class="px-2 text-center h-48 flex flex-col items-center justify-center">
        <h3 class="tracking-widest">{{ pageinfo.Free.title }}</h3>
        <h2 class="text-5xl text-gray-900 font-medium leading-none mb-4 mt-2">Free</h2>
        <span class="text-sm text-gray-600">Next 3 months</span>
      </div>
      <p class="bg-gray-100 text-gray-600 h-12 text-center px-2 flex items-center -mt-px justify-center border-t border-gray-300">100</p>
      <p class="text-gray-600 text-center h-12 flex items-center justify-center">
        <Allow />
      </p>
      <p class="bg-gray-100 text-gray-600 text-center h-12 flex items-center justify-center">
        <Disallow />
      </p>
      <p class="h-12 text-gray-600 px-6 text-center leading-relaxed flex items-center justify-center"><Disallow /></p>
      <p class="bg-gray-100 text-gray-600 h-12 text-center px-2 flex items-center -mt-px justify-center">&nbsp;</p>
      <p class="text-gray-600 text-center h-12 flex items-center justify-center">
        <Disallow />
      </p>
      <p class="bg-gray-100 text-gray-600 text-center h-12 flex items-center justify-center">
        <Disallow />
      </p>
      <p class="text-gray-600 text-center h-12 flex items-center justify-center">
        <Disallow />
      </p>
      <p class="bg-gray-100 text-gray-600 text-center h-12 flex items-center justify-center">
        <Disallow />
      </p>
      <div class="border-t border-gray-300 p-6 text-center rounded-bl-lg">
        <button @click="fnPaymentNice('Basic')" class="flex items-center mt-auto text-white mainbg border-0 py-2 px-4 w-full focus:outline-none rounded">{{ t('common.Getit') }}
          <RightArrow />
        </button>
        <p class="text-xs text-gray-500 mt-3">{{ t('site.payment_guide_1') }}</p>
      </div>
    </div>
    <div class="w-1/3 lg:-mt-px mb-10 lg:mb-0 border-2 rounded-lg border-indigo-500 relative">
      <span class="mainbg text-white px-3 py-1 tracking-widest text-xs absolute right-0 top-0 rounded-bl">POPULAR</span>
      <div class="px-2 text-center h-48 flex flex-col items-center justify-center">
        <h3 class="tracking-widest">{{ pageinfo.Premium.title }}</h3>
        <h2 class="text-5xl text-gray-900 font-medium flex items-center justify-center leading-none mb-4 mt-2">￦{{ Helper.formatNumber(pageinfo.Premium.price) }}
          <span class="text-gray-600 text-base ml-1">/mo</span>
        </h2>
        <span class="text-sm text-gray-600">￦{{ Helper.formatNumber(pageinfo.Premium.price * 12) }} per year</span>
      </div>
      <p class="bg-gray-100 text-gray-600 h-12 text-center px-2 flex items-center -mt-px justify-center border-t border-gray-300">{{ t('site.unlimited') }}</p>
      <p class="text-gray-600 text-center h-12 flex items-center justify-center">
        <Allow />
      </p>
      <p class="bg-gray-100 text-gray-600 text-center h-12 flex items-center justify-center">
        <Allow />
      </p>
      <p class="h-12 text-gray-600 text-center leading-relaxed flex items-center justify-center"><Allow /></p>
      <p class="bg-gray-100 text-gray-600 h-12 text-center px-2 flex items-center -mt-px justify-center">Gemini</p>
      <p class="text-gray-600 text-center h-12 flex items-center justify-center">
        <Disallow />
      </p>
      <p class="bg-gray-100 text-gray-600 text-center h-12 flex items-center justify-center">
        <Disallow />
      </p>
      <p class="text-gray-600 text-center h-12 flex items-center justify-center">
        <Disallow />
      </p>
      <p class="bg-gray-100 text-gray-600 text-center h-12 flex items-center justify-center">
        <Disallow />
      </p>
      <div class="p-6 text-center border-t border-gray-300">
        <button class="flex items-center mt-auto text-white mainbg border-0 py-2 px-4 w-full focus:outline-none rounded" @click="fnPaymentNice('Premium')">{{ t('common.PayNow') }}
          <RightArrow />
        </button>
        <p class="text-xs text-gray-500 mt-3">{{ t('site.payment_guide_2') }}</p>
      </div>
    </div>
    <div class="w-1/3 lg:mt-px border-2 border-gray-300 lg:border-none rounded-lg lg:rounded-none">
      <div class="px-2 text-center h-48 flex flex-col items-center justify-center">
        <h3 class="tracking-widest">{{ pageinfo.Enterprice.title }}</h3>
        <h2 class="text-5xl text-gray-900 font-medium flex items-center justify-center leading-none mb-4 mt-2">￦{{ Helper.formatNumber(pageinfo.Enterprice.price) }}
          <span class="text-gray-600 text-base ml-1">/mo</span>
        </h2>
        <span class="text-sm text-gray-600">￦{{ Helper.formatNumber(pageinfo.Enterprice.price * 12) }} per year</span>
      </div>
      <p class="bg-gray-100 text-gray-600 h-12 text-center px-2 flex items-center -mt-px justify-center border-t border-gray-300">{{ t('site.unlimited') }}</p>
      <p class="text-gray-600 text-center h-12 flex items-center justify-center">
        <Allow />
      </p>
      <p class="bg-gray-100 text-gray-600 text-center h-12 flex items-center justify-center">
        <Allow />
      </p>
      <p class="h-12 text-gray-600 text-center leading-relaxed flex items-center justify-center"><Allow /></p>
      <p class="bg-gray-100 text-gray-600 h-12 text-center px-2 flex items-center -mt-px justify-center">GPT</p>
      <p class="text-gray-600 text-center h-12 flex items-center justify-center">
        <Allow />
      </p>
      <p class="bg-gray-100 text-gray-600 text-center h-12 flex items-center justify-center">
        <Allow />
      </p>
      <p class="text-gray-600 text-center h-12 flex items-center justify-center">
        <Allow />
      </p>
      <p class="bg-gray-100 text-gray-600 text-center h-12 flex items-center justify-center">
        <Allow />
      </p>
      <div class="p-6 text-center border-t border-gray-300">
        <button class="flex items-center mt-auto text-white mainbg border-0 py-2 px-4 w-full focus:outline-none rounded" @click="fnPaymentNice('Professional')">{{ t('common.PayNow') }}
          <RightArrow />
        </button>
        <p class="text-xs text-gray-500 mt-3">{{ t('site.payment_guide_3') }}</p>
      </div>
    </div>
  </div>
</div>
</section>
    </div>

<div class="absolute w-full h-screen bg-opacity-30" style="top:0;left:0;z-index:500;height: 100%;" v-show="pageinfo.isPaymentPopup">
  <div class="absolute bg-white rounded-lg shadow-lg p-6 " style="width:500px;height:300px;top:30%;left:25%;">
    <h2 class="text-2xl font-bold text-center text-gray-800 mb-4" style="margin-top:20px;">{{t('common.EnterCoupon')}}</h2>
      <div class="mb-4" style="margin-top:20px;">
        <label for="coupon" class="block text-gray-700 font-semibold mt-4 mb-4">{{ t('common.CouponCode') }}</label>
        <input type="text" name="coupon" v-model="pageinfo.couponCode" class="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent" :placeholder="t('common.EnterCoupon')">
      </div>
      <div class="flex justify-center mt-4">
        <button @click="fnCouponRegist" class="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50">{{t('common.Regist')}}</button>
        <button @click="fnCouponCancel" class="bg-orange-500 ml-2 hover:bg-orange-600 text-white font-semibold py-2 px-4 rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-opacity-50">{{t('common.Cancel')}}</button>
      </div>
  </div>
</div>    
</template>

<script setup lang="ts">
import { ref,onMounted } from 'vue';
import { MemberRepository } from '@/repositories';
import { MyMemberShip, MemberShip, PaymentHistory } from '@/entities';
import { Loading,Allow,Disallow,RightArrow,Pagination } from '@/components';
import { ValidateHelper,Helper, MessageBox, StringHelper } from '@/utility';
import { useI18n } from 'vue-i18n';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import localizedFormat from 'dayjs/plugin/localizedFormat'; 
import { useRoute } from 'vue-router';
import router from '@/router';

dayjs.extend(utc);
dayjs.extend(localizedFormat);

const route = useRoute();
const { t, locale } = useI18n();
const memberRep = new MemberRepository();
const checker = new ValidateHelper();
const activeTab = ref(route.query.tab ?? 'current');

var pageinfo = ref({
  key : 0,
  totalcount:0,
  curpage:1,
  isLoading : true,
  isReady : false,
  isPaymentPopup : false,
  memberships:[] as MemberShip[],
  Free : new MemberShip(), 
  Premium : new MemberShip(), 
  Enterprice : new MemberShip(), 
  myMemberShip : new MyMemberShip(),
  myHistorian : [] as PaymentHistory[],
  couponCode : "OP24-08AG-D22P-0511"
});

onMounted(async () => {
  try {
    await GetMemberShips();
    await GetMyMemberShip();
    await fnPaging(1);
  } catch (e:any) {
    console.log(e.message);
  } finally {
    pageinfo.value.isLoading = false;
    pageinfo.value.key += 1;
  }
});

const GetMemberShips = async () => {
  let rst = await memberRep.GetMemberShips({ curPage : 1, pageSize : 10 });
  if (rst.check && rst.data !== null) {
    pageinfo.value.memberships = rst.data;
    pageinfo.value.Free = rst.data.filter((x:any) => x.title === "Basic")[0];
    pageinfo.value.Premium = rst.data.filter((x:any) => x.title === "Premium")[0];
    pageinfo.value.Enterprice = rst.data.filter((x:any) => x.title === "Professional")[0];
    pageinfo.value.isReady = true;
  }
}

const GetMyMemberShip = async () => {
  let rst = await memberRep.GetMyMemberShip();
  //console.log('GetMyMemberShip : ', rst);
  if (rst.check && rst.data !== null) {
    pageinfo.value.myMemberShip = rst.data;
    
  }
}

const fnPaging = async (curpage:number) => {
  let rst = await memberRep.GetHistory({ curPage : curpage, pageSize : 10 });
  //console.log('GetMyMemberShips : ', rst);
  if (rst.check && rst.data !== null) {
    pageinfo.value.curpage = curpage;
    pageinfo.value.myHistorian = rst.data;
  }
};

const fnCancel = async (log:PaymentHistory) => {
  MessageBox.Confirm(t('site.cancelPaymentMent'), async () => {
    let rst = await memberRep.PutNicePayCancel(log.id);
    if (rst.check) {
      MessageBox.Success(t('common.paymentCancelled'), () => {
        log.status = "cancel";
      });
    }
  });
};

const fnPaymentNice = (productCode:string) => {
  MessageBox.Confirm(t('common.Areyoupay'), () => {
    router.push(`/account/payment?productCode=${productCode}`);
  });
};

const fnCouponCancel = () => {
  pageinfo.value.isPaymentPopup = false;
  pageinfo.value.key += 1;
};

const fnCouponRegist = () => {
  MessageBox.Confirm(t('common.confirmregistcoupon'), async () => {
    let rst = await memberRep.PostCouponRegist(pageinfo.value.couponCode);
    if (rst.check) {
      MessageBox.Success(t('common.Registered'), () => {
        document.location.href = "/account/membership";
      });
    } else {
      MessageBox.Alert(rst.message);
    }
  });
};
</script>
