<template>
  <div class="w-full flex justify-between mb-1">
    <div class="ml-2">
      <h3 class="text-2xl subcolor uppercase"><b>{{ t('site.aiuseguide') }}</b></h3>
    </div>
    <div class="text-sm mr-2">
      <router-link to="/main" class="text-gray-400 uppercase text-sm"><small>HOME</small></router-link>
      <span class="px-2 text-gray-300">&gt;</span>
      <a href="#" class="text-gray-500 uppercase text-md"><b>{{ t('site.guide') }}</b></a>
    </div>
  </div>

  <!-- Tabs -->
  <div class="flex justify-center mb-6">
    <button class="text-gray-600 px-4 py-2 mx-2 focus:outline-none border-b-2 border-transparent hover:border-blue-500" @click="onTagb(1)" :class="pageinfo.tab === 1 ? `maincolor` : ''">
      AI등록하기
    </button>
    <button class="text-gray-600 px-4 py-2 mx-2 focus:outline-none border-b-2 border-transparent hover:border-blue-500" @click="onTagb(2)" :class="pageinfo.tab === 2 ? `maincolor` : ''">
      AI초안작성
    </button>
    <button class="text-gray-600 px-4 py-2 mx-2 focus:outline-none border-b-2 border-transparent hover:border-blue-500" @click="onTagb(3)" :class="pageinfo.tab === 3 ? `maincolor` : ''">
      AI평가듣기
    </button>
    <button class="text-gray-600 px-4 py-2 mx-2 focus:outline-none border-b-2 border-transparent hover:border-blue-500" @click="onTagb(4)" :class="pageinfo.tab === 4 ? `maincolor` : ''">
      AI토론하기
    </button>
    <button class="text-gray-600 px-4 py-2 mx-2 focus:outline-none border-b-2 border-transparent hover:border-blue-500" @click="onTagb(5)" :class="pageinfo.tab === 5 ? `maincolor` : ''">
      AI리뷰요청
    </button>
  </div>

  <div :key="pageinfo.key"
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white">
    <div class="block w-full overflow-x-auto p-5" v-if="pageinfo.tab === 1">
      <h1 class="mt-4 mb-4 p-2 text-2xl">AI등록하기</h1>
      <hr class="mt-4 mb-4"/>
      <!-- Guide Item 1 -->
      <div class="mb-8">
        <h4 class="text-lg font-bold text-gray-700 mb-4">1. AI 사용전 점검하기</h4>
        <div class="flex">
          <div class="w-1/3">
            <img src="/images/guide/aiuse/aiuse_01.png" alt="" class="w-full h-auto rounded-md shadow-md">
          </div>
          <div class="w-2/3 pl-6">
            <div class="text-gray-600 leading-relaxed">
              먼저 자신에게 적합한 멤버십이 있는지 확인하세요.<br/>
              멤버십별로 사용할 수 있는 기능에 차이가 있습니다.<br/>
              <p>&nbsp;</p>
              <router-link to="/account/membership" class="text-blue-400">보유멤버십 확인하러 가기</router-link><br/>
              <router-link to="/account/membership?tab=membership" class="text-blue-400">멤버십별 기능 확인하러 가기</router-link><br/>
            </div>
          </div>
        </div>
      </div>
      
      <!-- Guide Item 2 -->
      <div class="mb-8">
        <h4 class="text-lg font-bold text-gray-700 mb-4">2. AI API 키 발급받기</h4>
        <div class="flex">
          <div class="w-1/3">
            <img src="/images/guide/aiuse/aiuse_02.png" alt="" class="w-full h-auto rounded-md shadow-md">
          </div>
          <div class="w-2/3 pl-6">
            <div class="text-gray-600 leading-relaxed">
              프리미엄 이용 고객은 AI API키를 직접 등록하셔야 합니다.<br/>
              구글 AI 스튜디오에 방문하여 키 발급을 진행합니다.<br/>
              <span class="text-orange-500 font-bold">Professional 이용 고객은 별도의 키 등록 절차가 필요없으므로, 이 단계를 생략합니다.</span><br/>
              <p>&nbsp;</p>
              <a href="https://aistudio.google.com" target="_blank" class="text-blue-400">구글 AI 스튜디오 방문</a><br/>
            </div>
          </div>
        </div>
      </div>

      <!-- Guide Item 3 -->
      <div class="mb-8">
        <div class="flex">
          <div class="w-1/3">
            <img src="/images/guide/aiuse/aiuse_03.png" alt="" class="w-full h-auto rounded-md shadow-md">
          </div>
          <div class="w-2/3 pl-6">
            <div class="text-gray-600 leading-relaxed">
              왼쪽 상단에 "Get API Key" 버튼을 눌러 API 키 발급을 진행합니다.<br/>
              <span class="text-orange-500">현재 구글에서 제공하는 Gemini 1.5 Flash는 <b>무료</b>입니다.</span><br/>
            </div>
          </div>
        </div>
      </div>

      <div class="mb-8">
        <div class="flex">
          <div class="w-1/3">
            <img src="/images/guide/aiuse/aiuse_04.png" alt="" class="w-full h-auto rounded-md shadow-md">
          </div>
          <div class="w-2/3 pl-6">
            <div class="text-gray-600 leading-relaxed">
              키 API 키만들기 버튼을 누르면 팝업창이 열립니다.<br/>
            </div>
          </div>
        </div>
      </div>

      <div class="mb-8">
        <div class="flex">
          <div class="w-1/3">
            <img src="/images/guide/aiuse/aiuse_05.png" alt="" class="w-full h-auto rounded-md shadow-md">
          </div>
          <div class="w-2/3 pl-6">
            <div class="text-gray-600 leading-relaxed">
              키 새 프로젝트에서 API 키 만들기를 클릭합니다.<br/>
            </div>
          </div>
        </div>
      </div>

      <div class="mb-8">
        <div class="flex">
          <div class="w-1/3">
            <img src="/images/guide/aiuse/aiuse_06.png" alt="" class="w-full h-auto rounded-md shadow-md">
          </div>
          <div class="w-2/3 pl-6">
            <div class="text-gray-600 leading-relaxed">
              키가 만들어지면 복사 버튼을 눌러 복사합니다.<br/>
            </div>
          </div>
        </div>
      </div>

      <div class="mb-8">
        <h4 class="text-lg font-bold text-gray-700 mb-4">2. AI API 키 등록</h4>
        <div class="flex">
          <div class="w-1/3">
            <img src="/images/guide/aiuse/aiuse_07.png" alt="" class="w-full h-auto rounded-md shadow-md">
          </div>
          <div class="w-2/3 pl-6">
            <div class="text-gray-600 leading-relaxed">
              구글에서 발급받은 API키를 GN2Studio에 등록해 줘야 합니다.<br/>
              왼쪽 메뉴에서 설정 버튼을 눌러 이동합니다.<br/>
              설정 화면에 보이는 New AI API Key를 클릭합니다.<br/>
              <span class="text-orange-500 font-bold">Professional 이용 고객은 별도의 키 등록 절차가 필요없으므로, 이 단계를 생략합니다.</span><br/>
              <p>&nbsp;</p>
              <router-link to="/account/settings" class="text-blue-400">설정화면으로 이동하기</router-link><br/>
            </div>
          </div>
        </div>
      </div>

      <div class="mb-8">
        <div class="flex">
          <div class="w-1/3">
            <img src="/images/guide/aiuse/aiuse_08.png" alt="" class="w-full h-auto rounded-md shadow-md">
          </div>
          <div class="w-2/3 pl-6">
            <div class="text-gray-600 leading-relaxed">
              팝업창이 열리면 Type에서 Gemini를 선택하고, 아래 API Key 란에 방금 복사한 구글 AI API키를 복사해 넣습니다.<br/>
              Save 버튼을 눌러 저장합니다.<br/>
              <p>&nbsp;</p>
              이제 AI 사용을 위한 기본적인 준비를 마쳤습니다.<br/>
            </div>
          </div>
        </div>
      </div>

      <div class="mb-8">
        <h4 class="text-lg font-bold text-gray-700 mb-4">3. 새 작품 등록</h4>
        <div class="flex">
          <div class="w-1/3">
            <img src="/images/guide/aiuse/aiuse_09.png" alt="" class="w-full h-auto rounded-md shadow-md">
          </div>
          <div class="w-2/3 pl-6">
            <div class="text-gray-600 leading-relaxed">
              이제 작품목록으로 이동하여 새작품을 등록해 주세요.<br/>
              새작품 버튼을 클릭합니다.<br/>
              <p>&nbsp;</p>
              <router-link to="/account/settings" class="text-blue-400">작품목록으로 이동하기</router-link><br/>
            </div>
          </div>
        </div>
      </div>

      <div class="mb-8">
        <div class="flex">
          <div class="w-1/3">
            <img src="/images/guide/aiuse/aiuse_10.png" alt="" class="w-full h-auto rounded-md shadow-md">
          </div>
          <div class="w-2/3 pl-6">
            <div class="text-gray-600 leading-relaxed">
              프리미엄 이상 고객은 AI 어시스던트 사용 버튼이 활성화 되어 있습니다.<br/>
              해당 버튼을 눌러 ON 상태로 만들어 줍니다.<br/>
              <span class="text-orange-500 font-bold">AI에게 초안작성을 요청하려면 작품에 대한 설명을 상세히 기술해 주세요.</span><br/>
            </div>
          </div>
        </div>
      </div>
      
      <div class="mb-8">
        <h4 class="text-lg font-bold text-gray-700 mb-4">4. 글쓰기 시작</h4>
        <div class="flex">
          <div class="w-1/3">
            <img src="/images/guide/aiuse/aiuse_11.png" alt="" class="w-full h-auto rounded-md shadow-md">
          </div>
          <div class="w-2/3 pl-6">
            <div class="text-gray-600 leading-relaxed">
              작품정보를 빠짐없이 기입하고 저장을 하면 하단에 버튼 목록이 나열됩니다.<br/>
              글쓰기 버튼을 눌러서 글 작성을 시작해 주세요.<br/>
            </div>
          </div>
        </div>
      </div>

      <div class="mb-8">
        <div class="flex">
          <div class="w-1/3">
            <img src="/images/guide/aiuse/aiuse_12.png" alt="" class="w-full h-auto rounded-md shadow-md">
          </div>
          <div class="w-2/3 pl-6">
            <div class="text-gray-600 leading-relaxed">
              우측 작성보조탭에 AI 관련 기능이 활성화 된 것을 볼 수 있습니다.<br/>
              이제 AI 기능을 활용해 보세요.<br/>
            </div>
          </div>
        </div>
      </div>


      <p class="text-red-500 text-bold text-xl">※ AI 기능은 사용되는 모델에 따라 큰 차이가 발생합니다.  프리미엄에서 제공되는 기능은 AI 기능을 맛보기 하는 수준으로 이해해 주세요.</p>
    </div>

    <div class="block w-full overflow-x-auto p-5" v-if="pageinfo.tab === 2">
      <h1 class="mt-4 mb-4 p-2 text-2xl">AI초안작성</h1>
      <p>준비중입니다.</p>
    </div>

    <div class="block w-full overflow-x-auto p-5" v-if="pageinfo.tab === 3">
      <h1 class="mt-4 mb-4 p-2 text-2xl">AI평가듣기</h1>
      <p>준비중입니다.</p>
    </div>

    <div class="block w-full overflow-x-auto p-5" v-if="pageinfo.tab === 4">
      <h1 class="mt-4 mb-4 p-2 text-2xl">AI토론하기</h1>
      <p>준비중입니다.</p>
    </div>

    <div class="block w-full overflow-x-auto p-5" v-if="pageinfo.tab === 5">
      <h1 class="mt-4 mb-4 p-2 text-2xl">AI리뷰요청</h1>
      <p>준비중입니다.</p>
    </div>
  </div>
</template>


<script setup lang="ts">
  import { onMounted, ref } from 'vue';
  import { MessageBox } from '@/utility';
  import { useRouter, useRoute } from 'vue-router';
  import { useI18n } from 'vue-i18n';
  import dayjs from 'dayjs';
  import utc from 'dayjs/plugin/utc';
  import localizedFormat from 'dayjs/plugin/localizedFormat'; 

  dayjs.extend(utc);
  dayjs.extend(localizedFormat);
  const router = useRouter();
  const { t, locale } = useI18n();

  var pageinfo = ref({
    key : 0,
    tab : 1
  });

  onMounted(async() => {
    pageinfo.value.key += 1;
  });

  const onTagb = (num:number) => {
    pageinfo.value.tab = num;
    pageinfo.value.key += 1;
  }
</script>