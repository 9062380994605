import { IPageBase } from "@/abstracts";
import config from "@/config";
import { Article, Book, Chapter, FormSetup, Memo, Review, Site, SitePage, UsePlatform } from "@/entities";
import { PagingParameter, ReturnValues } from "@/models";
import { ApiHelper } from "@/utility"

export class ArticleRepository {
    async GetCategories():Promise<ReturnValues> {
        let url = `${config.apis.article}/api/Site/Categories`;
        //console.log('ArticleRepository GetCategories : ', url);
        return await ApiHelper.GetAsync(url);
    }    

    async GetWriters():Promise<ReturnValues> {
        let url = `${config.apis.article}/api/Writer/List?CurPage=1&PageSize=100`;
        //console.log('ArticleRepository GetWriters : ', url);
        return await ApiHelper.GetAsync(url);
    }   
    
    async GetWriter(writerId:string):Promise<ReturnValues> {
        let url = `${config.apis.article}/api/PublishBook/Writer/View/${writerId}`;
        //console.log('ArticleRepository GetWriters : ', url);
        return await ApiHelper.GetAsync(url);
    }   

    async GetBooks(paging:PagingParameter):Promise<ReturnValues> {
        let url = `${config.apis.article}/api/Book/List?CurPage=${paging.curPage}&PageSize=${paging.pageSize}`;
        //console.log('ArticleRepository GetBooks : ', url);
        return await ApiHelper.GetAsync(url);
    }    
    
    async GetBook(bookId:string):Promise<ReturnValues> {
        let url = `${config.apis.article}/api/Book/View/${bookId}`;
        //console.log('ArticleRepository GetBook : ', url);
        return await ApiHelper.GetAsync(url);
    }    

    async GetOpenBooks(paging:PagingParameter):Promise<ReturnValues> {
        let url = `${config.apis.article}/api/PublishBook/List?CurPage=${paging.curPage}&PageSize=${paging.pageSize}`;
        //console.log('ArticleRepository GetOpenBooks : ', url);
        return await ApiHelper.GetAsync(url);
    }    

    async GetBookswithReview(paging:PagingParameter):Promise<ReturnValues> {
        let url = `${config.apis.article}/api/PublishBook/List/Review?CurPage=${paging.curPage}&PageSize=${paging.pageSize}`;
        //console.log('ArticleRepository GetBookswithReview : ', url);
        return await ApiHelper.GetAsync(url);
    }    
    
    async GetOpenBook(bookId:string):Promise<ReturnValues> {
        let url = `${config.apis.article}/api/PublishBook/View/${bookId}`;
        //console.log('ArticleRepository GetOpenBook : ', url);
        return await ApiHelper.GetAsync(url);
    } 
    
    async GetMyReviews(paging:PagingParameter):Promise<ReturnValues> {
        let url = `${config.apis.article}/api/Review/MyReviews?CurPage=${paging.curPage}&PageSize=${paging.pageSize}`;
        //console.log('ArticleRepository GetMyReviews : ', url);
        return await ApiHelper.GetAsync(url);
    }    

    async GetBookReviews(bookId:string, paging:PagingParameter):Promise<ReturnValues> {
        let url = `${config.apis.article}/api/Review/List/${bookId}/?CurPage=${paging.curPage}&PageSize=${paging.pageSize}`;
        //console.log('ArticleRepository GetBookReviews : ', url);
        return await ApiHelper.GetAsync(url);
    }    


    async GetReview(reviewId:string):Promise<ReturnValues> {
        let url = `${config.apis.article}/api/Review/View/${reviewId}`;
        //console.log('ArticleRepository GetMyReviews : ', url);
        return await ApiHelper.GetAsync(url);
    }   

    async GetReviewBooks(paging:PagingParameter):Promise<ReturnValues> {
        let url = `${config.apis.article}/api/Review/Books?CurPage=${paging.curPage}&PageSize=${paging.pageSize}`;
        //console.log('ArticleRepository GetOpenBooks : ', url);
        return await ApiHelper.GetAsync(url);
    }    
    
    async GetReviewBook(bookId:string):Promise<ReturnValues> {
        let url = `${config.apis.article}/api/Review/Book/${bookId}`;
        //console.log('ArticleRepository GetOpenBook : ', url);
        return await ApiHelper.GetAsync(url);
    } 

    async GetChapters(bookId:string):Promise<ReturnValues> {
        let url = `${config.apis.article}/api/Chapter/List/${bookId}`;
        //console.log('ArticleRepository GetChapters : ', url);
        return await ApiHelper.GetAsync(url);
    }   

    async GetArticles(bookId:string):Promise<ReturnValues> {
        let url = `${config.apis.article}/api/Article/List/${bookId}`;
        //console.log('ArticleRepository GetArticles : ', url);
        return await ApiHelper.GetAsync(url);
    }   

    async GetArticle(articleId:string):Promise<ReturnValues> {
        let url = `${config.apis.article}/api/Article/View/${articleId}`;
        //console.log('ArticleRepository GetArticle : ', url);
        return await ApiHelper.GetAsync(url);
    }   

    
    async GetBeforeArticle(articleId:string):Promise<ReturnValues> {
        let url = `${config.apis.article}/api/Article/View/${articleId}/before`;
        //console.log('ArticleRepository GetArticle : ', url);
        return await ApiHelper.GetAsync(url);
    }   

    
    async GetAfterArticle(articleId:string):Promise<ReturnValues> {
        let url = `${config.apis.article}/api/Article/View/${articleId}/after`;
        //console.log('ArticleRepository GetArticle : ', url);
        return await ApiHelper.GetAsync(url);
    }   

    async GetPlatforms():Promise<ReturnValues> {
        let url = `${config.apis.article}/api/PublishBook/Platform/List`;
        //console.log('ArticleRepository GetArticle : ', url);
        return await ApiHelper.GetAsync(url);
    }   

    async GetUserFormSetup():Promise<ReturnValues> {
        let url = `${config.apis.article}/api/User/Setup`;
        //console.log('ArticleRepository GetUserFormSetup : ', url);
        return await ApiHelper.GetAsync(url);
    }    

    async GetMemos(bookid:string|null|undefined, paging:PagingParameter):Promise<ReturnValues> {
        let url = `${config.apis.article}/api/Memo/List?CurPage=${paging.curPage}&PageSize=${paging.pageSize}`;
        if (bookid !== null && bookid !== undefined) {
            url = `${config.apis.article}/api/Memo/List/${bookid}/?CurPage=${paging.curPage}&PageSize=${paging.pageSize}`;
        }
        //console.log('ArticleRepository GetMemos : ', url);
        return await ApiHelper.GetAsync(url);
    }   
    
    async GetMemo(memoid:string):Promise<ReturnValues> {
        let url = `${config.apis.article}/api/Memo/View/${memoid}`;
        //console.log('ArticleRepository GetMemo : ', url);
        return await ApiHelper.GetAsync(url);
    }  
    
    async DeleteMemo(memoid:string):Promise<ReturnValues> {
        let url = `${config.apis.article}/api/Memo/Remove/${memoid}`;
        //console.log('ArticleRepository DeleteMemo : ', url);
        return await ApiHelper.DeleteAsync(url);
    }  

    async PostMemoRegist(memo:Memo):Promise<ReturnValues> {
        let url = `${config.apis.article}/api/Memo/Regist`;
        if (memo.bookId !== null && memo.bookId !== undefined && String(memo.bookId) !== '') {
            url = `${config.apis.article}/api/Memo/Book/${memo.bookId}/Regist`;
        }
        //console.log('ArticleRepository PostMemoRegist : ', url);
        let jsonData = {
            title: memo.title,
            content: encodeURIComponent(memo.content)
          };
        return await ApiHelper.PostAsync(url, jsonData);
    }

    async PutMemoRegist(memo:Memo):Promise<ReturnValues> {
        let url = `${config.apis.article}/api/Memo/Modify/${memo.id}`;
        if (memo.bookId !== null && memo.bookId !== undefined && String(memo.bookId) !== '') {
            url = `${config.apis.article}/api/Memo/Book/${memo.bookId}/Modify/${memo.id}`;
        }
        //console.log('ArticleRepository PutMemoRegist : ', url);
        let jsonData = {
            title: memo.title,
            content: encodeURIComponent(memo.content)
          };
        return await ApiHelper.PutAsync(url, jsonData);
    }

    async PostPlatformRegist(platform:UsePlatform):Promise<ReturnValues> {
        let url = `${config.apis.article}/api/Book/Platform/Regist`;
        //console.log('ArticleRepository PostPlatformRegist : ', url);

        let jsonData = {
            bookId: platform.bookId,
            name: platform.name,
            url: platform.url
          };
        return await ApiHelper.PostAsync(url, jsonData);
    }
    
    async PutPlatformUpdate(platform:UsePlatform):Promise<ReturnValues> {
        let url = `${config.apis.article}/api/Book/Platform/Modify/${platform.id}`;
        console.log('ArticleRepository PutPlatformRegist : ', url);

        let jsonData = {
            bookId: platform.bookId,
            name: platform.name,
            url: platform.url,
            seq : platform.seq
          };

        console.log('ArticleRepository PutPlatformRegist : ', jsonData);

        return await ApiHelper.PutAsync(url, jsonData);
    }

    async DeletePlatform(targetId:string):Promise<ReturnValues> {
        let url = `${config.apis.article}/api/Book/Platform/Remove/${targetId}`;
        //console.log('ArticleRepository DeletePlatformRegist : ', url);

        return await ApiHelper.DeleteAsync(url);
    }

    async PostFormSetup(form:FormSetup):Promise<ReturnValues> {
        let url = `${config.apis.article}/api/User/Setup/Save`;
        //console.log('ArticleRepository PostFormSetup : ', url);

        let jsonData = {
            font: form.font,
            size: form.size,
            lineHeight: form.lineHeight,
            fontColor: form.fontColor,
            bgColor: form.bgColor
          };
        return await ApiHelper.PostAsync(url, jsonData);
    }

    async PostWriter(nickname:string):Promise<ReturnValues> {
        let url = `${config.apis.article}/api/Writer/Regist`;
        //console.log('ArticleRepository PostWriter : ', url);
        let jsonData = {
            photo: "",
            nickname: nickname,
            bio: ""
          };
        return await ApiHelper.PostAsync(url, jsonData);
    }

    async PostBook(book:Book):Promise<ReturnValues> {
        let url = `${config.apis.article}/api/Book/Regist`;
        //console.log('ArticleRepository PostBook : ', url);

        let jsonData = {
            title: book.title,
            description: book.description,
            keywords: book.keywords,
            cover: book.cover,
            thumbnail: book.thumbnail,
            minorCategoryId: book.minorCategoryId,
            majorCategoryId: book.majorCategoryId,
            writerId: book.writerId,
            tags: book.tags,
            plannedCount: book.plannedCount,
            isReviewOpen: book.isReviewOpen,
            isPublish: book.isPublish,
            isUseAssistant: book.isUseAssistant
          };
        return await ApiHelper.PostAsync(url, jsonData);
    }

    async PutBook(book:Book):Promise<ReturnValues> {
        let url = `${config.apis.article}/api/Book/Modify/${book.id}`;
        //console.log('ArticleRepository PutBook : ', url);
        return await ApiHelper.PutAsync(url, book);
    }

    async DeleteBook(bookid:string):Promise<ReturnValues> {
        let url = `${config.apis.article}/api/Book/Remove/${bookid}`;
        //console.log('ArticleRepository DeleteBook : ', url);
        return await ApiHelper.DeleteAsync(url);
    }

    async PostArticleRegist(article:Article):Promise<ReturnValues> {
        let url = `${config.apis.article}/api/Article/Regist`;
        //console.log('ArticleRepository PostArticleRegist : ', url);

        let jsonData = {
            title: article.title,
            content: article.content,
            thumbnail: article.thumbnail,
            originURL: article.originURL,
            writer: article.writer,
            publishedAt: article.publishedAt,
            originKey: article.originKey,
            ownerId:article.ownerId,
            siteId: config.site.key,
            chapterId: article.chapterId,
            writerId: article.writerId,
            tags: article.tags
        };

        //console.log('ArticleRepository PostArticleRegist : ', jsonData);

        return await ApiHelper.PostAsync(url, jsonData);
    }

    async PutArticleModify(article:Article):Promise<ReturnValues> {
        let url = `${config.apis.article}/api/Article/Modify/${article.id}`;
        //console.log('ArticleRepository PostBook : ', url);

        let jsonData = {
            title: article.title,
            content: article.content,
            thumbnail: article.thumbnail,
            originURL: article.originURL,
            writer: article.writer,
            publishedAt: article.publishedAt,
            ownerId:article.ownerId,
            originKey: article.originKey,
            siteId: config.site.key,
            chapterId: article.chapterId,
            writerId: article.writerId,
            tags: article.tags
        };
        return await ApiHelper.PutAsync(url, jsonData);
    }

    async DeleteArticle(articleId:string):Promise<ReturnValues> {
        let url = `${config.apis.article}/api/Article/Remove/${articleId}`;
        //console.log('ArticleRepository DeleteArticle : ', url);
        return await ApiHelper.DeleteAsync(url);
    }

    async PostChapterRegist(ownerId:string, chapter:Chapter):Promise<ReturnValues> {
        let url = `${config.apis.article}/api/Chapter/Regist`;
        //console.log('ArticleRepository PostChapterRegist : ', url);

        let jsonData = {
            title: chapter.title,
            description: chapter.description,
            seq: chapter.seq,
            bookId: chapter.bookId,
            ownerId: ownerId
          };
        return await ApiHelper.PostAsync(url, jsonData);
    }
    
    async PutChapterRegist(ownerId:string, chapter:Chapter):Promise<ReturnValues> {
        let url = `${config.apis.article}/api/Chapter/Modify/${chapter.id}`;
        //console.log('ArticleRepository PutChapterRegist : ', url);

        let jsonData = {
            title: chapter.title,
            description: chapter.description,
            seq: chapter.seq,
            bookId: chapter.bookId,
            ownerId: ownerId
          };

          //console.log('ArticleRepository PutChapterRegist : ', jsonData);
        return await ApiHelper.PutAsync(url, jsonData);
    }

    async DeleteChapter(chapterId:string):Promise<ReturnValues> {
        let url = `${config.apis.article}/api/Chapter/Remove/${chapterId}`;
        //console.log('ArticleRepository DeleteChapter : ', url);
        return await ApiHelper.DeleteAsync(url);
    }

    async PostReviewRegist(review:Review):Promise<ReturnValues> {
        let url = `${config.apis.article}/api/Review/Regist`;
        //console.log('ArticleRepository PostReviewRegist : ', url);

        let jsonData = {
            targetId: review.targetId,
            targetType: review.targetType,
            title: review.title,
            content: encodeURIComponent(review.content)
          };
        return await ApiHelper.PostAsync(url, jsonData);
    }

    async PutReviewUpdate(review:Review):Promise<ReturnValues> {
        let url = `${config.apis.article}/api/Review/Modify/${review.id}`;
        //console.log('ArticleRepository PutReviewUpdate : ', url);

        let jsonData = {
            targetId: review.targetId,
            targetType: review.targetType,
            title: review.title,
            content: encodeURIComponent(review.content)
          };
        return await ApiHelper.PutAsync(url, jsonData);
    }

    async DeleteReview(reviewId:string):Promise<ReturnValues> {
        let url = `${config.apis.article}/api/Review/Remove/${reviewId}`;
        //console.log('ArticleRepository DeleteReview : ', url);
        return await ApiHelper.DeleteAsync(url);
    }

    async GetFavorites(paging:PagingParameter):Promise<ReturnValues> {
        let url = `${config.apis.article}/api/Favorite/List?CurPage=${paging.curPage}&PageSize=${paging.pageSize}`;
        //console.log('ArticleRepository GetFavorites : ', url);
        return await ApiHelper.GetAsync(url);
    }  

    async GetFavorite(bookId:string):Promise<ReturnValues> {
        let url = `${config.apis.article}/api/Favorite/Check/${bookId}`;
        //console.log('ArticleRepository GetFavorites : ', url);
        return await ApiHelper.GetAsync(url);
    }  

    async RegistFavorite(bookId:string):Promise<ReturnValues> {
        let url = `${config.apis.article}/api/Favorite/Regist/${bookId}`;
        //console.log('ArticleRepository PostFavoriteRegist : ', url);
        return await ApiHelper.PostAsync(url, {});
    }

    async DeleteFavorite(bookId:string):Promise<ReturnValues> {
        let url = `${config.apis.article}/api/Favorite/Remove/${bookId}`;
        //console.log('ArticleRepository PostFavoriteRegist : ', url);
        return await ApiHelper.DeleteAsync(url, {});
    }

    async GetMainStats():Promise<ReturnValues> {
        let url = `${config.apis.article}/api/Site/Stats/Main`;
        //console.log('ArticleRepository GetMainStats : ', url);
        return await ApiHelper.GetAsync(url);
    }  

    async GetBests(paging:PagingParameter):Promise<ReturnValues> {
        let url = `${config.apis.article}/api/PublishBook/List/Best?CurPage=${paging.curPage}&PageSize=${paging.pageSize}`;
        //console.log('ArticleRepository GetBests : ', url);
        return await ApiHelper.GetAsync(url);
    }  

    async GetSearch(keyword:string, paging:PagingParameter):Promise<ReturnValues> {
        let url = `${config.apis.article}/api/Site/Search?Keyword=${encodeURIComponent(keyword)}&CurPage=${paging.curPage}&PageSize=${paging.pageSize}`;
        //console.log('ArticleRepository GetBests : ', url);
        return await ApiHelper.GetAsync(url);
    }  

    async PutSeqUpdate(jsonData:any):Promise<ReturnValues> {
        let url = `${config.apis.article}/api/Book/Sequence`;
        //console.log('ArticleRepository PutSeqUpdate : ', url);
        return await ApiHelper.PutAsync(url, jsonData);
    }

    async GetAiStat():Promise<ReturnValues> {
        let url = `${config.apis.article}/api/Stat/Trend`;
        //console.log('ArticleRepository GetAiStat : ', url);
        return await ApiHelper.GetAsync(url);
    }  
}
