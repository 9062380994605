<template>
  <Loading :loading="pageinfo.isLoading" />
  <div class="w-full h-screen" :key="pageinfo.key" :style="`background-color:${pageinfo.form.bgColor};color:${pageinfo.form.fontColor};`">
    <div class="w-full flex justify-between form-body">
      <div class="p-4 w-full">
        <div class="w-full text-bold text-xl">
          {{ pageinfo.chapter?.title }}
        </div>        
        <hr class="mt-4 mb-4"/>
        <div class="w-full">
          <input type="text" v-model="pageinfo.article.title" class="form-text focus:ring-0" :class="pageinfo.form.font" :style="`font-size:${pageinfo.form.size}px;line-height:${pageinfo.form.lineHeight}%;`" :placeholder="t('common.requiredtitlement')" />
        </div>
        <div class="w-full mt-3 form-body border-t border-gray">
          <textarea ref="textarea" v-model="pageinfo.article.content" class="form-textarea focus:ring-0 h-full w-full mt-4" :class="pageinfo.form.font" :style="`font-size:${pageinfo.form.size}px;line-height:${pageinfo.form.lineHeight}%;`" :placeholder="t('common.requiredContent')"></textarea>
        </div>
      </div>
      <div class="w-1/3 md:block p-4 hidden" style="overflow-x:hidden;overflow-y:scroll;">
        <div class="flex flex-col w-full h-full bg-white p-4 rounded-lg shadow-md">
    <!-- 탭 헤더 -->
    <div class="flex space-x-4 border-b border-gray-300 dark:border-gray-700 mb-4">
      <button
        :class="{'text-blue-500 border-b-2 border-blue-500': activeTab === 'tool'}"
        @click="activeTab = 'tool'"
        class="p-2 text-sm font-medium focus:outline-none transition duration-200 text-gray-400"
      >
       {{t('site.assistance')}}
      </button>
      <button
        :class="{'text-blue-500 border-b-2 border-blue-500': activeTab === 'config'}"
        @click="activeTab = 'config'"
        class="p-2 text-sm font-medium focus:outline-none transition duration-200 text-gray-400"
      >
      {{ t('site.Memos') }}
      </button>
      <button
        :class="{'text-blue-500 border-b-2 border-blue-500': activeTab === 'memo'}"
        @click="activeTab = 'memo'"
        class="p-2 text-sm font-medium focus:outline-none transition duration-200 text-gray-400"
      >
      {{t('site.booksetup')}}
      </button>
      <button
        :class="{'text-blue-500 border-b-2 border-blue-500': activeTab === 'setup'}"
        @click="activeTab = 'setup'"
        class="p-2 text-sm font-medium focus:outline-none transition duration-200 text-gray-400"
      >
       {{t('common.displaysetup')}}
      </button>
    </div>

    <!-- 탭 콘텐츠 -->
    <div class="flex-1 w-full">
      <div v-if="activeTab === 'tool'" class="p-4">
        <div class="w-full mt-3" v-if="pageinfo.permission.Assistant">
          <div class="w-full">
            <button class="w-full py-2 px-4 border border-1 mainbg rounded-md text-white" @click="fnAssistant" >{{ t('site.AI_Draft') }}</button>
            <button v-show="false" class="w-full py-2 px-4 border border-1 mainbg rounded-md text-white mt-1" @click="fnAssistantEdit" >{{ t('site.AI_Refines') }}</button>
            <button class="w-full py-2 px-4 border border-1 mainbg rounded-md text-white mt-1" @click="fnAssistantCorrect" >{{ t('site.AI_Ask') }}</button>
          </div>
        </div>
        <div class="w-full mt-3">
          <div class="w-full">
            <button class="w-full py-2 px-4 border border-1 bg-green-500 rounded-md text-white mt-1" @click="addEmptyLines">{{ t('site.AddBlankLines') }}</button>
            <button class="w-full py-2 px-4 border border-1 bg-orange-500 rounded-md text-white mt-1" @click="removeEmptyLines">{{ t('site.RemoveBlankLines') }}</button>
          </div>
        </div>
<div class="w-full mt-3 mb-4" style="max-height: 450px; overflow-y: auto; overflow-x: hidden;">
  <div class="rounded-md text-gray-400 text-sm break-words mb-3 mt-3">
    {{ pageinfo.book.description }}
  </div>
</div>


      </div>
      <div v-if="activeTab === 'config'" class="p-4">
        <ul class="w-full">
          <li v-for="(memo, index) in pageinfo.globalMemos.data" :key="`global_memo_${index}`" class="w-full px-4 py-2 bg-gray-100 mb-1 border-1 border-t">
            <a href="#" :class="(pageinfo.globalMemos.target.id === memo.id) ? 'maincolor' : 'text-gray-600'" @click="fnGlobalMemoSelect(memo)">{{ memo.title }}</a>
            <div class="float-right">
            <TableDropdown :siteid="memo.id">
                <button
                  type="button"  @click="fnGlobalMemoEdit(memo.id, index)"
                  class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700 text-left"
                >
                  {{ t('system.ButtonModify') }}
                </button>
                <button
                  type="button" @click="fnGlobalMemoRemove(memo, index)"
                  class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700 text-left"
                >
                  {{ t('system.ButtonDelete') }}
                </button>
              </TableDropdown>
              </div>
          </li>
          <li class="flex justify-between w-full px-4 py-2 bg-gray-300 mb-1 border-1 border-t">
            <button class="text-blue-400" @click="fnMemoPreviousPage">◀ {{t('common.previous')}}</button>
            <button class="text-white px-4 py-1 bg-orange-400" @click="fnMemoAppend('')">{{ t('common.ButtonAdd') }}</button>
            <button class="text-blue-400" @click="fnMemoNextPage">{{ t('common.next') }} ▶</button>
          </li>
        </ul>
      </div>
      <div v-if="activeTab === 'memo'" class="p-4">
        <ul class="w-full">
          <li v-for="(memo, index) in pageinfo.memos.data" :key="`memo_${index}`" class="w-full px-4 py-2 bg-gray-100 mb-1 border-1 border-t">
            <a href="#" :class="(pageinfo.memos.target.id === memo.id) ? 'maincolor' : 'text-gray-600'" @click="fnMemoSelect(memo)">{{ memo.title }}</a>
            <div class="float-right">
            <TableDropdown :siteid="memo.id">
                <button
                  type="button"  @click="fnMemoEdit(memo.id, index)"
                  class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700 text-left"
                >
                  {{ t('system.ButtonModify') }}
                </button>
                <button
                  type="button" @click="fnMemoRemove(memo, index)"
                  class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700 text-left"
                >
                  {{ t('system.ButtonDelete') }}
                </button>
              </TableDropdown>
              </div>
          </li>
          <li class="flex justify-between w-full px-4 py-2 bg-gray-300 mb-1 border-1 border-t">
            <button class="text-blue-400" @click="fnGlobalPreviousPage">◀ {{t('common.previous')}}</button>
            <button class="text-white px-4 py-1 bg-orange-400" @click="fnMemoAppend(pageinfo.book.id)">{{ t('common.ButtonAdd') }}</button>
            <button class="text-blue-400" @click="fnGlobalNextPage">{{ t('common.next') }} ▶</button>
          </li>
        </ul>
      </div>
      <div v-if="activeTab === 'setup'" class="p-4">
        <select v-model="pageinfo.form.font" @change="applyStyle" class="w-full p-2 border border-gray-300 rounded mb-1 text-gray-500">
          <option v-for="font in fonts" :key="font" :value="font">{{ font }}</option>
        </select>
        <select v-model="pageinfo.form.size" @change="applyStyle" class="w-full p-2 border border-gray-300 rounded mb-1 text-gray-500">
          <option v-for="size in fontSizes" :key="size" :value="size">{{ size }}px</option>
        </select>
        <select v-model="pageinfo.form.lineHeight" @change="applyStyle" class="w-full p-2 border border-gray-300 rounded mb-1 text-gray-500">
          <option v-for="h in lineHeight" :key="h" :value="h">{{ h }}%</option>
        </select>

        <div class="border border-1 px-2 py-1 rounded-md mb-1">
            <label class="block text-sm font-medium text-gray-700 pb-4">Font Color</label>
            <input v-model="pageinfo.form.fontColor" type="color" /> <span class="text-sm text-gray-300"> {{ pageinfo.form.fontColor }}</span>
        </div>
        <div class="border border-1 px-2 py-1 rounded-md mb-1">
            <label class="block text-sm font-medium text-gray-700 pb-4">Background Color</label>
            <input v-model="pageinfo.form.bgColor" type="color" /> <span class="text-sm text-gray-300"> {{ pageinfo.form.bgColor }}</span>
        </div>
        <button class="w-full py-2 px-4 border border-1 bg-green-100 rounded-md text-gray-500" @click="fnSaveSetup">Setup Save</button>
      </div>
    </div>
  </div>
      </div>
    </div>
    <div class="form-bottom p-4 border-t border-gray">
      <div class="w-full flex justify-between">
        <div><button class="rounded-lg text-lg shadow-lg uppercase px-4 py-2 text-gray-500 bg-green-100" @click="fnSave">Save</button></div>
        <div>{{ pageinfo.article.content.length }}</div>
        <div>
          <button class="rounded-lg text-lg shadow-lg uppercase px-4 py-2 text-gray-400 bg-gray-300 mr-2" @click="fnBeforeArticle()" >{{ t('common.previous') }}</button>
          <button class="rounded-lg text-lg shadow-lg uppercase px-4 py-2 text-gray-400 bg-gray-300 mr-2" @click="fnAfterArticle()" >{{ t('common.next') }}</button>
          <button class="rounded-lg text-lg shadow-lg uppercase px-4 py-2 text-gray-400 bg-gray-300" @click="goList">Back</button>
        </div>
      </div>
    </div>
    <div class="absolute inset-0 flex items-center justify-center w-full h-screen" style="top:0;left:0;max-height: 600px;overflow-x:hidden;overflow-y:auto;" v-show="pageinfo.chatwin">
      <div class="relative w-full">
        <div class="bg-white text-white p-4 rounded shadow-lg" style="width:60%;margin:0 auto;">
          <p class="text-blue-500 text-lg">{{ t('site.AI_suggests') }}</p>
          <hr />
          <div class="w-full text-gray-500 p-4" v-html="HtmlHelper.write(pageinfo.chatText)">
          </div>
          <hr />
          <div class="flex justify-between mt-3">
            <div class="text-align-left" v-if="pageinfo.isEdit">
              <button class="py-2 px-4 border border-1 mainbg rounded-md text-white mr-2" @click="fnSubmitOk(1)" >{{ t('site.ReplaceContent') }}</button>
              <button class="py-2 px-4 border border-1 mainbg rounded-md text-white" @click="fnSubmitOk(2)" >{{ t('site.AddContent') }}</button>
            </div>
            <div class="text-align-right">
              <button class="py-2 px-4 border border-1 bg-red-500 rounded-md text-white" @click="fnSubmitOk(4)" >{{ t('common.close') }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="absolute inset-0 flex items-center justify-center w-full h-screen" style="top:0;left:0;max-height: 600px;overflow-x:hidden;overflow-y:auto;" v-show="pageinfo.memos.isShow">
      <div class="relative w-full">
        <div class="bg-white text-white p-4 rounded shadow-lg" style="width:60%;margin:0 auto;">
          <p class="text-blue-500 text-lg">{{ pageinfo.memos.target.title }}</p>
          <hr />
          <div class="w-full text-gray-500 p-4" v-html="pageinfo.memos.target.content">
          </div>
          <hr />
          <div class="flex justify-between mt-3">
            <div class="text-align-right">
              <button class="py-2 px-4 border border-1 bg-red-500 rounded-md text-white" @click="pageinfo.memos.isShow = false" >{{ t('common.close') }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="absolute inset-0 flex items-center justify-center w-full h-screen" style="top:0;left:0;max-height: 600px;overflow-x:hidden;overflow-y:auto;" v-show="pageinfo.globalMemos.isShow">
      <div class="relative w-full">
        <div class="bg-white text-white p-4 rounded shadow-lg" style="width:60%;margin:0 auto;">
          <p class="text-blue-500 text-lg">{{ pageinfo.globalMemos.target.title }}</p>
          <hr />
          <div class="w-full text-gray-500 p-4" v-html="pageinfo.globalMemos.target.content">
          </div>
          <hr />
          <div class="flex justify-between mt-3">
            <div class="text-align-right">
              <button class="py-2 px-4 border border-1 bg-red-500 rounded-md text-white" @click="pageinfo.globalMemos.isShow = false" >{{ t('common.close') }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="absolute inset-0 flex items-center justify-center w-full h-screen" style="top:0px;left:0;" v-show="pageinfo.memoform.isShow">
      <div class="relative w-full">
        <div class="bg-white text-white p-4 rounded shadow-lg" style="width:60%;margin:0 auto;">
          <input type="text" v-model="pageinfo.memoform.title" maxlength="100" class="w-full border-1 border-gray-200 text-gray-500" :placeholder="t('common.requiredtitlement')" />
          <div class="w-full mt-1">
            <!--<WebEditor :content="pageinfo.memoform.memo" :multiupload="false" :key="`memo_${pageinfo.key}`" :callback="handleUpdate" />-->
            <Editor
            apiKey="grw0w13jlmd6svqpvrpe4uh2ly8q34a2m8sqsylmny6bhiws"
            :key="`memo_${pageinfo.key}`"
            :init="{
                height: 500,
                menubar: false,
                plugins: ['advlist','autolink','lists','link','image','wordcount','media','code'],
                toolbar:
                'undo redo | formatselect | bold italic backcolor | bullist numlist outdent indent | removeformat | image | media | code',
                images_upload_handler: uploadHandler,
                dropzone: {
                uploadMultiple: false,
                maxFiles: 10,
                acceptedFiles: 'image/*',
                success: handleDropzoneSuccess
                },
                relative_urls: false,
                document_base_url: config.apis.resource
            }"
            v-model="pageinfo.memoform.memo"
            ></Editor>
          </div>
          <div class="flex justify-center mt-3">
            <button class="mainbg px-4 py-1 text-white rounded-md mr-2" @click="fnMemoSave">{{ t('common.Save') }}</button>
            <button class="bg-orange-400 text-white px-4 py-1 rounded-md" @click="fnMemoCancel">{{ t('common.Cancel') }}</button>
          </div>
        </div>
      </div>
    </div>
</div>
</template>

<script setup lang="ts">
  import { Article,Book,Chapter,FormSetup, Member, Memo, MyMemberShip, Permission } from '@/entities';
  import { Loading,TableDropdown } from '@/components';
  import Editor from '@tinymce/tinymce-vue'
  import { ref,defineProps,onMounted,nextTick,getCurrentInstance,watch } from 'vue';
  import { ArticleRepository,MemberRepository,AiRepository,NotifyRepository,CopilotRepository } from '@/repositories';
  import { MessageBox,HtmlHelper, ApiHelper } from '@/utility';
  import { useRouter } from 'vue-router';
  import { useI18n } from 'vue-i18n';
  import { ValidateHelper } from '@/utility';
  import { ReturnValues } from '@/models';
  import config from '@/config';
  
  var selectedLanguage = ref(localStorage.getItem('selectedLanguage') || 'ko');
  const { proxy } = getCurrentInstance()!;
  const articleRep = new ArticleRepository();
  const memberRep = new MemberRepository();
  const nofifyRep = new NotifyRepository();
  const copilotRep = new CopilotRepository();
  const aiRep = new AiRepository();
  const router = useRouter();
  const { t,locale } = useI18n();
  const activeTab = ref('tool');
  const fonts = ['noto-sans-kr', 'noto-serif-kr', 'spoqa-han-sans', 'nanum-gothic', 'nanum-myeongjo','jua','do-hyeon','yoon-gothic'];
  const fontSizes = [12, 14, 16, 18, 20, 24, 28];
  const lineHeight = [60, 70, 80, 90, 100, 110, 120, 130, 140, 150, 160, 170, 180, 190, 200];
  const validate = new ValidateHelper();
  const textarea = ref<HTMLTextAreaElement | null>(null);

  interface bookConfigProperty {
      bookid?:string|null|undefined,
      articleid?:string|null|undefined
  }

  const property = defineProps<bookConfigProperty>();

  var pageinfo = ref({
    key : 0,
    userinfo:new Member(),
    myMemberShip : new MyMemberShip(),
    permission: new Permission(),
    article : new Article(),
    isLoading:true,
    book:new Book(),
    chapters:[] as Chapter[],
    chapter:null as Chapter|null,
    form:new FormSetup(),
    previousText:"",
    chatText:"",
    chatwin:false,
    isEdit:false,
    memos : {
      target:new Memo(),
      isShow : false,
      data : [] as Memo[],
      curpage : 1
    },
    globalMemos : {
      target:new Memo(),
      isShow : false,
      data : [] as Memo[],
      curpage : 1
    },
    memoform : {
      id:"",
      index:-1,
      bookid:"",
      title:"",
      memo:"",
      isShow : false
    }
  })

  onMounted(async() => {
    try {
      locale.value = selectedLanguage.value;
      await getUser();
      await GetMyMemberShip();
      await GetBookInfo();
      await GetUserFormSetup();
      await GetArticle();
      await fnMemoPaging(1);
      await fnGlobalMemoPaging(1);
      await GetChapters();

      if (proxy?.LoadingClose) {
        proxy.LoadingClose();
      }
    } catch (ex:any) {
      console.log('onMounted : ' + ex.message);
    } finally {
      pageinfo.value.isLoading = false;
      pageinfo.value.key += 1;
      await scrollToBottom();
    }
  });

  watch(selectedLanguage, () => {
    locale.value = selectedLanguage.value;
    localStorage.setItem('selectedLanguage', selectedLanguage.value);
  });

  const scrollToBottom = async () => {
    if (textarea.value) {
      await nextTick();
      textarea.value.scrollTop = textarea.value.scrollHeight;
    }
  };

  const handleUpdate = (content:string) => {
    pageinfo.value.memoform.memo = content;
  };

  const uploadHandler = (blobInfo: any): Promise<string> => {
    const formData = new FormData();
    formData.append('upload', blobInfo.blob(), blobInfo.filename());

    return new Promise((resolve, reject) => {
      ApiHelper.UploadAsync('/File/Upload', formData)
        .then((rst: ReturnValues) => {
          if (rst.check) {
            let newImages = rst.value.split(',');
            for (let i = 0; i < newImages.length; i++) {
              newImages[i] = config.image + newImages[i];
            }
            resolve(newImages.join(','));
          } else {
            reject(rst.message);
          }
        })
        .catch((error: any) => {
          reject(error.message || 'An unknown error occurred');
        });
    });
  };

  const handleDropzoneSuccess = (images: any[]) => {
    try {
        const imageTags = images.map((image) => `<img src="${image.url}" alt="${image.name}" />`);
        pageinfo.value.memoform.memo += imageTags.join('');
    } catch (e: any) {
        console.log(e.message);
    }
  };

  const applyStyle = () => {
    pageinfo.value.key += 1;
  };

  const getUser = async () => {
    let rst = await memberRep.GetUser();
    if (rst.check && rst.data !== null) {
      pageinfo.value.userinfo = rst.data;
    }
  };

  const fnMemoPaging = async (curpage:number) => {
    let rst = await articleRep.GetMemos(null, { curPage : curpage, pageSize : 10 });
    if (rst.check && rst.data !== null && rst.data !== undefined && rst.data.length > 0) {
      pageinfo.value.globalMemos.data = rst.data;
      pageinfo.value.globalMemos.curpage = curpage;
    }
  };

  const fnGlobalMemoPaging = async (curpage:number) => {
    let rst = await articleRep.GetMemos(property.bookid, { curPage : curpage, pageSize : 10 });
    if (rst.check && rst.data !== null && rst.data !== undefined && rst.data.length > 0) {
      pageinfo.value.memos.data = rst.data;
      pageinfo.value.globalMemos.curpage = curpage;
    }
  };

  const getMaxSeq = ():number => {
    if (pageinfo.value.chapters !== null && pageinfo.value.chapters !== undefined && pageinfo.value.chapters.length > 0) {
      return Math.max(...pageinfo.value.chapters.map(x => x.seq)) + 1;
    } else {
      return 1;
    }
  };

  const fnMemoAppend = (bookid:string) => {
    pageinfo.value.memoform.isShow = true;
    fnMemoClear();
    pageinfo.value.memoform.bookid = bookid;
  };

  const fnMemoCancel = () => {
    pageinfo.value.memoform.isShow = false;
    fnMemoClear();
  };

  const fnMemoClear = () => {
    pageinfo.value.memoform.id = "";
    pageinfo.value.memoform.bookid = "";
    pageinfo.value.memoform.title = "";
    pageinfo.value.memoform.memo = "";
    pageinfo.value.memoform.index = -1;
  };

  const fnMemoSave = async () => {
    let memo = new Memo();
    memo.bookId = pageinfo.value.memoform.bookid;
    memo.title = pageinfo.value.memoform.title;
    memo.content = pageinfo.value.memoform.memo;
    
    console.log('pageinfo.value.memoform.id : ', pageinfo.value.memoform.id)

    if (pageinfo.value.memoform.id === "") {
      let rst = await articleRep.PostMemoRegist(memo);
      if (rst.check && rst.data !== null) {
        if (pageinfo.value.memoform.bookid === "") {
          pageinfo.value.globalMemos.data.push(rst.data);
        } else {
          pageinfo.value.memos.data.push(rst.data);
        }
        pageinfo.value.memoform.isShow = false;
        fnMemoClear();
        pageinfo.value.key += 1;
      } else {
        MessageBox.Alert(rst.message);
      }
    } else {
      memo.id = pageinfo.value.memoform.id;
      let rtn = await articleRep.PutMemoRegist(memo);
      if (rtn.check && rtn.data !== null) {
        if (pageinfo.value.memoform.bookid === "") {
          pageinfo.value.globalMemos.data[pageinfo.value.memoform.index] = rtn.data;
        } else {
          pageinfo.value.memos.data[pageinfo.value.memoform.index] = rtn.data;
        }
        pageinfo.value.memoform.isShow = false;
        fnMemoClear();
        pageinfo.value.key += 1;
      } else {
        MessageBox.Alert(rtn.message);
      }
    }


  };

  const GetMyMemberShip = async () => {
    let rst = await memberRep.GetMyMemberShip();
    if (rst.check && rst.data !== null) {
      pageinfo.value.myMemberShip = rst.data;
      pageinfo.value.permission.Aceept(pageinfo.value.myMemberShip.memberShip);
      console.log('pageinfo.value.permission : ', pageinfo.value.permission);
    } else {
      MessageBox.Alert(t('common.requiredmembership'), () => {
        router.push('/account/membership?tab=membership');
      });
    }
  }

  const GetBookInfo = async () => {
    if (property.bookid !== null && property.bookid !== undefined && property.bookid.trim() !== '') {
      let rst = await articleRep.GetBook(property.bookid);
      if (rst.check) {
        pageinfo.value.book = rst.data;
        pageinfo.value.key += 1;
      }
    }
  };

  const GetChapters = async () => {
    if (property.bookid !== null && property.bookid !== undefined && property.bookid.trim() !== '') {
      let rst = await articleRep.GetChapters(property.bookid);
      if (rst.check) {
        pageinfo.value.chapters = rst.data;

        for(let i = 0; i < pageinfo.value.chapters.length; i++) {
          console.log('chapter : ', pageinfo.value.chapters[i])
          console.log('chapterId : ', pageinfo.value.article.chapterId)
          
          if (pageinfo.value.chapters[i].id === pageinfo.value.article.chapterId) {
            console.log('set : ', pageinfo.value.chapters[i])
            pageinfo.value.chapter = pageinfo.value.chapters[i];
            break;
          }
        }

        pageinfo.value.key += 1;
      }
    }
  };

  const GetArticle = async () => {
    if (property.articleid !== null && property.articleid !== undefined && property.articleid.trim() !== '') {
      let rst = await articleRep.GetArticle(property.articleid);
      if (rst.check) {
        pageinfo.value.article = rst.data;
        pageinfo.value.key += 1;
      }
    }
  };

  const GetUserFormSetup = async () => {
    let rst = await articleRep.GetUserFormSetup();
    if (rst.check) {
      pageinfo.value.form = rst.data;
      pageinfo.value.key += 1;
    }
  };

  const fnSaveSetup = async () => {
    let rst = await articleRep.PostFormSetup(pageinfo.value.form);
    if (rst.check) {
      MessageBox.Success(t('system.Saved'));
    } else {
      MessageBox.Alert(rst.message);
    }
  };

  const goList = () => {
    MessageBox.Confirm(t('common.requiredsave'), () => {
      router.push(`/book/articles/${pageinfo.value.book.id}`);
    });
  }

  const fnSave = async () => {
    if (validate.IsNullOrWhiteSpace(pageinfo.value.article.title)) {
      MessageBox.Alert(t('system.required') + ' : title');
      return;
    }

    if (validate.IsNullOrWhiteSpace(pageinfo.value.article.content)) {
      MessageBox.Alert(t('system.required') + ' : content');
      return;
    }

    if (validate.IsNullOrWhiteSpace(pageinfo.value.article.chapterId)) {
      MessageBox.Alert(t('system.required') + ' : chapter');
      return;
    }

    try {
      let rst = new ReturnValues();
      pageinfo.value.isLoading = true;

      if (pageinfo.value.article.id !== null && pageinfo.value.article.id !== undefined && String(pageinfo.value.article.id).trim() !== '') {
        rst = await articleRep.PutArticleModify(pageinfo.value.article);
      } else {
        pageinfo.value.article.writerId = pageinfo.value.book.writerId;
        pageinfo.value.article.ownerId = pageinfo.value.userinfo.id;
        rst = await articleRep.PostArticleRegist(pageinfo.value.article);
      }

      pageinfo.value.isLoading = false;

      if (rst.check) {
        pageinfo.value.article = rst.data;
        MessageBox.Success(t('system.Saved'));
      } else {
        MessageBox.Alert(rst.message);
      }
    } catch (e:any) {
      let body = `##Description \r\n`;
      body += `userId : ${pageinfo.value.userinfo.id}\r\n`;
      body += `position : Articles/ArticleForm.vue\r\n`;
      body += `\r\n`;
      body += `###Summary\r\n`;
      body += `${e.stack}`;
      await nofifyRep.PostGithubIssue(`[Studio] Error : ${e.message}`, body, `bug`);
    }
  };


const fnAssistant = () => {
  if (validate.IsNullOrWhiteSpace(pageinfo.value.article.content)) {
    MessageBox.Confirm(t('site.AI_Wouldyoulikedraft'), async () => {
      if (validate.IsNullOrWhiteSpace(pageinfo.value.book.description) || pageinfo.value.book.description.length < 30) {
        MessageBox.Confirm(t('site.AI_Required_Draft'), () => {
          router.push(`/book/settings/${pageinfo.value.book.id}`);
        });
      } else {
        pageinfo.value.previousText = "1";
        pageinfo.value.isEdit = true;
        pageinfo.value.isLoading = true;

        let intro = new ReturnValues();
        if (pageinfo.value.permission.AITalk) {
          intro = await copilotRep.PostAgentIntroduction(pageinfo.value.book.id, pageinfo.value.book.description);
        } else {
          intro = await aiRep.PostAgentIntroduction(pageinfo.value.book.description);
        }
        
        pageinfo.value.isLoading = false;
        if (intro.check) {
          pageinfo.value.chatText = decodeURIComponent(intro.value).trim();
          pageinfo.value.chatwin = true;
        } else {
          MessageBox.Alert(intro.message);
        }
      }
    });
  } else {
    MessageBox.Confirm(t('site.AI_assistantContinue'), async () => {
        pageinfo.value.previousText = "2";
        pageinfo.value.isEdit = true;
        pageinfo.value.isLoading = true;
        let tmp = pageinfo.value.article.content;
        if (tmp.length < 2000) {
          tmp = pageinfo.value.book.description + '\r\n' + tmp;
        }
        let intro = await aiRep.PostAgentContinuation(tmp);
        pageinfo.value.isLoading = false;
        if (intro.check) {
          pageinfo.value.chatText = pageinfo.value.article.content + '\r\n' + decodeURIComponent(intro.value).trim();
          pageinfo.value.chatwin = true;
        } else {
          MessageBox.Alert(intro.message);
        }
    });
  }
};

const closeChatManager = () => {
  pageinfo.value.chatwin = false;
  pageinfo.value.previousText = "";
  pageinfo.value.chatText = "";
  console.log('pageinfo.value.chatwin : ', pageinfo.value.chatwin);
};

const fnAssistantEdit = () => {
  if (!validate.IsNullOrWhiteSpace(pageinfo.value.article.content)) {
    MessageBox.Confirm(t('site.AI_assistantSupplementary '), async () => {
      pageinfo.value.previousText = "3";
      pageinfo.value.isEdit = true;
      pageinfo.value.isLoading = true;

      let intro = new ReturnValues();
      if (pageinfo.value.permission.AITalk) {
        intro = await copilotRep.PostAgentProsePolish(pageinfo.value.book.id, pageinfo.value.article.content);
      } else {
        intro = await aiRep.PostAgentProsePolish(pageinfo.value.article.content);
      }
      
      pageinfo.value.isLoading = false;
      if (intro.check) {
        pageinfo.value.chatText = decodeURIComponent(intro.value).trim();
        pageinfo.value.chatwin = true;
      } else {
        MessageBox.Alert(intro.message);
      }
    });
  } else {
    MessageBox.Alert(t('common.requiredContent'));
  }
};

const fnAssistantCorrect = () => {
  if (!validate.IsNullOrWhiteSpace(pageinfo.value.article.content)) {
    MessageBox.Confirm(t('site.AI_assistantOpinion'), async () => {
      pageinfo.value.previousText = "";
      pageinfo.value.isEdit = false;
      pageinfo.value.isLoading = true;

      let intro = new ReturnValues();
      if (pageinfo.value.permission.AITalk) {
        intro = await copilotRep.PostAgentCorrect(pageinfo.value.book.id, pageinfo.value.article.content);
      } else {
        intro = await aiRep.PostAgentCorrect(pageinfo.value.article.content);
      }
      
      pageinfo.value.isLoading = false;
      if (intro.check) {
        pageinfo.value.chatText = decodeURIComponent(intro.value).trim();
        pageinfo.value.chatwin = true;
      } else {
        MessageBox.Alert(intro.message);
      }
    });
  } else {
    MessageBox.Alert(t('common.requiredContent'));
  }
};


const fnSubmitOk = async (check:number) => {
  switch (check) {
    case 1:
      pageinfo.value.article.content = pageinfo.value.chatText;
      pageinfo.value.chatText = "";
      break;
    case 2:
      pageinfo.value.article.content += `\r\n${pageinfo.value.chatText}`;
      pageinfo.value.chatText = "";
      break;
  }

  closeChatManager();
  pageinfo.value.key += 1;
};


const addEmptyLines = () => {
  const lines = pageinfo.value.article.content.split('\n');
  const newLines = lines.flatMap(line => line.trim() === '' ? [line] : [line, '']);
  pageinfo.value.article.content = newLines.join('\n');
  pageinfo.value.key += 1;
}

const removeEmptyLines = () => {
  const lines = pageinfo.value.article.content.split('\n');
  
  const newLines = [];
  let wasLastLineEmpty = false;
  let emptyLineRemoved = false;

  for (const line of lines) {
    if (line.trim() === '') {
      if (!wasLastLineEmpty && !emptyLineRemoved) {
        emptyLineRemoved = true;
      } else if (wasLastLineEmpty) {
        continue;
      }
      wasLastLineEmpty = true;
    } else {
      newLines.push(line);
      wasLastLineEmpty = false;
    }
  }

  pageinfo.value.article.content = newLines.join('\n');
  pageinfo.value.key += 1;
}

const fnMemoSelect = (memo:Memo) => {
  pageinfo.value.memos.target = memo;
  pageinfo.value.memos.isShow = true;
};

const fnMemoEdit = (id:string, index:number) => {
  let memo = pageinfo.value.memos.data.filter(x => x.id === id)[0] as Memo;
  pageinfo.value.memoform.id = memo.id;
  pageinfo.value.memoform.index = index;
  pageinfo.value.memoform.bookid = memo.bookId;
  pageinfo.value.memoform.title = memo.title;
  pageinfo.value.memoform.memo = memo.content;
  pageinfo.value.memoform.isShow = true;
};

const fnMemoRemove = (memo:Memo, index:number) => {
  MessageBox.Confirm(t('system.RemoveConfirm'), async () => {
    let rst = await articleRep.DeleteMemo(memo.id);
    if (rst.check) {
      pageinfo.value.memos.data.splice(index, 1);
      pageinfo.value.key += 1;
      MessageBox.Success(t('system.Deleted'));
    } else {
      MessageBox.Alert(rst.message);
    }
  });
};

const fnGlobalMemoSelect = (memo:Memo) => {
  pageinfo.value.globalMemos.target = memo;
  pageinfo.value.globalMemos.isShow = true;
};

const fnGlobalMemoEdit = (id:string, index:number) => {
  let memo = pageinfo.value.globalMemos.data.filter(x => x.id === id)[0] as Memo;
  pageinfo.value.memoform.id = memo.id;
  pageinfo.value.memoform.index = index;
  pageinfo.value.memoform.bookid = "";
  pageinfo.value.memoform.title = memo.title;
  pageinfo.value.memoform.memo = memo.content;
  pageinfo.value.memoform.isShow = true;
};

const fnGlobalMemoRemove = (memo:Memo, index:number) => {
  MessageBox.Confirm(t('system.RemoveConfirm'), async () => {
    let rst = await articleRep.DeleteMemo(memo.id);
    if (rst.check) {
      pageinfo.value.globalMemos.data.splice(index, 1);
      pageinfo.value.key += 1;
      MessageBox.Success(t('system.Deleted'));
    } else {
      MessageBox.Alert(rst.message);
    }
  });
};

const fnMemoPreviousPage = async () => {
  if (pageinfo.value.memos.curpage > 1) {
    await fnMemoPaging(pageinfo.value.memos.curpage - 1);
  }
};

const fnMemoNextPage = async () => {
  await fnMemoPaging(pageinfo.value.memos.curpage + 1);
};

const fnGlobalPreviousPage = async () => {
  if (pageinfo.value.globalMemos.curpage > 1) {
    await fnGlobalMemoPaging(pageinfo.value.globalMemos.curpage - 1);
  }
};

const fnGlobalNextPage = async () => {
  await fnGlobalMemoPaging(pageinfo.value.globalMemos.curpage + 1);
};

const fnBeforeArticle = async () => {
  if (property.articleid !== null && property.articleid !== undefined && property.articleid.trim() !== '') {
      let rst = await articleRep.GetBeforeArticle(property.articleid);
      if (rst.check && rst.data !== null) {
        document.location.href = `/book/article/${pageinfo.value.book.id}/${rst.data.id}`;
      }
    }
};

const fnAfterArticle = async () => {
  if (property.articleid !== null && property.articleid !== undefined && property.articleid.trim() !== '') {
      let rst = await articleRep.GetAfterArticle(property.articleid);
      if (rst.check && rst.data !== null) {
        document.location.href = `/book/article/${pageinfo.value.book.id}/${rst.data.id}`;
      }
    }
};
</script>

