<template>
<div class="bg-white shadow-sm p-4 mb-4" v-if="pageinfo.book !== null && pageinfo.book !== undefined">
<h1 class="text-2xl font-bold">{{ pageinfo.book.title }} <FavoriteButton :bookid="property.book.id" v-if="property.book.id !== null && property.book.id !== undefined && String(property.book.id).trim() !== ''" />
    <span class="float-right inline-block py-1 px-2 last:mr-0 mr-1 text-sm pt-3">
        <i class="fas fa-circle mr-2" :class="fnConditionColor(pageinfo.book)"></i>
        <label>{{t(`site.${fnStatus(pageinfo.book)}`)}}</label>
    </span>
</h1>
<div class="w-full flex justify-between mt-3 mb-3 p-3 bg-gray-100 rounded-md" v-if="pageinfo.writer !== null && pageinfo.writer !== undefined">
<h6><small>작가 </small> <b>{{ pageinfo.writer.nickname }}</b></h6>
<span><small>작품등록 : </small>{{ dayjs.utc(pageinfo.book.condition.registDate).local().format('YYYY-MM-DD') }}</span>
</div>
<div class="w-full flex justify-between space-x-6 mt-8">
    <div class="flex" style="width:190px;">
    <div class="w-full">
    <label for="Cover" class="block text-sm font-medium text-gray-700">{{ t('site.form_label_cover') }}</label>
    <div class="bookcover shadow-lg">
        <img :src="pageinfo.cover" width="180" height="260" />
    </div>
    </div>
    </div>
    <div class="flex" style="width:calc(100% - 190px)">
    <div class="w-full">
        <div class="flex justify-start">
          <label for="Description" class="block text-sm font-medium text-gray-700">{{t('site.form_label_description')}}</label>
          <button class="ml-3 text-mini bg-green-500 text-white btn-mini" @click="copyToClipboard(pageinfo.book.description)">{{ t('common.copy') }}</button>
        </div>
        <p class="w-full">
        {{pageinfo.book.description}}
        </p>
    </div>
    </div>
</div>
</div>
</template>

<script setup lang="ts">
import { Book,Writer } from '@/entities';
import { FavoriteButton } from '..';
import { onMounted, ref,defineProps } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { ArticleRepository } from '@/repositories';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import localizedFormat from 'dayjs/plugin/localizedFormat'; 
import { MessageBox, ValidateHelper } from '@/utility';

dayjs.extend(utc);
dayjs.extend(localizedFormat);

const router = useRouter();
const { t, locale } = useI18n();
const articleRep = new ArticleRepository();
const validate = new ValidateHelper();

interface bookConfigProperty {
    book:Book
}

const property = defineProps<bookConfigProperty>();

var pageinfo = ref({
    book: new Book(),
    writer:new Writer(),
    isfavo : false,
    cover : "https://via.placeholder.com/180x260"
});

onMounted(async () => {
    pageinfo.value.book = property.book;
    await MemberInfo();

    if (validate.IsNullOrWhiteSpace(pageinfo.value.book.cover) === false) {
      pageinfo.value.cover = pageinfo.value.book.cover;
    } else if (validate.IsNullOrWhiteSpace(pageinfo.value.book.thumbnail) == false) {
      pageinfo.value.cover = pageinfo.value.book.thumbnail;
    } else {
      pageinfo.value.cover = "https://via.placeholder.com/180x260";
    }
});

const MemberInfo = async () => {
  if (pageinfo.value.book !== null && pageinfo.value.book !== undefined) {
    if (pageinfo.value.book.writerId !== null && pageinfo.value.book.writerId !== undefined && String(pageinfo.value.book.writerId).trim() !== '') {
      let rst = await articleRep.GetWriter(pageinfo.value.book.writerId);
      if (rst.check) {
        pageinfo.value.writer = rst.data;
      }
    }
  }
};

const fnStatus = (book:Book) => {
    let result = '';

    if (book.isPublish) {
      result = "Open";
    } else if (book.isReviewOpen) {
      result = "Review";
    } else {
      result = "Close";
    }

    return result;
  };

const fnConditionColor = (book:Book) => {
    let result = "";

    if (book.isPublish) {
      result = "text-green-500";
    } else if (book.isReviewOpen) {
      result = "text-orange-500";
    } else {
      result = "text-red-500";
    }

    return result;
  };

  const copyToClipboard = async (text:string) => {
  try {
    await navigator.clipboard.writeText(text);
    MessageBox.Success(t('common.copyComplete'));
  } catch (err) {
    console.error('Failed to copy text to clipboard:', err);
  }
};
</script>