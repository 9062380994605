import config from "@/config";
import { BoardContent, Partner } from "@/entities";
import { PagingParameter, ReturnValues } from "@/models";
import { ApiHelper } from "@/utility"
import { ValidateHelper } from "@/utility";

const checker = new ValidateHelper();

export class BoardRepository {
    private slug:string = "studio";

    async GetMasters(paging:PagingParameter):Promise<ReturnValues> {
        let url = `${config.apis.board}/api/Board/${this.slug}/List?CurPage=${paging.curPage}&PageSize=${paging.pageSize}`;
        //console.log('BoardRepository GetMasters : ', url);
        return await ApiHelper.GetAsync(url);
    }

    async GetMaster(masterCode:string):Promise<ReturnValues> {
        let url = `${config.apis.board}/api/Board/${this.slug}/${masterCode}/Info`;
        //console.log('BoardRepository GetMaster : ', url);
        return await ApiHelper.GetAsync(url);
    }

    async GetBoards(masterCode:string, paging:PagingParameter):Promise<ReturnValues> {
        let url = `${config.apis.board}/api/Board/${this.slug}/${masterCode}/List?CurPage=${paging.curPage}&PageSize=${paging.pageSize}`;
        //console.log('BoardRepository GetBoards : ', url);
        return await ApiHelper.GetAsync(url);
    }

    async GetUserBoards(masterCode:string, paging:PagingParameter):Promise<ReturnValues> {
        let url = `${config.apis.board}/api/BoardContent/${this.slug}/${masterCode}/List?CurPage=${paging.curPage}&PageSize=${paging.pageSize}`;
        //console.log('BoardRepository GetBoards : ', url);
        return await ApiHelper.GetAsync(url);
    }

    async GetContent(contentId:string):Promise<ReturnValues> {
        let url = `${config.apis.board}/api/Board/View/${contentId}`;
        //console.log('BoardRepository GetContent : ', url);
        return await ApiHelper.GetAsync(url);
    }

    async RegistContent(board:BoardContent):Promise<ReturnValues> {
        let url = `${config.apis.board}/api/BoardContent/Regist`;
        let jsonData = {
            masterId: board.masterId,
            title: board.title,
            content: encodeURIComponent(board.content)
        };
        //console.log('BoardRepository PostContent : ', url);
        return await ApiHelper.PostAsync(url, jsonData);
    }

    async UpdateContent(board:BoardContent):Promise<ReturnValues> {
        let url = `${config.apis.board}/api/BoardContent/Modify/${board.id}`;
        let jsonData = {
            masterId: board.masterId,
            title: board.title,
            content: encodeURIComponent(board.content)
        };
        //console.log('BoardRepository UpdateContent : ', url);
        return await ApiHelper.PutAsync(url, jsonData);
    }

    async DeleteContent(contentId:string):Promise<ReturnValues> {
        let url = `${config.apis.board}/api/BoardContent/Remove/${contentId}`;
        //console.log('BoardRepository DeleteContent : ', url);
        return await ApiHelper.DeleteAsync(url);
    }

    async RegistPartner(partner:Partner):Promise<ReturnValues> {
        let url = `${config.apis.board}/api/Partner/Regist`;
        let jsonData = {
            title: partner.title,
            name: partner.name,
            email: partner.email,
            phone: partner.phone,
            companyName: partner.companyName,
            content: partner.content
          };
        //console.log('BoardRepository RegistPartner : ', url);
        return await ApiHelper.PostAsync(url, jsonData);
    }

}
