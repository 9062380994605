import config from "@/config";
import { ReturnValues } from "@/models";
import { ApiHelper } from "@/utility"
import { ValidateHelper } from "@/utility";

const checker = new ValidateHelper();

export class NotifyRepository {
    private slug:string = "studio";

    async PostGithubIssue(title:string,content:string,tag:string):Promise<ReturnValues> {
        let url = `${config.apis.notification}/api/Notify/Github/Issue`;
        //console.log('NotifyRepository PostGithubIssue : ', url);
        let jsonData = {
            title: title,
            content: content,
            tag: tag
          };
        return await ApiHelper.PostAsync(url, jsonData);
    }

    async PostSendMessage(msgType:string,subscriber:string,message:string,option:string):Promise<ReturnValues> {
        let url = `${config.apis.notification}/api/Notify/WebSocket/Message`;
        //console.log('TranslationRepository PostSendMessage : ', url);
        let jsonData = {
            msgType: msgType,
            subscriber: subscriber,
            message: message,
            option: option
          }
        return await ApiHelper.PostAsync(url, jsonData);
    }

}
