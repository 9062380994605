<template>
  <Loading :loading="!pageinfo.isReady" />

  <div class="w-full flex justify-between mb-1">
    <div class="ml-2">
      <h3 class="text-2xl subcolor uppercase"><b>{{ pageinfo.master.title }}</b></h3>
    </div>
    <div class="text-sm mr-2">
      <router-link to="/main" class="text-gray-400 uppercase text-sm"><small>HOME</small></router-link>
      <span class="px-2 text-gray-300">&gt;</span>
      <a href="#" class="text-gray-500 uppercase text-md"><b>{{ pageinfo.master.title }}</b></a>
    </div>
  </div>

  <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white" :key="pageinfo.key">
    <div class="p-1">
      <input type="text" name="title" class="focus:outline-none w-full px-4 py-2 border-2 border-gray-100" v-model="pageinfo.content.title" placeholder="제목을 입력하세요." />
    </div>
    <div class="w-full">
      <WebEditor :content="pageinfo.content.content" :multiupload="false" :key="`boardcontent_${pageinfo.key}`" :callback="handleUpdate" />
    </div>
    <hr/>
    <div class="py-2 flex justify-between px-2">
      <button @click="fnSave" class="px-4 py-2 uppercase mainbg text-white font-semibold rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-opacity-50" type="button">
        <i class="fa-solid fa-floppy-disk"></i> {{ t('common.Save') }}
      </button>
      <button @click="goList" class="px-4 py-2 uppercase bg-gray-400 text-white font-semibold rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-opacity-50" type="button">
        <i class="fa-solid fa-folder"></i> {{ t('common.List') }}
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { onMounted, ref,defineProps,watch } from 'vue';
  import { Loading } from "@/components";
  import { BoardRepository } from '@/repositories';
  import store from '@/store';
  import { BoardContent, BoardMaster } from '@/entities';
  import { MessageBox } from '@/utility';
  import { useRouter } from 'vue-router';
  import { WebEditor } from '@/components/Editor';
  import { useI18n } from 'vue-i18n';
  import dayjs from 'dayjs';
  import utc from 'dayjs/plugin/utc';
  import localizedFormat from 'dayjs/plugin/localizedFormat'; 
  import { ValidateHelper } from '@/utility';
import { ReturnValues } from '@/models';

  dayjs.extend(utc);
  dayjs.extend(localizedFormat);
  const { t, locale } = useI18n();
  const boardRep = new BoardRepository();
  const router = useRouter();
  const checker = new ValidateHelper();

  interface BoardProperty {
    code?:string|null|undefined,
    id?:string|null|undefined
  }

  const property = defineProps<BoardProperty>();

  var pageinfo = ref({
    key:0,
    master:new BoardMaster(),
    content:new BoardContent(),
    isReady:false
  });

  onMounted(async() => {
    await GetMaster();
    await GetContent();
    pageinfo.value.key += 1;
    pageinfo.value.isReady = true;
  });

  watch(property, async () => {
    await GetMaster();
    await GetContent();
    pageinfo.value.key += 1;
    pageinfo.value.isReady = true;
  });

  const GetContent = async () => {
    if (property.id !== null && property.id !== undefined) {
      let rst = await boardRep.GetContent(property.id);
      if (rst.check && rst.data !== null) {
        pageinfo.value.content = rst.data;
      }
    }
  };

  const GetMaster = async () => {
    if (property.code !== null && property.code !== undefined) {
      let rst = await boardRep.GetMaster(property.code);
      if (rst.check && rst.data !== null) {
        pageinfo.value.master = rst.data;
      }
    }
  };

  const goList = () => {
    MessageBox.Confirm("저장하지 않은 내용은 유실됩니다.", () => {
      router.push(`/board/list/${pageinfo.value.master.masterCode}`);
    });
  };

  const handleUpdate = (content:string) => {
    pageinfo.value.content.content = content;
  };

  const fnSave = async () => {
    if (checker.IsNullOrWhiteSpace(pageinfo.value.content.title)) {
      MessageBox.Alert(t('system.required') + ' : title');
      return;
    }

    if (checker.IsNullOrWhiteSpace(pageinfo.value.content.content)) {
      MessageBox.Alert(t('system.required') + ' : content');
      return;
    }

    let rst = new ReturnValues();
    pageinfo.value.isReady = false;

    if (pageinfo.value.content.id !== null && pageinfo.value.content.id !==  undefined && String(pageinfo.value.content.id) !== '') {
      rst = await boardRep.UpdateContent(pageinfo.value.content);
    } else {
      pageinfo.value.content.masterId = pageinfo.value.master.id;
      rst = await boardRep.RegistContent(pageinfo.value.content);
    }

    pageinfo.value.isReady = true;

    if (rst.check && rst.data !== null) {
      MessageBox.Success(t('system.Saved'), () => {
        router.push(`/board/${pageinfo.value.master.masterCode}/view/${rst.data.id}`)
      });
    } else {
      MessageBox.Alert(rst.message);
    }
  };
</script>