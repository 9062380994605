import config from "@/config";
import { BillingKey, Member, MemberShip } from "@/entities";
import { CardInfo, PagingParameter, ReturnValues } from "@/models";
import { ApiHelper } from "@/utility"

export class MemberRepository {
    async GetUser():Promise<ReturnValues> {
        let url = `${config.apis.member}/api/User`;
        //console.log('MemberRepository GetUser : ', url);
        return await ApiHelper.GetAsync(url);
    }

    async GetUserInfo(userid:string):Promise<ReturnValues> {
        let url = `${config.apis.member}/api/UserInfo/${userid}`;
        //console.log('MemberRepository GetUserInfo : ', url);
        return await ApiHelper.GetAsync(url);
    }

    async GetMemberShips(paging:PagingParameter):Promise<ReturnValues> {
        let url = `${config.apis.member}/api/MemberShip/List?CurPage=${paging.curPage}&PageSize=${paging.pageSize}`;
        //console.log('MemberRepository GetMemberShips : ', url);
        return await ApiHelper.GetAsync(url);
    }

    async GetMyMemberShips(paging:PagingParameter):Promise<ReturnValues> {
        let url = `${config.apis.member}/api/MyMemberShip/List?CurPage=${paging.curPage}&PageSize=${paging.pageSize}`;
        //console.log('MemberRepository GetMyMemberShips : ', url);
        return await ApiHelper.GetAsync(url);
    }

    async GetMyMemberShip():Promise<ReturnValues> {
        let url = `${config.apis.member}/api/MyMemberShip/View`;
        //console.log('MemberRepository GetMyMemberShip : ', url);
        return await ApiHelper.GetAsync(url);
    }

    async UpdateMemberInfo(member:Member):Promise<ReturnValues> {
        let url = `${config.apis.member}/api/User`;
        //console.log('MemberRepository UpdateMemberInfo : ', url);
        let jsonData = {
            background: member.background,
            bio: member.bio,
            job: member.job,
            nickName: member.nickName,
            photo: member.photo
        };
        return await ApiHelper.PutAsync(url, jsonData);
    }

    async PostFreePaymentRegist(membershipId:string):Promise<ReturnValues> {
        let url = `${config.apis.member}/api/payment/regist`;
        //console.log('MemberRepository PostFreePaymentRegist : ', url);
        let jsonData = {
            memberShipId: membershipId,
            age: 3
          };
        return await ApiHelper.PostAsync(url, jsonData);
    }

    async PutPasswordChange(password:string, newPassword:string):Promise<ReturnValues> {
        let url = `${config.apis.member}/api/User/Password/Change`;
        //console.log('MemberRepository PutPasswordChange : ', url);
        let jsonData = {
            password: password,
            newPassword: newPassword
          };
        return await ApiHelper.PutAsync(url, jsonData);
    }

    async GetCoupons(paging:PagingParameter):Promise<ReturnValues> {
        let url = `${config.apis.member}/api/Coupon/List?CurPage=${paging.curPage}&PageSize=${paging.pageSize}`;
        //console.log('MemberRepository GetCoupons : ', url);
        return await ApiHelper.GetAsync(url);
    }

    async PostCouponRegist(code:string):Promise<ReturnValues> {
        let url = `${config.apis.member}/api/Coupon/Regist`;
        //console.log('MemberRepository PostCouponRegist : ', url);
        let jsonData = {
            code: code
          };
        return await ApiHelper.PostAsync(url, jsonData);
    }

    async GetProduct(productCode:string):Promise<ReturnValues> {
        let url = `${config.apis.member}/api/Payment/Product/${productCode}`;
        //console.log('MemberRepository GetProduct : ', url);
        return await ApiHelper.GetAsync(url);
    }

    async GetOrder(orderCode:string):Promise<ReturnValues> {
        let url = `${config.apis.member}/api/Payment/Order/${orderCode}`;
        //console.log('MemberRepository GetOrder : ', url);
        return await ApiHelper.GetAsync(url);
    }

    async PostOrderNo(membershipId:string):Promise<ReturnValues> {
        let url = `${config.apis.member}/api/Payment/Order/${membershipId}`;
        //console.log('MemberRepository PostOrderNo : ', url);
        return await ApiHelper.PostAsync(url, {});
    }

    async PostNicePayCardRegist(orderNo:string, card:CardInfo):Promise<ReturnValues> {
        let url = `${config.apis.member}/api/NicePay/Card/Regist/${orderNo}`;
        //console.log('MemberRepository PostCouponRegist : ', url);
        let jsonData = {
            cardNo: card.cardNo,
            expYear: card.expYear,
            expMonth: card.expMonth,
            idNo: card.idNo,
            cardPw: card.cardPw
        };
        return await ApiHelper.PostAsync(url, jsonData);
    }

    async PostNicePayAccept(tid:string, orderid:string):Promise<ReturnValues> {
        let url = `${config.apis.member}/api/NicePay/Pay/Accept`;
        //console.log('MemberRepository PostNicePayAccept : ', url);
        let jsonData = {
            tid: tid,
            orderId: orderid
          };
        return await ApiHelper.PostAsync(url, jsonData);
    }

    async GetHistory(paging:PagingParameter):Promise<ReturnValues> {
        let url = `${config.apis.member}/api/Payment/History?CurPage=${paging.curPage}&PageSize=${paging.pageSize}`;
        //console.log('MemberRepository GetHistory : ', url);
        return await ApiHelper.GetAsync(url);
    }

    async PutNicePayCancel(logId:string):Promise<ReturnValues> {
        let url = `${config.apis.member}/api/NicePay/Pay/Cancel/${logId}`;
        //console.log('MemberRepository PutNicePayCancel : ', url);
        return await ApiHelper.PutAsync(url, {});
    }

    async GetCards(paging:PagingParameter):Promise<ReturnValues> {
        let url = `${config.apis.member}/api/Payment/Cards?CurPage=${paging.curPage}&PageSize=${paging.pageSize}`;
        //console.log('MemberRepository GetHistory : ', url);
        return await ApiHelper.GetAsync(url);
    }

    async DeleteCard(cardid:string):Promise<ReturnValues> {
        let url = `${config.apis.member}/api/NicePay/Card/Remove/${cardid}`;
        //console.log('MemberRepository DeleteCard : ', url);
        return await ApiHelper.DeleteAsync(url);
    }

    async PutCard(cardid:string):Promise<ReturnValues> {
        let url = `${config.apis.member}/api/NicePay/Card/Main/${cardid}`;
        //console.log('MemberRepository DeleteCard : ', url);
        return await ApiHelper.PutAsync(url);
    }
}
