<template>
  <div class="flex flex-wrap" :key="`SiteDropdown_${pageinfo.key}`">
    <div class="w-full px-1">
      <div class="relative flex flex-col align-middle w-full">
  <button
    class="w-full text-white font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 mainbg"
    type="button"
    ref="btnDropdownRef"
    v-on:click="toggleDropdown()"
  >
    {{ pageinfo.buttonText }}
  </button>
  <div
    ref="popoverDropdownRef"
    class="mainbg text-base z-50 py-2 list-none text-left rounded shadow-lg mt-1"
    style="width: calc(100% - 5px)"
    v-bind:class="{ hidden: !dropdownPopoverShow, block: dropdownPopoverShow }"
  >
    <a
      href="#"
      @click="fnMenuLink(book)"
      class="text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent text-white pointer"
      v-for="(book, index) in favorites"
      :key="`menu_${index}`"
    >
      {{ book.title }}
    </a>
    <div
      v-show="false"
      class="h-0 my-2 border border-solid border-t-0 border-pink-700 opacity-25"
    ></div>
    <a
      href="#"
      @click="fnCreateLink"
      class="text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent text-white pointer"
    >
      {{ t('site.newBook') }}
    </a>
  </div>
</div>

    </div>
  </div>
</template>
  
<script setup lang="ts">
import { onMounted, ref, computed, watch } from 'vue';
import { createPopper, Instance } from '@popperjs/core';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { Book, Member } from '@/entities';
import { MessageBox } from '@/utility';
import { useI18n } from 'vue-i18n';
import { MemberRepository } from '@/repositories';

const { t } = useI18n();
const router = useRouter();
const btnDropdownRef = ref<HTMLElement | null>(null);
const popoverDropdownRef = ref<HTMLElement | null>(null);
const dropdownPopoverShow = ref(false);
const store = useStore();
const memberRep = new MemberRepository();

let popperInstance: Instance | null = null;

var pageinfo = ref({
  key: 1,
  buttonText: `${t('common.Favorites')}`,
  userinfo: null as Member | null,
});

const favorites = computed(() => store.getters['favorites/getFavorites']);

onMounted(async () => {
  await getUser();
  await store.dispatch('favorites/fetchFavorites');
  pageinfo.value.key += 1;
});

watch(favorites, () => {
  // 즐겨찾기가 변경되면 key를 업데이트하여 컴포넌트를 재렌더링
  //console.log('favorites : ', favorites.value)
  pageinfo.value.key += 1;
}, { deep: true });

const getUser = async () => {
  let rst = await memberRep.GetUser();
  if (rst.check) {
    pageinfo.value.userinfo = rst.data;
  }
};

const toggleDropdown = () => {
  dropdownPopoverShow.value = !dropdownPopoverShow.value;

  if (dropdownPopoverShow.value) {
    createPopper(btnDropdownRef.value!, popoverDropdownRef.value!, {
      placement: 'bottom-start',
    });
  } else if (popperInstance) {
    popperInstance.destroy();
    popperInstance = null;
  }
};

const fnMenuLink = (book: Book) => {
  pageinfo.value.buttonText = book.title;
  dropdownPopoverShow.value = false;

  if (pageinfo.value.userinfo !== null && book.condition.createdBy === pageinfo.value.userinfo.id) {
    router.push(`/book/articles/${book.id}`);
  } else {
    if (book.isPublish) {
      router.push(`/book/publish/${book.id}`);
    } else if (book.isReviewOpen) {
      router.push(`/review/book/${book.id}`);
    } else {
      MessageBox.Confirm("해당 작품은 현재 이용할 수 없습니다. 즐겨찾기를 해제하시겠습니까?", async () => {
        let rst = await store.dispatch('favorites/removeFavorite', book.id);
        if (rst.check) {
          MessageBox.Success("삭제되었습니다.");
        }
      });
    }
  }
};

const fnCreateLink = () => {
  pageinfo.value.buttonText = 'FAVORITES';
  router.push('/book/settings');
  dropdownPopoverShow.value = false;
};
</script>
