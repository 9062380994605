<template>
  <Loading :loading="pageinfo.isloading" />
  <div class="w-full p-6 bg-white rounded-lg shadow-lg">
    <!-- Header Section -->
    <div class="mb-4">
      <h2 class="text-xl font-bold text-gray-800">{{ t('site.webnoveltrend') }}</h2>
    </div>

    <!-- Chart Section -->
    <div class="grid grid-cols-1 sm:grid-cols-2 gap-6">
      <div v-for="(item, index) in pageinfo.data.data" :key="`chart_${index}`" class="p-4 border rounded-lg shadow-sm bg-gray-50">
        <h3 class="text-lg font-semibold text-gray-700">{{ item.platform }}</h3>
        <div class="mt-4">
          <canvas :id="'chart-' + index"></canvas>
        </div>
      </div>
    </div>

    <!-- Trend Analysis Text -->
    <div class="mt-6">
      <div class="text-sm text-gray-600" v-html="HtmlHelper.write(pageinfo.data.ment)">
      </div>
    </div>

  </div>
</template>

<script setup lang="ts">
import { Loading } from '@/components';
import { onMounted, ref } from 'vue';
import { ArticleRepository, MemberRepository } from '@/repositories';
import { MyMemberShip, Permission, StatResponse } from '@/entities';
import { MessageBox, HtmlHelper } from '@/utility';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { Chart, registerables } from 'chart.js';

// Chart.js에 필요한 모든 구성 요소 등록
Chart.register(...registerables);

dayjs.extend(utc);
dayjs.extend(localizedFormat);
const articleRep = new ArticleRepository();
const memberRep = new MemberRepository();
const router = useRouter();
const { t } = useI18n();

const pageinfo = ref({
  isloading: true,
  myMemberShip: new MyMemberShip(),
  permission: new Permission(),
  data: new StatResponse()
});

const charts = ref<Chart<'bar', number[], string>[]>([]); // Chart 객체 타입 명시

onMounted(async () => {
  await GetMyMemberShip();
  await GetAiStat();
  renderCharts();
  pageinfo.value.isloading = false;
});

const GetAiStat = async () => {
  let rst = await articleRep.GetAiStat();
  if (rst.check && rst.data !== null) {
    console.log('rst : ', rst.data);
    pageinfo.value.data = rst.data;
  }
};

const GetMyMemberShip = async () => {
  let rst = await memberRep.GetMyMemberShip();
  if (rst.check && rst.data !== null) {
    pageinfo.value.myMemberShip = rst.data;
    pageinfo.value.permission.Aceept(pageinfo.value.myMemberShip.memberShip);
    if (!pageinfo.value.permission.Analysis) {
      MessageBox.Alert(t('common.nopermission'), () => {
        router.push('/account/membership?tab=membership');
      });
    }
  } else {
    MessageBox.Alert(t('common.requiredmembership'), () => {
      router.push('/account/membership?tab=membership');
    });
  }
};

const renderCharts = () => {
  // 기존 차트를 삭제
  charts.value.forEach(chart => chart.destroy());
  charts.value = [];

  pageinfo.value.data.data.forEach((item, index) => {
    const ctx = document.getElementById('chart-' + index) as HTMLCanvasElement;
    if (ctx) {
      const chart = new Chart<'bar', number[], string>(ctx, {
        type: 'bar',
        data: {
          labels: item.keyword.map(k => k.key),
          datasets: [
            {
              label: t('Keyword Points'),
              data: item.keyword.map(k => k.point),
              backgroundColor: 'rgba(75, 192, 192, 0.2)',
              borderColor: 'rgba(75, 192, 192, 1)',
              borderWidth: 1
            }
          ]
        },
        options: {
          responsive: true,
          scales: {
            y: {
              beginAtZero: true
            }
          },
          plugins: {
            legend: {
              display: false
            }
          }
        }
      });

      charts.value.push(chart); // 차트 배열에 추가
    }
  });
};
</script>
