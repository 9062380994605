import { Condition } from "./Condition";

export class MemberShip {
    public id:string;
    public title:string;
    public price:number;
    public unit:string;
    public isFree:boolean;
    public grade:number;
    public condition:Condition;

    constructor() {
        this.id = "";
        this.title = "";
        this.price = 0;
        this.unit = "";
        this.isFree = true;
        this.grade = 0;
        this.condition = new Condition();
    }
}

