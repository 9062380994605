<template>
  <Loading :loading="pageinfo.isloading" />
  <div
    class="bg-gray-50 flex flex-col items-center justify-center"
    :key="pageinfo.key"
    v-show="pageinfo.isReady"
  >
    <div class="bg-white shadow-lg rounded-lg p-6 w-full max-w-md text-center">
      <h2 class="text-2xl font-semibold text-gray-800 mb-4">{{ t('common.PayResult') }}</h2>

      <div v-if="pageinfo.history.resultCode === '0000'" class="text-green-500 text-lg font-semibold">
        <div class="flex items-center justify-center mb-4">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
            <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
          </svg>
        </div>
        {{ t('common.PaymentSuccess') }}
      </div>

      <div v-else class="text-red-500 text-lg font-semibold">
        <div class="flex items-center justify-center mb-4">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12 text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </div>
        {{ t('common.PaymentFailed') }} <br />
        {{ t('common.FailureReason') }}: {{ pageinfo.history.resultMsg }}
      </div>

      <div v-if="pageinfo.history.resultCode === '0000'" class="mt-6">
        <h3 class="text-lg font-medium text-gray-700 mb-2">{{ t('common.PaymentInformation') }}</h3>
        <p class="text-gray-600"><strong>{{ t('common.ProductName') }}:</strong> {{ pageinfo.history.goodsName }}</p>
        <p class="text-gray-600"><strong>{{ t('common.PaymentAmount') }}:</strong> ￦{{ StringHelper.formatNumberWithCommas(pageinfo.history.amount) }}</p>
        <p class="text-gray-600"><strong>{{ t('common.PaymentDate') }}:</strong> {{ dayjs(pageinfo.history.paidAt).format('YYYY-MM-DD HH:mm:ss') }}</p>
      </div>

      <button
        @click="router.push('/main')"
        class="mt-6 w-full bg-blue-600 text-white font-semibold py-3 rounded-md shadow-md hover:bg-blue-700 transition"
      >
        {{ t('common.BackHome') }}
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { NotifyRepository, MemberRepository } from '@/repositories';
import { PaymentHistory } from '@/entities';
import { MessageBox, ValidateHelper,StringHelper } from '@/utility';
import { Loading } from '@/components';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import localizedFormat from 'dayjs/plugin/localizedFormat'; 
import { useI18n } from 'vue-i18n';

dayjs.extend(utc);
dayjs.extend(localizedFormat);
const route = useRoute();
const router = useRouter();
const notifyRep = new NotifyRepository();
const memberRep = new MemberRepository();
const validate = new ValidateHelper();
const { t, locale } = useI18n();

var pageinfo = ref({
  key: 0,
  isReady: false,
  productCode: '',
  couponNo: '',
  isloading: true,
  history:new PaymentHistory()
});

onMounted(async () => {
  // 결제 승인 페이지에서 전달된 데이터 파싱
  const queryParams = new URLSearchParams(window.location.search);
  pageinfo.value.history.id = String(queryParams.get('id'));
  pageinfo.value.history.resultCode = String(queryParams.get('resultCode'));
  pageinfo.value.history.resultMsg = decodeURIComponent(String(queryParams.get('resultMsg')));
  pageinfo.value.history.orderId = String(queryParams.get('orderId'));
  pageinfo.value.history.status = String(queryParams.get('status'));
  pageinfo.value.history.paidAt = dayjs.utc(decodeURIComponent(String(queryParams.get('paidAt')))).local().toDate();
  pageinfo.value.history.amount = Number(queryParams.get('amount'));
  pageinfo.value.history.goodsName = decodeURIComponent(String(queryParams.get('goodsName')));

  pageinfo.value.isReady = true;
  pageinfo.value.isloading = false;
});

</script>
