<template>
  <div>
    <a
      class="text-blueGray-500 block"
      href="#pablo"
      ref="btnDropdownRef"
      @click="toggleDropdown"
    >
      <div class="items-center flex">
        <span class="w-12 h-12 text-sm text-white mainbg inline-flex items-center justify-center rounded-full overflow-hidden">
          <ProfilePhoto :url="componentInfo.user.photo" :email="componentInfo.user.email" :width="48" :height="48" />
        </span>
      </div>
    </a>
    <div
      ref="popoverDropdownRef"
      class="bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"
      :class="{ hidden: !dropdownPopoverShow, block: dropdownPopoverShow }"
    >
      <button 
        @click="fnNav('/account/profile')"
        class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700 text-left"
      >
      {{ t("common.profile") }}
      </button>
      <button
        @click="fnNav('/account/password')"
        class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700 text-left"
      >
      {{ t("common.change_password") }}
      </button>
      <button
        @click="fnNav('/account/membership')"
        class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700 text-left"
      >
      {{ t("common.membership") }}
      </button>
      <button
        @click="fnNav('/account/settings')"
        class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700 text-left"
      >
      {{ t("common.settings") }}
      </button>
      <div class="h-0 my-2 border border-solid border-blueGray-100" />
      <button
        type="button" @click="fnLogout()"
        class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700 text-left"
      >
      {{ t("system.logout") }}
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted,computed } from 'vue';
import { createPopper } from "@popperjs/core";
import { AuthService } from '@/services';
import { MessageBox } from '@/utility';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { ProfilePhoto } from '../User';
import { Member } from '@/entities';
import { MemberRepository } from '@/repositories';

const auth = new AuthService();
const router = useRouter();
const { t, locale } = useI18n();
const dropdownPopoverShow = ref(false);
const btnDropdownRef = ref<HTMLElement | null>(null);
const popoverDropdownRef = ref<HTMLElement | null>(null);
const memberRep = new MemberRepository();

var componentInfo = ref({
  user:new Member()
});

onMounted(async () => {
  let rst = await memberRep.GetUser();
  if (rst.check && rst.data !== null) {
    componentInfo.value.user = rst.data;
  }
});

const toggleDropdown = (event: Event) => {
  event.preventDefault();
  dropdownPopoverShow.value = !dropdownPopoverShow.value;
  if (dropdownPopoverShow.value && btnDropdownRef.value && popoverDropdownRef.value) {
    createPopper(btnDropdownRef.value, popoverDropdownRef.value, {
      placement: "bottom-start",
    });
  }
};

const fnLogout = () => {
  MessageBox.Confirm(t('system.logout_ment'), () => {
    auth.logout();
  });

  dropdownPopoverShow.value = false;
};

const fnNav = (url:string) => {
  dropdownPopoverShow.value = false;
  router.push(url);
}
</script>
