<template>
  <!-- Navbar -->
  <nav
    class="absolute top-0 left-0 w-full z-10 bg-transparent md:flex-row md:flex-nowrap md:justify-start flex items-center p-4"
  >
    <div
      class="w-full mx-auto items-center flex justify-between md:flex-nowrap flex-wrap md:px-10 px-4"
    >
      <!-- Brand -->
      <div class="md:hidden"></div>
      <!-- Form -->
      <form
        class="md:flex hidden flex-row flex-wrap items-center lg:flex mr-3"
        @submit.prevent="handleSubmit"
      >
        <div class="relative flex w-full flex-wrap items-stretch">
          <span
            class="z-10 h-full leading-snug font-normal absolute text-center text-blueGray-300 absolute bg-transparent rounded text-base items-center justify-center w-8 pl-3 py-3"
          >
            <i class="fas fa-search"></i>
          </span>
          <input
            type="text"
            placeholder="Search here..."
            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm shadow outline-none focus:outline-none focus:ring w-full pl-10"
            v-model="searchQuery"
            @keydown.enter="handleSubmit"
          />
        </div>
      </form>
      <ul class="flex-col md:flex-row list-none items-center hidden md:flex">
        <li class="inline-block relative">
          <LanguageDropdown />
        </li>
        <li class="inline-block relative">
          <NotificationDropdown />
        </li>
        <li class="inline-block relative">
          <UserDropdown />
        </li>
      </ul>
    </div>
  </nav>
  <!-- End Navbar -->
</template>

<script setup lang="ts">
import { ref } from "vue";
import { UserDropdown, NotificationDropdown, LanguageDropdown } from '../Dropdowns';
import { useRouter } from 'vue-router';

const searchQuery = ref('');
const router = useRouter();

const handleSubmit = () => {
  if (searchQuery.value.trim().length > 0) {
    router.push({ path: '/search', query: { q: searchQuery.value } });
  }
};
</script>
