<template>
  <div v-if="isPopupVisible" class="absolute flex flex-col min-w-0 break-words bg-white rounded mb-6 xl:mb-0 shadow-lg popup cursor">
    <img src="/images/banner/free_coupon_240822.png" alt="" @click="fnCouponLink" />
    <div class="checkbox-container">
      <label>
        <input type="checkbox" v-model="doNotShowToday" />
        오늘 하루 열지 않기
      </label>
      <button @click="closePopup">CLOSE</button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';
import router from '@/router';
import { AuthService } from '@/services';
import { MessageBox, ValidateHelper } from '@/utility';

const auth = new AuthService();
const validate = new ValidateHelper();
const isPopupVisible = ref(true);
const doNotShowToday = ref(false);

const fnCouponLink = async () => {
  try {
    let user = await auth.getUser();
    if (!validate.IsNullOrWhiteSpace(user?.access_token)) {
      router.push(`/account/membership?tab=membership`);
    } else {
      MessageBox.Confirm("로그인 후 이용가능합니다. 로그인 페이지로 이동하시겠습니까?", () => {
        router.push(`/login`);
      });
    }
  } catch (e: any) {
    MessageBox.Alert(e.message);
  }
};

const closePopup = () => {
  if (doNotShowToday.value) {
    const today = new Date().toISOString().split('T')[0]; // yyyy-mm-dd 형식의 날짜
    localStorage.setItem('popupClosedToday', today);
  }
  isPopupVisible.value = false;
};

onMounted(() => {
  const today = new Date().toISOString().split('T')[0];
  const storedDate = localStorage.getItem('popupClosedToday');
  if (storedDate === today) {
    isPopupVisible.value = false;
  }
});
</script>

<style scoped>
.popup {
  position: absolute;
  width: 80%;
  height: auto;
  box-sizing: border-box;
  overflow: hidden;
}

@media (max-width: 768px) {
  .popup {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid #919191;
    max-width: 400px;
    max-height: 560px;
  }
}

@media (min-width: 1024px) {
  .popup {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid #919191;
    max-width: 400px;
    max-height: 560px;
  }
}

.popup img {
  max-width: 100%;
  height: auto;
  display: block;
  box-sizing: border-box;
}

.checkbox-container {
  display:flex;
  justify-content:space-between;
  margin: 2px 0;
  text-align: center;
}

.checkbox-container label {
  font-size: 0.8rem;
  color: #333;
  margin-left:5px;
}

.checkbox-container button {
  margin-right: 5px;
  padding: 2px 10px;
  font-size:0.8rem;
  background-color: #000;
  color:#FFF;
  border: none;
  border-radius: 3px;
  cursor: pointer;

}
</style>
