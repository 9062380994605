import { Condition } from "./Condition";

export class FormSetup {
    public id:string;
    public font:string;
    public fontColor:string;
    public bgColor:string;
    public size:number;
    public lineHeight:number;
    public condition:Condition;

    constructor() {
        this.id = "";
        this.font = "";
        this.fontColor = "";
        this.bgColor = "";
        this.size = 0;
        this.lineHeight = 0;
        this.condition = new Condition();
    }
}