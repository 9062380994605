import { Article } from "./Article";
import { Condition } from "./Condition";

export class Chapter {
    public id:string;
    public title:string;
    public description:string;
    public bookId:string;
    public seq:number;
    public articles:Article[];
    public condition:Condition;

    constructor() {
        this.id = "";
        this.title = "";
        this.description = "";
        this.bookId = "";
        this.seq = 0;
        this.articles = [] as Article[];
        this.condition = new Condition();
    }
}