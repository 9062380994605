<template>
  <div class="w-full flex justify-between mb-1">
    <div class="ml-2">
      <h3 class="text-2xl subcolor uppercase"><b>{{ t('site.MyReviews') }}</b></h3>
    </div>
    <div class="text-sm mr-2">
      <router-link to="/main" class="text-gray-400 uppercase text-sm"><small>HOME</small></router-link>
      <span class="px-2 text-gray-300">&gt;</span>
      <a href="#" class="text-gray-500 uppercase text-md"><b>{{ t('site.MyReviews') }}</b></a>
    </div>
  </div>
  <div :key="pageinfo.key"
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white">
    <div class="block w-full overflow-x-auto">
      <table class="items-center w-full bg-transparent border-collapse">
        <thead>
          <tr>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="'bg-blueGray-50 text-blueGray-500 border-blueGray-100'"
            >
              {{ t('common.title') }}
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="'bg-blueGray-50 text-blueGray-500 border-blueGray-100'"
            >
              {{ t('common.Type') }}
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="'bg-blueGray-50 text-blueGray-500 border-blueGray-100'"
            >
              {{ t('common.RegistDate') }}
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="'bg-blueGray-50 text-blueGray-500 border-blueGray-100'"
            ></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(review,index) in pageinfo.reviews" :key="`list_${index}`">
            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
              <a href="#"  @click="fnView(review)" class="ml-3 font-bold light">
                {{ review.title }}
              </a>
            </td>
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
            >
              {{ review.targetType }}
            </td>
            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
              {{ dayjs.utc(review.condition.registDate).local().format('YYYY-MM-DD HH:mm') }}
            </td>
            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right">
              <TableDropdown>
                <button type="button" @click="fnMemoEdit(review.id)" class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700 text-left">
                  {{ t('site.EditMemo') }}
                </button>
                <button type="button" @click="fnMemoErase(review.id)" class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700 text-left">
                  {{ t('site.DeleteMemo') }}
                </button>
              </TableDropdown>
            </td>
          </tr>

        </tbody>
      </table>
    </div>
    <hr/>
    <div class="py-2 flex justify-center items-center">
        <Pagination :total-count="pageinfo.totalcount" :cur-page="pageinfo.curpage" :callback="fnPaging" />
    </div>
    <div class="py-2 flex justify-between px-2">
      <div>
        <button @click="goReviews" class="px-4 py-2 uppercase mainbg text-white font-semibold rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-opacity-50" type="button">{{ t('site.ReviewWrite') }}</button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { Pagination,TableDropdown } from '@/components';
  import { onMounted, ref } from 'vue';
  import { ArticleRepository } from '@/repositories';
  import { Review } from '@/entities';
  import { MessageBox } from '@/utility';
  import { useRouter } from 'vue-router';
  import dayjs from 'dayjs';
  import utc from 'dayjs/plugin/utc';
  import localizedFormat from 'dayjs/plugin/localizedFormat'; 
  import { useI18n } from 'vue-i18n';

  dayjs.extend(utc);
  dayjs.extend(localizedFormat);

  const articleRep = new ArticleRepository();
  const router = useRouter();
  const { t, locale } = useI18n();

  var pageinfo = ref({
    key:0,
    reviews:[] as Review[],
    totalcount:0,
    curpage:1
  });

  onMounted(async() => {
    await fnPaging(1);
  });

  const fnPaging = async (curpage:number) => {
    pageinfo.value.curpage = curpage;
    let rst = await articleRep.GetMyReviews({ curPage : pageinfo.value.curpage, pageSize : 10 });
    if (rst.check) {
      pageinfo.value.reviews = rst.data;
    }
  };

  const goReviews = () => {
    router.push(`/review/list`);
  };

  const fnView = (review:Review) => {
    router.push(`/book/${review.targetId}/review/${review.id}`)
  }

  const fnMemoEdit = (memoId:string|null) => {
    router.push(`/memo/form/${memoId}`);
  };

  const fnMemoErase = (memoId:string) => {
    MessageBox.Confirm(t('system.RemoveConfirm'), async () => {
      let rst = await articleRep.DeleteMemo(memoId);
      if (rst.check) {
        await fnPaging(1);
        pageinfo.value.key += 1;
      } else {
        MessageBox.Alert(rst.message);
      }
    });

  };
</script>