<template>
  <div :key="notifyKey">
    <div class="items-center flex mr-2">
      <a
        class="w-10 h-10 text-sm text-white bg-blueGray-200 inline-flex items-center justify-center rounded-full"
        style="cursor:pointer"
        ref="btnDropdownRef"
        @click="toggleDropdown"
      >
        <i class="fas fa-bell maincolor"></i>
      </a>
    </div>

    <div
      ref="popoverDropdownRef"
      :class="{
        'hidden': !dropdownPopoverShow,
        'block': dropdownPopoverShow,
      }"
      class="bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"
    >
      <div v-if="messages !== null && messages!== undefined && messages.length > 0">
        <a 
          v-for="(msg, index) in messages" 
          :key="`msg_list_${index}`"
          class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-gray-700 border-b border-gray-100"
        >
          {{ msg }}
        </a>
      </div>
      <div v-else>
        <a class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-gray-700">메시지가 없습니다.</a>
      </div>
      <a v-if="messages !== null && messages!== undefined && messages.length > 0"
        href="#"
        @click="fnClear"
        class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
      >
        Clear All Notifications
      </a>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch } from 'vue';
import { createPopper, Instance } from '@popperjs/core';
import { useStore } from 'vuex';

const store = useStore();
const messages = computed(() => store.getters['messages/getMessages']); // Corrected getter path
const btnDropdownRef = ref<HTMLElement | null>(null);
const popoverDropdownRef = ref<HTMLElement | null>(null);
const dropdownPopoverShow = ref(false);
const notifyKey = ref(0);
let popperInstance: Instance | null = null;

const toggleDropdown = (event: MouseEvent) => {
  event.preventDefault();
  dropdownPopoverShow.value = !dropdownPopoverShow.value;

  if (dropdownPopoverShow.value) {
    popperInstance = createPopper(btnDropdownRef.value!, popoverDropdownRef.value!, {
      placement: 'bottom-start',
    });
  } else if (popperInstance) {
    popperInstance.destroy();
    popperInstance = null;
  }
};

// Watch for changes in messages and update notifyKey to force re-render
watch(messages, () => {
  console.log('messages : ', messages)
  notifyKey.value++;
});

// Clear all messages
const fnClear = () => {
  store.dispatch('messages/clearMessages'); // Corrected action path
  dropdownPopoverShow.value = false;
};
</script>
