<template>
    <Loading :loading="pageinfo.isLoading" />

    <div class="w-full flex justify-between mb-1">
    <div class="ml-2">
      <h3 class="text-2xl subcolor uppercase"><b>{{ t('site.Reviews') }}</b></h3>
    </div>
    <div class="text-sm mr-2">
      <router-link to="/main" class="text-gray-400 uppercase text-sm"><small>HOME</small></router-link>
      <span class="px-2 text-gray-300">&gt;</span>
      <router-link :to="`/book/articles/${pageinfo.book.id}`" class="text-gray-400 uppercase text-sm"><small>{{ t('site.Books') }}</small></router-link>
      <span class="px-2 text-gray-300">&gt;</span>
      <a href="#" class="text-gray-500 uppercase text-md"><b>{{ t('site.Reviews') }}</b></a>
    </div>
  </div>
    
    <div :key="pageinfo.key">
        <Bookinfo :book="pageinfo.book" />
    <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white mt-4">

    <div class="block w-full overflow-x-auto">
    <table class="items-center w-full bg-transparent border-collapse">
        <thead>
        <tr>
            <th
            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
            :class="'bg-blueGray-50 text-blueGray-500 border-blueGray-100'"
            >
            Title
            </th>
            <th
            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
            :class="'bg-blueGray-50 text-blueGray-500 border-blueGray-100'"
            >
            RegistDate
            </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(reivew,index) in pageinfo.reviews" :key="`article_list_${index}`">
            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
            <a href="#" @click="fnView(reivew.id)" class="ml-3 font-bold light">{{ reivew.title }}</a>
            </td>
            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
            {{ dayjs.utc(reivew.condition.registDate).local().format('YYYY-MM-DD HH:mm') }}
            </td>
        </tr>

        </tbody>
    </table>
    </div>
    <hr/>
    <div class="py-2 flex justify-center items-center">
        <Pagination :total-count="pageinfo.totalcount" :cur-page="pageinfo.curpage" :callback="GetReviews" />
    </div>
    <div class="p-4">
      <div class="w-full flex justify-between">
        <div>
          <button v-if="pageinfo.userinfo.id !== pageinfo.book.ownerId" @click="fnCreateBook" class="px-4 py-2 uppercase mainbg text-white font-semibold rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-opacity-50" type="button">{{ t('site.ReviewWrite') }}</button>
        </div>
        <div>
          <button @click="fnBookList" class="px-4 py-2 uppercase bg-blue-500 text-white font-semibold rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-opacity-50" type="button">{{ t('site.Books') }}</button>
        </div>
      </div>
    </div>
</div>
</div>
</template>

<script setup lang="ts">
import { Pagination,Loading,Bookinfo } from '@/components';
import { onMounted, ref, defineProps } from 'vue';
import { ArticleRepository,MemberRepository } from '@/repositories';
import { Book, Chapter, Member, Review } from '@/entities';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import localizedFormat from 'dayjs/plugin/localizedFormat'; 

dayjs.extend(utc);
dayjs.extend(localizedFormat);

const articleRep = new ArticleRepository();
const router = useRouter();
const { t, locale } = useI18n();
const memberRep = new MemberRepository();

interface bookConfigProperty {
    bookid?:string|null|undefined
}

const property = defineProps<bookConfigProperty>();

var pageinfo = ref({
    key:0,
    isLoading:true,
    book:new Book(),
    userinfo:new Member(),
    chapters:[] as Chapter[],
    reviews:[] as Review[],
    totalcount:0,
    curpage:1
});

onMounted(async() => {
    await getUser();
    await GetChapters();
    await GetBookInfo();
    await GetReviews(1);
    pageinfo.value.isLoading = false;
    pageinfo.value.key += 1;
});

const getUser = async () => {
    let rst = await memberRep.GetUser();
    if (rst.check && rst.data !== null) {
      pageinfo.value.userinfo = rst.data;
    }
  };

  const GetBookInfo = async () => {
    if (property.bookid !== null && property.bookid !== undefined && property.bookid.trim() !== '') {
      let rst = await articleRep.GetBook(property.bookid);
      if (rst.check) {
        pageinfo.value.book = rst.data;
        pageinfo.value.key += 1;
      }
    }
  };

  const GetChapters = async () => {
    if (property.bookid !== null && property.bookid !== undefined && property.bookid.trim() !== '') {
      let rst = await articleRep.GetChapters(property.bookid);
      if (rst.check) {
        pageinfo.value.chapters = rst.data;
        pageinfo.value.key += 1;
      }
    }
  };

  const GetReviews = async (curpage:number) => {
    if (property.bookid !== null && property.bookid !== undefined && property.bookid.trim() !== '') {
      let rst = await articleRep.GetBookReviews(property.bookid, { curPage : curpage, pageSize : 10 });
      if (rst.check) {
        pageinfo.value.curpage = curpage;
        pageinfo.value.reviews = rst.data;
        pageinfo.value.key += 1;
      }
    }
  };

const fnCreateBook = () => {
    router.push(`/review/book/form/${pageinfo.value.book.id}`);
};

const fnView = (reviewId:string) => {
    router.push(`/book/${property.bookid}/review/${reviewId}`);
};

const fnBookList = () => {
  router.push(`/book/articles/${pageinfo.value.book.id}`);
};

</script>