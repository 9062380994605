export class MainStats {
    public articles:number;
    public writers:number;
    public reviews:number;
    public likes:number;

    constructor() {
        this.articles = 0;
        this.writers = 0;
        this.reviews = 0;
        this.likes = 0;
    }
}