<template>
  <div class="w-full flex justify-between mb-1">
    <div class="ml-2">
      <h3 class="text-2xl subcolor uppercase"><b>Search</b></h3>
    </div>
    <div class="text-sm mr-2">
      <router-link to="/main" class="text-gray-400 uppercase text-sm"><small>HOME</small></router-link>
      <span class="px-2 text-gray-300">&gt;</span>
      <a href="#" class="text-gray-500 uppercase text-md"><b>OpenBooks</b></a>
    </div>
  </div>
  <div :key="pageinfo.key"
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white">
    <div class="block w-full overflow-x-auto">
      <table class="items-center w-full bg-transparent border-collapse">
        <thead>
          <tr>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="'bg-blueGray-50 text-blueGray-500 border-blueGray-100'"
            >
              {{ t('site.book_table_bookname') }}
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="'bg-blueGray-50 text-blueGray-500 border-blueGray-100'"
            >
            분류
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="'bg-blueGray-50 text-blueGray-500 border-blueGray-100'"
            >
            작가
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="'bg-blueGray-50 text-blueGray-500 border-blueGray-100'"
            >
            작성일시
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item,index) in pageinfo.list" :key="`list_${index}`">
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center"
            >
              <img
                :src="(item.thumbnail !== null && item.thumbnail !== undefined && String(item.thumbnail).trim() !== '') ? item.thumbnail : 'https://via.placeholder.com/48x48'"
                class="h-12 w-12 bg-white rounded-full border"
                alt="..."
                @click="fnView(item)"
              />
              <a href="#" @click="fnView(item)"
                class="ml-3 font-bold light"
              >
                {{ item.title }}
              </a>
            </td>
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
            >
              {{ item.type }}
            </td>
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
            >
              <WriterName :writer-id="item.writerId" />
            </td>
            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
              {{ dayjs.utc(item.registDate).local().format('YYYY-MM-DD HH:mm') }}
            </td>
          </tr>

        </tbody>
      </table>
    </div>
    <hr/>
    <div class="py-2 flex justify-center items-center">
        <Pagination :total-count="pageinfo.totalcount" :cur-page="pageinfo.curpage" :callback="fnPaging" />
    </div>
  </div>
</template>

<script setup lang="ts">
  import { Pagination,TableDropdown } from '@/components';
  import { onMounted, ref } from 'vue';
  import { ArticleRepository } from '@/repositories';
  import store from '@/store';
  import { Book, Categories, SearchResult } from '@/entities';
  import { MessageBox } from '@/utility';
  import { useRouter, useRoute } from 'vue-router';
  import { useI18n } from 'vue-i18n';
  import dayjs from 'dayjs';
  import utc from 'dayjs/plugin/utc';
  import localizedFormat from 'dayjs/plugin/localizedFormat'; 
  import WriterName from '@/components/Atom/WriterName.vue';
  import { ValidateHelper } from '@/utility';

  dayjs.extend(utc);
  dayjs.extend(localizedFormat);
  const articleRep = new ArticleRepository();
  const router = useRouter();
  const route = useRoute();
  const { t, locale } = useI18n();
  const validate = new ValidateHelper();

  var pageinfo = ref({
    key:0,
    list:[] as SearchResult[],
    totalcount:0,
    curpage:1,
    keyword : "" as string|null|undefined
  });

  onMounted(async() => {
    pageinfo.value.keyword = route.query.q as string || '';
    await fnPaging(pageinfo.value.keyword, 1);
    pageinfo.value.key += 1;
  });

  const fnPaging = async (keyword:string, curpage:number) => {
    let rst = await articleRep.GetSearch(keyword, { curPage : curpage, pageSize : 10 });
    if (rst.check) {
      pageinfo.value.list = rst.data;
      pageinfo.value.curpage = curpage;
    }
  };

  const fnView = (item:SearchResult) => {
    if (item.type.trim().toLowerCase() === "book") {
      router.push(`/book/publish/${item.id}`);
    }
  };
</script>