<template>
    <Loading :loading="pageinfo.isloading" />
  <div class="min-h-screen bg-gray-50 flex flex-col items-center" :key="pageinfo.key" v-show="pageinfo.isReady">
    <!-- 상품 정보 -->
    <div class="bg-white shadow-lg rounded-lg p-6 mt-8 w-full max-w-md">
      <h2 class="text-2xl font-semibold text-gray-800">{{ pageinfo.membership.title }} Membership</h2>
      <p v-if="pageinfo.membership.title === 'Basic'" class="text-gray-600 mt-2">상품 설명: AI를 제외한 거의 모든 기능을 사용할 수 있습니다.  특별히 AI가 필요하신게 아니라면, 이것으로 충분합니다.</p>
      <p v-if="pageinfo.membership.title === 'Premium'" class="text-gray-600 mt-2">상품 설명: 자신이 가진 공개된 AI키(Gemini, GPT)를 이용하실 수 있습니다.  하지만, RAG등 최적화된 기능은 사용할 수 없습니다.</p>
      <p v-if="pageinfo.membership.title === 'Professional'" class="text-gray-600 mt-2">상품 설명: 당사에서 제공하는 AI를 사용해 RAG등 최신 기술을 이용할 수 있습니다.</p>
    </div>

    <!-- 구독 결제 안내 -->
    <div class="bg-white shadow-lg rounded-lg p-6 mt-6 w-full max-w-md" v-if="pageinfo.membership.isFree">
      <h3 class="text-lg font-semibold text-gray-700">무료 이용 안내</h3>
      <p class="text-gray-600 mt-2">
        무료 이용은 자동결제가 지원되지 않으며, 기간이 종료되면 직접 갱신해야 합니다.
      </p>
    </div>
    <div class="bg-white shadow-lg rounded-lg p-6 mt-6 w-full max-w-md" v-else>
      <h3 class="text-lg font-semibold text-gray-700">구독 결제 안내</h3>
      <p class="text-gray-600 mt-2">
        구독 결제는 매월 자동으로 결제되며, 언제든지 해지할 수 있습니다. 구독 해지 시 환불이
        불가능할 수 있습니다.
      </p>
      <a href="#" class="text-sm text-blue-600 hover:underline mt-2 inline-block">
        환불 및 구독 해지에 관한 안내
      </a>
      <a href="#" class="text-sm text-blue-600 hover:underline mt-1 inline-block">
        법적 고지사항
      </a>
    </div>

    <!-- 결제 정보 -->
    <div class="bg-white shadow-lg rounded-lg p-6 mt-6 w-full max-w-md">
      <h4 class="text-xl font-semibold text-gray-800">결제 금액</h4>
      <p class="text-3xl font-bold text-red-500 mt-2">{{ StringHelper.formatNumberWithCommas(pageinfo.membership.price) }} 원 <small>(부가세 포함)</small></p>

      <div class="mt-4">
        <label for="couponNo" class="block text-sm font-medium text-gray-700">쿠폰번호입력</label>
        <input
          v-model="pageinfo.couponNo"
          :disabled="!pageinfo.iscoupon"
          type="text"
          id="couponNo"
          class="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
          placeholder=""
        />
        <input type="checkbox" class="text-mini border-gray-300 mr-2 focus:outline-none" :disabled="!pageinfo.isauto" v-model="pageinfo.isautocheck" /><label class="text-mini text-gray-500">자동연장결제</label>
      </div>

      <button
        @click="fnPayment"
        class="mt-6 w-full bg-blue-600 text-white font-semibold py-3 rounded-md shadow-md hover:bg-blue-700 transition"
      >
        결제하기
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ref, onMounted } from 'vue';
  import { useRoute,useRouter } from 'vue-router';
  import { NotifyRepository,MemberRepository } from '@/repositories';
  import { MemberShip, Order } from '@/entities';
  import { MessageBox, StringHelper,ValidateHelper } from '@/utility';
  import config from '@/config';
  import { Loading } from '@/components';
  
  const route = useRoute();
  const router = useRouter();
  const notifyRep = new NotifyRepository();
  const memberRep = new MemberRepository();
  const validate = new ValidateHelper();

  var pageinfo = ref({
    key:0,
    isReady:false,
    productCode:'',
    couponNo:'',
    isloading:true,
    membership:new MemberShip(),
    order:null as null|Order,
    isauto:true,
    isautocheck:true,
    iscoupon:true
  });
  
  onMounted(async () => {
    pageinfo.value.productCode = route.query.productCode as string;
    await getProdcut(pageinfo.value.productCode);
    await ExternalScript();
    pageinfo.value.isReady = true;
    pageinfo.value.isloading = false;
  });
  
  const getProdcut = async (productCode:string) => {
    let rst = await memberRep.GetProduct(productCode);
    if (rst.check && rst.data !== null) {
      pageinfo.value.membership = rst.data;
      if (pageinfo.value.membership.title === "Premium") {
        pageinfo.value.isautocheck = false;
        pageinfo.value.couponNo = 'OP24-08AG-D22P-0511'; //기간한정
      } 
      
      if (pageinfo.value.membership.isFree) {
        pageinfo.value.isauto = false;
        pageinfo.value.isautocheck = false;
        pageinfo.value.iscoupon = false;
      }
    }
  };

  const registOrder = async () => {
    let rst = await memberRep.PostOrderNo(pageinfo.value.membership.id);
    if (rst.check && rst.data !== null) {
      pageinfo.value.order = rst.data;
    }
  }

  const ExternalScript = async () => {
    const script = document.createElement('script');
    script.src = 'https://pay.nicepay.co.kr/v1/js/';
    script.async = true;
    document.body.appendChild(script);
  };

  const fnPayment = async () => {
    if (pageinfo.value.membership.isFree) {
      let free = await memberRep.PostFreePaymentRegist(pageinfo.value.membership.id);
      if (free.check) {
        MessageBox.Success('이용권을 획득하였습니다.', () => {
          router.push(`/account/membership`);
        });
      } else {
        MessageBox.Alert(free.message);
      }
    } else if (!validate.IsNullOrWhiteSpace(pageinfo.value.couponNo)) {
      let coupon = await memberRep.PostCouponRegist(pageinfo.value.couponNo);
      if (coupon.check) {
        MessageBox.Success('쿠폰 이용이 완료되었습니다.', () => {
          router.push(`/account/membership`);
        });
      } else {
        MessageBox.Alert(coupon.message);
      }
    } else {
      await registOrder();
      if (pageinfo.value.order !== null && pageinfo.value.order !== undefined && String(pageinfo.value.order.orderNo).trim() !== '') {
        if (pageinfo.value.isautocheck) {
          router.push(`/account/card/regist?orderno=${pageinfo.value.order.orderNo}`);
        } else {
          MessageBox.Confirm("자동연장결제를 해제하면, 만료일에 직접 연장결제를 하셔야 합니다. 이대로 진행하시겠습니까?", () => {
            fnNicePayCall();
          });
        }
      } else {
        MessageBox.Alert('주문요청이 등록되지 않았습니다.  페이지를 새로고침 해주세요.');
      }
    }
  };


  const fnNicePayCall = () => {
    const AUTHNICE = (window as any).AUTHNICE;
  
    if (!AUTHNICE) {
      alert('Nicepay 라이브러리가 로드되지 않았습니다.');
      return;
    }

    AUTHNICE.requestPay({
      clientId: config.nicepay.clientId,
      method: 'card',
      vbankHolder: '',
      orderId: pageinfo.value.order?.orderNo,
      amount: pageinfo.value.membership.price,
      goodsName: pageinfo.value.membership.title,
      returnUrl: `${config.apis.member}/api/Nicepay/Complete`,
      fnError: async (result: any) => {
        MessageBox.Alert(result.errorMsg);
        await notifyRep.PostGithubIssue('[Payment Fail] Payment.vue Exception', `##Description \r\n result.errorMsg`, 'bug');
      },
    });
  }
</script>

<style scoped>
body {
  @apply bg-gray-50;
}
</style>
  