import config from "@/config";
import { ReturnValues } from "@/models";
import { ApiHelper } from "@/utility"

export class CopilotRepository {
    async BookTalk(bookid:string, message:string):Promise<ReturnValues> {
        let url = `${config.apis.copilot}/book/${bookid}/messages`;
        //console.log('CopilotRepository BookTalk : ', url);
        let jsonData = {
            input: message,
            variables: []
        };
        return await ApiHelper.PostAsync(url, jsonData);
    }

    async UserTalk(message:string):Promise<ReturnValues> {
        let url = `${config.apis.copilot}/user/messages`;
        //console.log('CopilotRepository UserTalk : ', url);
        let jsonData = {
            input: message,
            variables: []
        };
        return await ApiHelper.PostAsync(url, jsonData);
    }

    async PostAgentIntroduction(bookid:string,body:string):Promise<ReturnValues> {
        let url = `${config.apis.copilot}/Agent/novelist/introduction/${bookid}`;
        //console.log('AiRepository PostAgentIntroduction : ', url);
        let jsonData = { input: encodeURIComponent(body) };
        return await ApiHelper.PostAsync(url, jsonData);
    }

    async PostAgentContinuation(bookid:string,body:string):Promise<ReturnValues> {
        let url = `${config.apis.copilot}/Agent/novelist/Continuation/${bookid}`;
        //console.log('AiRepository PostAgentContinuation : ', url);
        let jsonData = { input: encodeURIComponent(body) };
        return await ApiHelper.PostAsync(url, jsonData);
    }

    async PostAgentProsePolish(bookid:string,body:string):Promise<ReturnValues> {
        let url = `${config.apis.copilot}/Agent/novelist/ProsePolish/${bookid}`;
        //console.log('AiRepository PostAgentProsePolish : ', url);
        let jsonData = { input: encodeURIComponent(body) };
        return await ApiHelper.PostAsync(url, jsonData);
    }
    
    async PostAgentRecommend(bookid:string,body:string):Promise<ReturnValues> {
        let url = `${config.apis.copilot}/Agent/novelist/recommend/${bookid}`;
        //console.log('AiRepository PostAgentRecommend : ', url);
        let jsonData = { input: encodeURIComponent(body) };
        return await ApiHelper.PostAsync(url, jsonData);
    }

    async PostAgentCorrect(bookid:string,body:string):Promise<ReturnValues> {
        let url = `${config.apis.copilot}/Agent/editor/Correct/${bookid}`;
        //console.log('AiRepository PostAgentCorrect : ', url);
        let jsonData = { input: encodeURIComponent(body) };
        return await ApiHelper.PostAsync(url, jsonData);
    }
}
