class StatResponse {
    public ment:string;
    public data:PlatformData[];

    constructor() {
        this.ment = "";
        this.data = [] as PlatformData[];
    }
}

class PlatformData {
    public platform:string;
    public keyword:Keyword[];

    constructor() {
        this.platform = "";
        this.keyword = [] as Keyword[];
    }
}

class Keyword {
    public key:string;
    public point:number;

    constructor() {
        this.key = "";
        this.point = 0;
    }
}

export { StatResponse,PlatformData,Keyword }