export class PublicUser {
    id: string;
    background: string;
    bio: string;
    job: string;
    nickName: string;
    photo: string;
  
    constructor() {
      this.id = '';
      this.background = '';
      this.bio = '';
      this.job = '';
      this.nickName = '';
      this.photo = '';
    }
  }
  