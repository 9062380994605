<template>
  <nav
    class="top-0 absolute z-50 w-full flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg"
  >
    <div
      class="container px-4 mx-auto flex flex-wrap items-center justify-between"
    >
      <div
        class="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start"
      >
        <router-link
          class="text-white text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase"
          to="/"
        >
          GN2Studio
        </router-link>
        <button
          class="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
          type="button"
          v-on:click="setNavbarOpen()"
        >
          <i class="text-white fas fa-bars"></i>
        </button>
      </div>
      <div
        class="lg:flex flex-grow items-center bg-white lg:bg-opacity-0 lg:shadow-none"
        :class="(pageinfo.navbarOpen ? 'block rounded shadow-lg' : 'hidden')"
        id="example-navbar-warning"
      >
        <ul class="flex flex-col lg:flex-row list-none mr-auto">
          <li class="flex items-center">
            <router-link
              class="lg:text-white lg:hover:text-blueGray-200 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
              to="/"
            >
              <i
                class="lg:text-blueGray-200 text-blueGray-400 fas fa-home text-lg leading-lg mr-2"
              />
              Home
            </router-link>
          </li>

          <!-- Books -->
          <li class="flex items-center">
            <router-link
              class="lg:text-white lg:hover:text-blueGray-200 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
              to="/guide/youtube"
            >
              <i
                class="lg:text-blueGray-200 text-blueGray-400 fas fa-book text-lg leading-lg mr-2"
              />
              Guide
            </router-link>
          </li>

          <!-- Partners -->
          <li class="flex items-center">
            <router-link
              class="lg:text-white lg:hover:text-blueGray-200 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
              to="/Contact"
            >
              <i
                class="lg:text-blueGray-200 text-blueGray-400 fas fa-handshake text-lg leading-lg mr-2"
              />
              Contact
            </router-link>
          </li>
        </ul>

        <ul class="flex flex-col lg:flex-row list-none lg:ml-auto">
          <!-- Facebook -->
          <li class="flex items-center">
            <a
              class="text-white hover:text-gray-200 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
              :href="config.link.facebook"
              target="_blank"
            >
              <i
                class="fab fa-facebook-f text-lg leading-lg"
              />
          </a>
          </li>

          <!-- Blog -->
          <li class="flex items-center">
            <a
              class="text-white hover:text-gray-200 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
              :href="config.link.blog"
              target="_blank"
            >
              <i
                class="fas fa-rss text-lg leading-lg"
              />
          </a>
          </li>

          <!-- YouTube -->
          <li class="flex items-center">
            <a
              class="text-white hover:text-gray-200 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
              :href="config.link.youtube"
              target="_blank"
            >
              <i
                class="fab fa-youtube text-lg leading-lg"
              />
          </a>
          </li>
        </ul>

      </div>
    </div>
  </nav>
</template>


<script setup lang="ts">
import { ref } from 'vue';
import config from '@/config';

var pageinfo = ref({
  navbarOpen : false
});

const setNavbarOpen = () => {
  pageinfo.value.navbarOpen = !pageinfo.value.navbarOpen;
}
</script>
