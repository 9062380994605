<template>
<span :key="component_info.key">{{ component_info.writer.nickname }}</span>
</template>

<script setup lang="ts">
import { Writer } from '@/entities';
import { ArticleRepository } from '@/repositories';
import { onMounted, ref,defineProps } from 'vue';

const articleRep = new ArticleRepository();

interface bookConfigProperty {
    writerId:string
}

const property = defineProps<bookConfigProperty>();

var component_info = ref({
    key:0,
    writer:new Writer()
});

onMounted(async () => {
    let rst = await articleRep.GetWriter(property.writerId);
    if (rst.check) {
        component_info.value.writer = rst.data;
        component_info.value.key += 1;
    }
});

</script>