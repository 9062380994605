<template>
    <Loading :loading="pageinfo.isLoading" />
  
    <div class="w-full flex justify-between mb-1">
    <div class="ml-2">
      <h3 class="text-2xl subcolor uppercase"><b>{{t('site.RequestReviews')}}</b></h3>
    </div>
    <div class="text-sm mr-2">
      <router-link to="/main" class="text-gray-400 uppercase text-sm"><small>HOME</small></router-link>
      <span class="px-2 text-gray-300">&gt;</span>
      <a href="#" class="text-gray-500 uppercase text-md"><b>{{t('site.RequestReviews')}}</b></a>
    </div>
  </div>

    <div :key="pageinfo.key">
      <Bookinfo :book="pageinfo.book" />
    <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white mt-4">

    <div class="block w-full overflow-x-auto">
    <table class="items-center w-full bg-transparent border-collapse">
        <thead>
        <tr>
            <th
            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
            :class="'bg-blueGray-50 text-blueGray-500 border-blueGray-100'"
            >
            {{ t('site.Chapter') }}
            </th>
            <th
            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
            :class="'bg-blueGray-50 text-blueGray-500 border-blueGray-100'"
            >
            {{ t('common.title') }}
            </th>
            <th
            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
            :class="'bg-blueGray-50 text-blueGray-500 border-blueGray-100'"
            >
            {{ t('common.text') }}
            </th>
            <th
            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
            :class="'bg-blueGray-50 text-blueGray-500 border-blueGray-100'"
            >
            {{ t('common.Read') }}
            </th>
            <th
            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
            :class="'bg-blueGray-50 text-blueGray-500 border-blueGray-100'"
            >
            {{ t('common.Like') }}
            </th>
            <th
            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
            :class="'bg-blueGray-50 text-blueGray-500 border-blueGray-100'"
            >
            {{ t('common.RegistDate') }}
            </th>
            <th
            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
            :class="'bg-blueGray-50 text-blueGray-500 border-blueGray-100'"
            ></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(article,index) in pageinfo.articles" :key="`article_list_${index}`">
            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
            {{ fnChapterName(article.chapterId) }}
            </td>
            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
            <a href="#" @click="fnViewArticle(article.id)" class="ml-3 font-bold light">{{ article.title }}</a>
            </td>
            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
            {{ article.content.length }}
            </td>
            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
            {{ article.readCount }}
            </td>
            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
            {{ article.likeCount }}
            </td>
            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
            {{ dayjs.utc(article.condition.registDate).local().format('YYYY-MM-DD HH:mm') }}
            </td>
            <td
            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right"
            >
            <TableDropdown>
                <button
                type="button"  @click="fnReviewWrite(article.id)"
                class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700 text-left"
                >
                {{ t('site.ReviewWrite') }}
                </button>
            </TableDropdown>
            </td>
        </tr>

        </tbody>
    </table>
    </div>
    <hr/>
    <div class="py-2 flex justify-center items-center">
        <Pagination :total-count="pageinfo.totalcount" :cur-page="pageinfo.curpage" :callback="fnPaging" />
    </div>
    <div class="p-4">
      <div class="w-full flex justify-between">
        <div v-if="pageinfo.userinfo.id !== pageinfo.book.ownerId">
          <button @click="fnCreateBook" class="px-4 py-2 uppercase mainbg text-white font-semibold rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-opacity-50" type="button">{{ t('site.ReviewWrite') }}</button>
        </div>
        <div>
          <button @click="fnBookList" class="px-4 py-2 uppercase bg-blue-500 text-white font-semibold rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-opacity-50" type="button">{{ t('site.reviewList') }}</button>
        </div>
      </div>
    </div>
</div>
</div>
</template>

<script setup lang="ts">
import { Pagination,TableDropdown,Loading,Bookinfo } from '@/components';
import { onMounted, ref, defineProps } from 'vue';
import { ArticleRepository,MemberRepository } from '@/repositories';
import { Article, Book, Chapter, Member } from '@/entities';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import localizedFormat from 'dayjs/plugin/localizedFormat'; 

dayjs.extend(utc);
dayjs.extend(localizedFormat);

const articleRep = new ArticleRepository();
const memberRep = new MemberRepository();
const router = useRouter();
const { t, locale } = useI18n();

interface bookConfigProperty {
    bookid?:string|null|undefined
}

const property = defineProps<bookConfigProperty>();

var pageinfo = ref({
    key:0,
    isLoading:true,
    book:new Book(),
    userinfo:new Member(),
    chapters:[] as Chapter[],
    articles:[] as Article[],
    totalcount:0,
    curpage:1
});

onMounted(async() => {
  await getUser();
    await GetChapters();
    await GetBookInfo();
    await GetArticles();
    pageinfo.value.isLoading = false;
    pageinfo.value.key += 1;
});

const getUser = async () => {
    let rst = await memberRep.GetUser();
    if (rst.check && rst.data !== null) {
      pageinfo.value.userinfo = rst.data;
    }
  };

  const GetBookInfo = async () => {
    if (property.bookid !== null && property.bookid !== undefined && property.bookid.trim() !== '') {
      let rst = await articleRep.GetBook(property.bookid);
      if (rst.check) {
        pageinfo.value.book = rst.data;
        pageinfo.value.key += 1;
      }
    }
  };

  const GetChapters = async () => {
    if (property.bookid !== null && property.bookid !== undefined && property.bookid.trim() !== '') {
      let rst = await articleRep.GetChapters(property.bookid);
      if (rst.check) {
        pageinfo.value.chapters = rst.data;
        pageinfo.value.key += 1;
      }
    }
  };

  const GetArticles = async () => {
    if (property.bookid !== null && property.bookid !== undefined && property.bookid.trim() !== '') {
      let rst = await articleRep.GetArticles(property.bookid);
      if (rst.check) {
        pageinfo.value.articles = rst.data;
        pageinfo.value.key += 1;
      }
    }
  };

const fnPaging = async (curpage:number) => {
    pageinfo.value.curpage = curpage;

};

const fnCreateBook = () => {
    router.push(`/review/book/form/${pageinfo.value.book.id}`);
};

const fnViewArticle = (articleId:string) => {
  router.push(`/review/${property.bookid}/view/${articleId}`);
};

const fnReviewWrite = (articleId:string) => {
    router.push(`/review/${pageinfo.value.book.id}/article/${articleId}/form`);
};

const fnChapterName = (chapterId:string):string => {
    let result = "";

    if (pageinfo.value.chapters !== null && pageinfo.value.chapters !== undefined && pageinfo.value.chapters.length > 0) {
        let chapter = pageinfo.value.chapters.filter(x => x.id === chapterId)[0];
        if (chapter !== null && chapter !== undefined) {
            result = chapter.title;
        }
    }

    return result;
};

const fnBookList = () => {
  router.push('/review/list');
};

</script>