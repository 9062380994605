import { ChatLog, ChatLogItem } from '@/models';

// State 타입 정의
interface ChatLogState {
  chatlogs: ChatLog[];
}

// 초기 상태 정의
const state: () => ChatLogState = () => ({
  chatlogs: JSON.parse(localStorage.getItem('chatlogs') || '[]'),
});

// Getters
const getters = {
  getLogById: (state: ChatLogState) => (id: string): ChatLog | undefined => {
    return state.chatlogs.find(log => log.id === id);
  },
};

// Mutations
const mutations = {
  addLog(state: ChatLogState, { id, message, owner }: { id: string; message: string; owner: string }) {
    let log = state.chatlogs.find(log => log.id === id);
    if (!log) {
      log = new ChatLog();
      log.id = id;
      state.chatlogs.push(log);
    }
    let item = new ChatLogItem();
    item.message = message;
    item.owner = owner;
    log.logs.push(item);
    // 상태를 로컬 스토리지에 저장
    localStorage.setItem('chatlogs', JSON.stringify(state.chatlogs));
  },
  removeLog(state: ChatLogState, { id, index }: { id: string; index: number }) {
    const log = state.chatlogs.find(log => log.id === id);
    if (log) {
      log.logs.splice(index, 1);
      // 상태를 로컬 스토리지에 저장
      localStorage.setItem('chatlogs', JSON.stringify(state.chatlogs));
    }
  },
  clearLog(state: ChatLogState, id: string) {
    const log = state.chatlogs.find(log => log.id === id);
    if (log) {
      log.logs = [];
      // 상태를 로컬 스토리지에 저장
      localStorage.setItem('chatlogs', JSON.stringify(state.chatlogs));
    }
  },
};

// Actions
const actions = {
  addLog({ commit }: { commit: Function }, { id, message, owner }: { id: string; message: string; owner: string }) {
    commit('addLog', { id, message, owner });
  },
  removeLog({ commit }: { commit: Function }, { id, index }: { id: string; index: number }) {
    commit('removeLog', { id, index });
  },
  clearLog({ commit }: { commit: Function }, id: string) {
    commit('clearLog', id);
  },
};

// 모듈 내보내기
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
