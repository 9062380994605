<template>
<img :src="componentInfo.url" alt="Picture" class="w-full object-cover" style="height:300px;" @click="fnUploadImage" :key="`bgimg_${componentInfo.key}`">
<ImageManager
      :isOpen="componentInfo.isImageManagerOpen"
      @close="closeImageManager"
      @imageSelected="handleImageSelected"
    />
</template>

<script setup lang="ts">
import { defineEmits, defineProps,ref,onMounted } from 'vue';
import { ImageManager } from '@/components';

const props = defineProps<{
    url:string|null|undefined,
    width:number|null|undefined,
    height:number|null|undefined
}>();
const emits = defineEmits(['imageSelected']);

var componentInfo = ref({
    key:0,
    url:"",
    width:1000,
    height:300,
    isImageManagerOpen :false
});

onMounted(() => {
    if (props.width !== null && props.width !== undefined) {
        componentInfo.value.width = props.width;
    }
    if (props.height !== null && props.height !== undefined) {
        componentInfo.value.height = props.height;
    }
    componentInfo.value.url = getImage(props.url);
    componentInfo.value.key += 1;
});

const closeImageManager = () => {
    componentInfo.value.isImageManagerOpen = false;
};

// Function to generate Gravatar URL
function getImage(src: string|null|undefined): string {
    if (src !== null && src !== undefined && String(src).trim() !== '') {
        return src;
    } else {
        return `https://via.placeholder.com/${componentInfo.value.width}x${componentInfo.value.height}`;
    }
}

const fnUploadImage = () => {
    componentInfo.value.isImageManagerOpen = true;
};

const handleImageSelected = (image:string) => {
    console.log('handleImageSelected : ', image)
    emits('imageSelected', image);
    componentInfo.value.url = image;
    componentInfo.value.key += 1;
};
</script>
