import { Chapter } from "./Chapter";
import { Condition } from "./Condition";
import { UsePlatform } from "./UsePlatform";

export class Book {
    public id:string;
    public title:string;
    public description:string;
    public keywords:string;
    public cover:string;
    public thumbnail:string;
    public likeCount:number;
    public favoriteCount:number;
    public reviewCount:number;
    public majorCategoryId:string;
    public minorCategoryId:string;
    public ownerId:string;
    public siteId:string;
    public writerId:string;
    public tags:string;
    public synopsis:string;
    public plannedCount:number;
    public isReviewOpen:boolean;
    public isPublish:boolean;
    public isUseAssistant:boolean;
    public articleCount:number;

    public chapters:Chapter[];
    public usePlatforms:UsePlatform[];
    public condition:Condition;

    constructor() {
        this.id = "";
        this.title = "";
        this.description = "";
        this.keywords = "";
        this.cover = "";
        this.thumbnail = "";
        this.likeCount = 0;
        this.favoriteCount = 0;
        this.reviewCount = 0;
        this.majorCategoryId = "";
        this.minorCategoryId = "";
        this.ownerId = "";
        this.siteId = "";
        this.writerId = "";
        this.tags = "";
        this.synopsis = "";
        this.plannedCount = 0;
        this.isReviewOpen = false;
        this.isPublish = false;
        this.isUseAssistant = false;
        this.articleCount = 0;
        
        this.chapters = [] as Chapter[];
        this.usePlatforms = [] as UsePlatform[];
        this.condition = new Condition();
    }
}