import { Condition } from "./Condition";

export class UsePlatform {
    public id:string;
    public name:string;
    public url:string;
    public seq:number;
    public bookId:string;
    public condition:Condition;

    constructor() {
        this.id = "";
        this.name = "";
        this.url = "";
        this.seq = 1;
        this.bookId = "";
        this.condition = new Condition();
    }
}