import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { LoginView,MainView,BookConfig,MemoList,ReviewList,RegistView } from '@/views';
import { BoardList,ResetPassword,Books,Contact,Privacy,Terms,Reviews,ReviewBook,ReviewBookForm,MyReviewList,BookReviewList } from '@/views';
import { AiUseDocument,Profile,PasswordChangeView,MemberShip,AccountSettings,MemoForm,BookReviewViewer,BookContent } from '@/views';
import { AuthCheck,AuthClear,AuthRefresh,BookList,ArticleManager,ArticleForm,HomeView,BookContentView,PublishList } from '@/views';
import { BoardView,BoardForm,StoryManager,ReviewContentView,ReviewArticleForm,SearchList,Youtube,Payment,CardRegist } from '@/views';
import { AIReview,AITrend,AITalk,PaymentResponse } from '@/views';
import { AuthLayout,AdminLayout } from '@/layouts';

const routes: Array<RouteRecordRaw> = [
  { path: '/signin-oidc',name: 'signin',component: AuthCheck },
  { path: '/signout-oidc',name: 'signout1',component: AuthClear },
  { path: '/signout-callback-oidc',name: 'signout2',component: AuthClear },
  { path: '/silent-renew-oidc',name: 'signrefresh',component: AuthRefresh },
  { path:'/book/article/:bookid', name: 'articleform',component:ArticleForm, props:true },
  { path:'/book/article/:bookid/:articleid', name: 'articleedit',component:ArticleForm, props:true },
  { path: '/', name: 'home',component: () => import('../layouts/Empty.vue'), children: [ 
    { path:'/', component:HomeView },
    { path:'/Books', component:Books },
    { path:'/Contact', component:Contact },
    { path:'/Privacy', component:Privacy },
    { path:'/Terms', component:Terms },
    { path:'/Reviews', component:Reviews },
    { path:'/guide/aiuse', component:AiUseDocument },
    { path:'/guide/youtube', component:Youtube },
  ] },
  { path: '/login', name: 'login',component: AuthLayout, children: [ 
    { path:'/login', component:LoginView },
    { path:'/regist', component:RegistView },
    { path:'/passwordreset', component:ResetPassword }
  ] },
  { path: '/main', name: 'main',component: AdminLayout, children: [ 
    { path:'/main', component:MainView },
    { path:'/memo/list', component:MemoList },
    { path:'/memo/form', component:MemoForm },
    { path:'/memo/form/:memoid', component:MemoForm, props:true },
    { path:'/memo/:bookid/list', component:MemoList, props:true },
    { path:'/memo/:bookid/form', component:MemoForm, props:true },
    { path:'/memo/:bookid/form/:memoid', component:MemoForm, props:true },
    { path:'/book/list', component:BookList },
    { path:'/review/list', component:ReviewList },
    { path:'/book/publish/list', component:PublishList },
    { path:'/book/publish/:bookid', component:BookContent, props:true },
    { path:'/book/:bookid/view/:articleId', component:BookContentView, props:true },
    { path:'/review/book/:bookid', component:ReviewBook, props:true },
    { path:'/review/:bookid/view/:articleId', component:ReviewContentView, props:true },
    { path:'/myreview/list', component:MyReviewList },
    { path:'/review/book/form/:bookid', component:ReviewBookForm, props:true },
    { path:'/review/:bookid/article/:articleId/form', component:ReviewArticleForm, props:true },
    { path:'/review/:bookid/article/:articleId/form/:reviewId', component:ReviewArticleForm, props:true },
    { path:'/review/book/:bookid/edit/:reviewId', component:ReviewBookForm, props:true },
    { path:'/book/:bookid/reviews', component:BookReviewList, props:true },
    { path:'/book/:bookid/review/:reviewId', component:BookReviewViewer, props:true },
    { path:'/book/articles/:bookid', component:ArticleManager, props:true },
    
    { path:'/book/settings', component:BookConfig },
    { path:'/book/story/:bookid', component:StoryManager, props:true },
    { path:'/book/settings/:bookid', component:BookConfig, props:true }
  ] },
  { path: '/boards', name: 'boards',component: AdminLayout, children: [ 
    { path:'/board/list/:code', component:BoardList, props:true },
    { path:'/board/:code/view/:id', component:BoardView, props:true },
    { path:'/board/:code/form/:id', component:BoardForm, props:true },
    { path:'/board/:code/form', component:BoardForm, props:true }
  ] },
  { path: '/agent', name: 'aiagent',component: AdminLayout, children: [ 
    { path:'/agent/talk', component:AITalk, props:true },
    { path:'/agent/trend', component:AITrend, props:true },
    { path:'/agent/review', component:AIReview, props:true }
  ] },
  { path: '/search', name: 'search',component: AdminLayout, children: [ 
    { path:'/search', component:SearchList, props:true }
  ] },
  { path: '/document', name: 'documents',component: AdminLayout, children: [ 
    { path:'/document/aiuse', component:AiUseDocument },
    { path:'/document/youtube', component:Youtube },
  ] },
  { path: '/account', name: 'accounts',component: AdminLayout, children: [ 
    { path:'/account/profile', component:Profile },
    { path:'/account/password', component:PasswordChangeView },
    { path:'/account/membership', component:MemberShip },
    { path:'/account/settings', component:AccountSettings },
    { path:'/account/payment', component:Payment },
    { path:'/account/card/regist', component:CardRegist },
    { path:'/payment/response', component:PaymentResponse },
  ] },
]

const router = createRouter({
  history: createWebHistory(""),
  routes
})

export default router
