<template>
<div class="relative flex items-center">
<!-- Left Button -->
<button @click="scrollLeft" class="absolute left-0 top-1/2 transform -translate-y-1/2 bg-transparent border-none text-pink-600 text-2xl p-2 z-10 focus:outline-none">
  <i class="fas fa-chevron-left"></i>
</button>
<!-- Scrolling Container -->
<div ref="scrollContainer" class="overflow-x-auto scroll-smooth whitespace-nowrap py-2 flex scrollbar-custom w-full">
  <div v-for="(work, index) in works" :key="index" class="inline-block w-full sm:w-1/2 md:w-1/3 lg:w-1/4 px-2" @click="fnView(work)">
  <div class="bg-white shadow-lg rounded-lg p-4 h-full flex flex-col">
      <img :src="fnCover(work)" alt="Cover Image" class="w-full h-48 object-cover rounded-t-lg mb-4">
      <h3 class="text-xl font-semibold mb-2 truncate">{{ work.title }}</h3>
      <p class="text-gray-600 truncate">{{ work.description }}</p>
  </div>
  </div>
</div>
<!-- Right Button -->
<button @click="scrollRight" class="absolute right-0 top-1/2 transform -translate-y-1/2 bg-transparent border-none text-pink-600 text-2xl p-2 z-10 focus:outline-none">
  <i class="fas fa-chevron-right"></i>
</button>
</div>
</template>

<script setup lang="ts">
import { Book } from '@/entities';
import { ref, defineProps, onMounted,computed } from 'vue';
import { MessageBox, ValidateHelper } from '@/utility';
import { AuthService } from '@/services';
import router from '@/router';

const props = defineProps<{
  works: Array<Book>
}>();

const auth = new AuthService();
const scrollContainer = ref<HTMLElement | null>(null);
const validate = new ValidateHelper();
const itemWidth = window.innerWidth / 3; // Width of the container divided by 3
const scrollAmount = itemWidth; // Scroll by one item width

const scrollLeft = () => {
  if (scrollContainer.value) {
    scrollContainer.value.scrollBy({
      left: -scrollAmount,
      behavior: 'smooth'
    });
  }
};

const scrollRight = () => {
  if (scrollContainer.value) {
    scrollContainer.value.scrollBy({
      left: scrollAmount,
      behavior: 'smooth'
    });
  }
};

const fnCover = (book:Book):string => {
  let result = "https://via.placeholder.com/180x260";
  if (validate.IsNullOrWhiteSpace(book.cover) === false) {
    result = book.cover;
  } else if (validate.IsNullOrWhiteSpace(book.thumbnail) == false) {
    result = book.thumbnail;
  }

  return result;
};

const fnView = async (book:Book) => {
  var user = await auth.getUser();
  if (!validate.IsNullOrWhiteSpace(user?.access_token)) {
    router.push(`/book/publish/${book.id}`);
  } else {
    MessageBox.Confirm("로그인이 필요합니다. 로그인하시겠습니까?", () => {
      router.push(`/login`);
    });
  }
};

onMounted(async () => {
  if (scrollContainer.value) {
    scrollContainer.value.style.scrollBehavior = 'smooth';
  }
});
</script>

<style scoped>
.scroll-smooth {
  scroll-behavior: smooth;
}

/* Custom scrollbar styling */
.scrollbar-custom::-webkit-scrollbar {
  height: 8px; /* Scrollbar height */
}

.scrollbar-custom::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3); /* Scrollbar color */
  border-radius: 10px; /* Scrollbar rounded corners */
}

.scrollbar-custom::-webkit-scrollbar-track {
  background: transparent; /* Track color */
}

button {
  cursor: pointer;
  border-radius: 50%;
  transition: background-color 0.3s ease;
  width: 36px; /* Button width */
  height: 36px; /* Button height */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  z-index: 10;
}

button:hover {
  background-color: rgba(255, 182, 193, 0.2); /* Light pink background on hover */
}

/* Truncate text with ellipsis */
.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

img {
  object-fit: cover;
}
</style>
