<template>
  <Loading :loading="pageinfo.isloading" />
  <div class="min-h-screen bg-gray-50 flex flex-col items-center" :key="pageinfo.key" v-show="pageinfo.isReady">
    <!-- 상품 정보 -->
    <div class="bg-white shadow-lg rounded-lg p-6 mt-8 w-full max-w-md">
      <h2 class="text-2xl font-semibold text-gray-800">{{ pageinfo.order.memberShip.title }} Membership</h2>
      <p v-if="pageinfo.order.memberShip.title === 'Basic'" class="text-gray-600 mt-2">상품 설명: AI를 제외한 거의 모든 기능을 사용할 수 있습니다.  특별히 AI가 필요하신게 아니라면, 이것으로 충분합니다.</p>
      <p v-if="pageinfo.order.memberShip.title === 'Premium'" class="text-gray-600 mt-2">상품 설명: 자신이 가진 공개된 AI키(Gemini, GPT)를 이용하실 수 있습니다.  하지만, RAG등 최적화된 기능은 사용할 수 없습니다.</p>
      <p v-if="pageinfo.order.memberShip.title === 'Professional'" class="text-gray-600 mt-2">상품 설명: 당사에서 제공하는 AI를 사용해 RAG등 최신 기술을 이용할 수 있습니다.</p>
    </div>

    <!-- 구독 결제 안내 -->
    <div class="bg-white shadow-lg rounded-lg p-6 mt-6 w-full max-w-md" v-if="pageinfo.order.memberShip.isFree">
      <h3 class="text-lg font-semibold text-gray-700">무료 이용 안내</h3>
      <p class="text-gray-600 mt-2">
        무료 이용은 자동결제가 지원되지 않으며, 기간이 종료되면 직접 갱신해야 합니다.
      </p>
    </div>
    <div class="bg-white shadow-lg rounded-lg p-6 mt-6 w-full max-w-md" v-else>
      <h3 class="text-lg font-semibold text-gray-700">구독 결제 안내</h3>
      <p class="text-gray-600 mt-2">
        구독 결제는 매월 자동으로 결제되며, 언제든지 해지할 수 있습니다. 구독 해지 시 환불이
        불가능할 수 있습니다.
      </p>
      <a href="#" class="text-sm text-blue-600 hover:underline mt-2 inline-block">
        환불 및 구독 해지에 관한 안내
      </a>
      <a href="#" class="text-sm text-blue-600 hover:underline mt-1 inline-block">
        법적 고지사항
      </a>
    </div>

    <!-- 결제 정보 -->
    <div class="bg-white shadow-lg rounded-lg p-6 mt-6 w-full max-w-md">
      <h4 class="text-xl font-semibold text-gray-800">결제 금액</h4>
      <p class="text-3xl font-bold text-red-500 mt-2">{{ StringHelper.formatNumberWithCommas(pageinfo.order.memberShip.price) }} 원 <small>(부가세 포함)</small></p>

      <div class="max-w-md mx-auto mt-6 space-y-6">
    <!-- 카드번호 입력 필드 -->
    <div>
      <label for="couponNo" class="block text-sm font-medium text-gray-700">
        카드번호 입력 <small class="text-gray-500">(숫자만 입력)</small>
      </label>
      <input
        v-model="pageinfo.card.cardNo"
        type="text"
        id="couponNo"
        class="mt-2 block w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500 transition ease-in-out duration-150"
        placeholder="1234 5678 9012 3456"
      />
    </div>

    <!-- 유효기간 및 비밀번호 -->
    <div class="flex space-x-4">
      <div class="w-1/2">
        <label class="block text-sm font-medium text-gray-700">유효기간</label>
        <div class="flex space-x-2 mt-2">
          <input
            v-model="pageinfo.card.expMonth"
            type="text"
            class="block w-full px-3 py-2 border border-gray-300 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500 transition ease-in-out duration-150"
            placeholder="MM"
          />
          <span class="text-gray-500 flex items-center">/</span>
          <input
            v-model="pageinfo.card.expYear"
            type="text"
            class="block w-full px-3 py-2 border border-gray-300 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500 transition ease-in-out duration-150"
            placeholder="YY"
          />
        </div>
      </div>

      <div class="w-1/2">
        <label class="block text-sm font-medium text-gray-700">비밀번호</label>
        <input
          v-model="pageinfo.card.cardPw"
          type="text"
          class="mt-2 block w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500 transition ease-in-out duration-150"
          placeholder="앞 2자리"
        />
      </div>
    </div>
  </div>
      <div class="mt-4">
        <div class="max-w-md mx-auto mt-8">
          <div class="flex border-b-2 border-gray-300">
            <button
              :class="[
                'flex-1 py-2 text-center text-lg font-semibold',
                pageinfo.activeTab === 'personal' ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-600'
              ]"
              @click="pageinfo.activeTab = 'personal'"
            >
              개인
            </button>
            <button
              :class="[
                'flex-1 py-2 text-center text-lg font-semibold',
                pageinfo.activeTab === 'business' ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-600'
              ]"
              @click="pageinfo.activeTab = 'business'"
            >
              기업
            </button>
          </div>

          <div class="p-4">
            <div v-if="pageinfo.activeTab === 'personal'">
              <label class="block text-gray-700 text-sm font-bold mb-2" for="personal-id">
                주민등록번호 앞자리
              </label>
              <input
                type="text"
                id="personal-id"
                v-model="pageinfo.personalNo"
                maxlength="6"
                class="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="앞자리 6자리 입력"
              />
            </div>

            <div v-else-if="pageinfo.activeTab === 'business'">
              <label class="block text-gray-700 text-sm font-bold mb-2" for="business-id">
                사업자등록번호
              </label>
              <input
                type="text"
                id="business-id"
                v-model="pageinfo.businessNo"
                maxlength="10"
                class="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="사업자등록번호 10자리 입력"
              />
            </div>
          </div>
        </div>
      </div>


      <button @click="fnPayment"
        class="mt-6 w-full bg-blue-600 text-white font-semibold py-3 rounded-md shadow-md hover:bg-blue-700 transition"
      >
        결제하기
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ref, onMounted } from 'vue';
  import { useRoute,useRouter } from 'vue-router';
  import { MemberRepository } from '@/repositories';
  import { Order } from '@/entities';
  import { CardInfo } from '@/models';
  import { MessageBox, StringHelper,ValidateHelper } from '@/utility';
  import { Loading } from '@/components';
  
  const route = useRoute();
  const router = useRouter();
  const memberRep = new MemberRepository();
  const validate = new ValidateHelper();

  var pageinfo = ref({
    key:0,
    isReady:false,
    orderno:'',
    personalNo:'',
    businessNo:'',
    order:new Order(),
    card:new CardInfo(),
    isloading:true,
    activeTab:'personal'
  });
  
  onMounted(async () => {
    pageinfo.value.orderno = route.query.orderno as string;
    await getOrder(pageinfo.value.orderno);
    pageinfo.value.isReady = true;
    pageinfo.value.isloading = false;
  });

  const getOrder = async (orderCode:string) => {
    let rst = await memberRep.GetOrder(orderCode);
    console.log('getOrder : ', rst.data)
    if (rst.check && rst.data !== null) {
      pageinfo.value.order = rst.data;
      
      if (pageinfo.value.order.memberShip.isFree) {
        MessageBox.Alert("해당 상품은 결제가 필요없는 무료 상품입니다.", () => {
          router.push(`/account/payment?productCode=${pageinfo.value.order.memberShip.title}`);
        });
      }
    }
  };

  const fnPayment = async () => {
    pageinfo.value.isloading = true;
    pageinfo.value.card.idNo = (pageinfo.value.activeTab === 'personal') ? pageinfo.value.personalNo : pageinfo.value.businessNo;
    let rst = await memberRep.PostNicePayCardRegist(pageinfo.value.orderno, pageinfo.value.card);
    pageinfo.value.isloading = false;
    if (rst.check) {
      MessageBox.Success("결제가 성공하였습니다.", () => {
        router.push(`/account/membership`);
      });
    } else {
      MessageBox.Alert(rst.message);
    }
  };
</script>

<style scoped>
body {
  @apply bg-gray-50;
}
</style>
  