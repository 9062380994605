<template>
  <Loading :loading="!pageinfo.isReady" />
  <div class="flex flex-col items-left py-8 px-4 max-w-3xl mx-auto bg-opacity-10 rounded-lg">
    <h1 class="text-3xl font-bold mb-6 text-align-center">GN2STUDIO 이용약관</h1>
    
    <p class="mb-4">
      본 이용약관은 <b>GN2STUDIO</b> (이하 "서비스")의 이용에 대한 조건을 규정합니다. 서비스를 이용함으로써 본 약관에 동의하는 것으로 간주됩니다.
    </p>

    <h2 class="text-2xl font-semibold mb-3 mt-3">1. 서비스 개요</h2>
    <p class="mb-4">
      서비스는 사용자에게 소설 집필 및 커뮤니티 기능을 제공하며, 유료 구독 서비스를 포함합니다.<br/>
      사용자는 구독을 통해 프리미엄 기능에 접근할 수 있으며, 해당 기능에 대한 자세한 내용은 서비스 내에서 안내됩니다.
    </p>

    <h2 class="text-2xl font-semibold mb-3 mt-3">2. 개인정보 및 데이터 관리</h2>
    <p class="mb-4">
      서비스는 사용자의 <span class="font-semibold">이메일</span> 외에 어떠한 개인정보도 수집하지 않습니다. <br/>
      이메일은 주로 계정 인증과 서비스 관련 공지에 사용됩니다.<br/>
       사용자는 서비스 내에서 추가적인 개인정보를 입력해서는 안 됩니다. <br/>
       개인정보 보호정책은 별도로 제공되며, 이를 준수해야 합니다.
    </p>

    <h2 class="text-2xl font-semibold mb-3 mt-3">3. 구독 및 결제</h2>
    <p class="mb-4">
      서비스는 유료 구독 모델을 통해 프리미엄 기능을 제공합니다. <br/>
      당월 구독 비용은 환불되지 않으며, 구독 중에는 서비스 이용이 가능하지만, 구독 종료 시 프리미엄 기능의 접근이 제한됩니다.
    </p>
    <p class="mb-4">
      쿠폰을 통해 제공되는 혜택은 환불되지 않으며, 사용 후에는 재사용이 불가합니다.
    </p>

    <h2 class="text-2xl font-semibold mb-3 mt-3">4. 사용자 콘텐츠 및 저작권</h2>
    <p class="mb-4">
      사용자가 서비스에 업로드하는 모든 콘텐츠(이미지, 글 등)에 대한 저작권 및 책임은 전적으로 사용자에게 있습니다. <br/>
      사용자는 반드시 본인이 저작권을 보유한 콘텐츠만을 업로드해야 하며, 서비스는 사용자 콘텐츠에 대해 어떠한 법적 책임도 지지 않습니다.
    </p>
    <p class="mb-4">
      타인의 저작권을 침해하는 행위는 엄격히 금지되며, 이를 위반할 경우 법적 책임이 사용자에게 귀속됩니다. <br/>
      또한, 저작권이 있는 글이나 이미지를 허락 없이 게시할 수 없습니다.
    </p>

    <h2 class="text-2xl font-semibold mb-3 mt-3">5. 계정 탈퇴 및 데이터 보유</h2>
    <p class="mb-4">
      사용자는 언제든지 서비스에서 회원 탈퇴를 요청할 수 있습니다. <br/>
      탈퇴 후 사용자의 계정 정보는 3개월간 보유되며, 이 기간 내에는 탈퇴를 철회하고 계정을 복구할 수 있습니다. <br/>
      3개월 이후에는 계정 정보가 완전히 삭제됩니다.
    </p>

    <h2 class="text-2xl font-semibold mb-3 mt-3">6. 서비스 이용 제한</h2>
    <p class="mb-4">
      서비스는 사용자의 부적절한 행위(예: 불법 콘텐츠 업로드, 타인 비방, 저작권 침해 등)에 대해 서비스 이용을 제한하거나 계정을 삭제할 권리를 보유합니다. <br/>
      이러한 경우, 사용자는 서비스에 대해 어떠한 이의도 제기할 수 없습니다.
    </p>

    <h2 class="text-2xl font-semibold mb-3 mt-3">7. 면책 조항</h2>
    <p class="mb-4">
      서비스는 사용자의 콘텐츠나 정보에 대해 법적 책임을 지지 않으며, 사용자가 본인의 책임 하에 콘텐츠를 생성하고 관리해야 합니다.<br/>
      서비스는 사용자의 부주의로 발생한 정보 유출이나 저작권 침해에 대해 관여하지 않습니다.
    </p>

    <h2 class="text-2xl font-semibold mb-3 mt-3">8. 약관의 수정</h2>
    <p class="mb-4">
      서비스는 언제든지 본 약관을 수정할 수 있으며, 중요한 변경 사항이 있을 경우 사용자에게 공지할 것입니다. <br/>
      수정된 약관은 공지 후 효력을 발휘하며, 사용자는 이를 준수해야 합니다.
    </p>

    <h2 class="text-2xl font-semibold mb-3 mt-3">9. 문의</h2>
    <p class="mb-4">
      이용약관에 대한 문의사항은 언제든지 서비스 고객지원팀에 연락해주시기 바랍니다.<br/>
      <a href="mailto:webmaster@gn2studio.com">webmaster@gn2studio.com</a>
    </p>
    

  </div>
</template>


<script setup lang="ts">
import { Loading } from "@/components";
import { onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';

const { t, locale } = useI18n();
var pageinfo = ref({
  isReady:false
});

onMounted(() => {
  pageinfo.value.isReady = true;
});

</script>