import { Condition } from "./Condition";

export class Memo {
    public id:string;
    public bookId:string;
    public title:string;
    public content:string;
    public condition:Condition;

    constructor() {
        this.id = "";
        this.bookId = "";
        this.title = "";
        this.content = "";
        this.condition = new Condition();
    }
}