import config from "@/config";
import { ReturnValues } from "@/models";
import { ApiHelper } from "@/utility"
import { ValidateHelper } from "@/utility";

const checker = new ValidateHelper();

export class TranslationRepository {
    private slug:string = "studio";

    async GetTranslations(languageCode:string):Promise<ReturnValues> {
        if (checker.IsNullOrWhiteSpace(languageCode))
        {
            languageCode = "en";
        }
        let url = `${config.apis.translation}/api/Translation/Site/${this.slug}/${languageCode}?version=4`;
        //console.log('TranslationRepository GetTranslations : ', url);
        return await ApiHelper.GetAsync(url);
    }

    async GetLanguages():Promise<ReturnValues> {
        let url = `${config.apis.translation}/api/Translation/Site/${this.slug}`;
        //console.log('TranslationRepository GetLanguages : ', url);
        return await ApiHelper.GetAsync(url);
    }

}
