import { MemberShip } from "./MemberShip";

export class Permission {
    public WorkCount:number;
    public IsReview:boolean;
    public AITalk:boolean;
    public AutoPay:boolean;
    public Trend:boolean;
    public Assistant:boolean;
    public Analysis:boolean;
    public AiReview:boolean;

    constructor() {
        this.WorkCount = 0;
        this.IsReview = false;
        this.AITalk = false;
        this.AutoPay = false;
        this.Trend = false;
        this.Assistant = false;
        this.Analysis = false;
        this.AiReview = false;
    }

    
    Aceept(membership:MemberShip) {
        if (membership !== null && membership !== undefined && String(membership.id).trim() !== '') {
            switch (membership.title) {
                case "Basic":
                    this.WorkCount = 100;
                    this.IsReview = true;
                    this.Analysis = true;
                    this.Assistant = false;
                    this.AITalk = false;
                    this.Trend = false;
                    this.AiReview = false;
                    this.AutoPay = false;
                    break;
                case "Premium":
                    this.WorkCount = 10000;
                    this.IsReview = true;
                    this.Analysis = true;
                    this.Assistant = true;
                    this.AITalk = false;
                    this.Trend = false;
                    this.AiReview = false;
                    this.AutoPay = false;
                    break;
                case "Professional":
                    this.WorkCount = 30000;
                    this.IsReview = true;
                    this.Analysis = true;
                    this.Assistant = true;
                    this.AITalk = true;
                    this.Trend = true;
                    this.AiReview = true;
                    this.AutoPay = true;
                    break;
            }
        }
    }
}