<template>
  <Loading :loading="!pageinfo.isReady" />

  <div class="w-full flex justify-between mb-1">
    <div class="ml-2">
      <h3 class="text-2xl subcolor uppercase"><b>{{ pageinfo.content.master.title }}</b></h3>
    </div>
    <div class="text-sm mr-2">
      <router-link to="/main" class="text-gray-400 uppercase text-sm"><small>HOME</small></router-link>
      <span class="px-2 text-gray-300">&gt;</span>
      <a href="#" class="text-gray-500 uppercase text-md"><b>{{ pageinfo.content.master.title }}</b></a>
    </div>
  </div>

  <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white" :key="pageinfo.key">
    <div class="p-6 bg-blueGray-50 text-blueGray-500 border-b border-blueGray-100">
      <h2 class="text-2xl font-semibold">{{ pageinfo.content.title }}</h2>
      <div class="text-sm text-blueGray-400 mt-2">
        <span class="mr-4">조회수: {{ pageinfo.content.readCount }}</span>
        <span>등록일: {{ dayjs.utc(pageinfo.content.condition.registDate).local().format('YYYY-MM-DD HH:mm') }}</span>
      </div>
    </div>
    <div class="p-6">
      <div class="text-blueGray-700 text-lg leading-relaxed" v-html="pageinfo.content.content"></div>
    </div>
    <hr/>
    <div class="py-2 flex justify-between px-2">
      <button @click="goList" class="px-4 py-2 uppercase bg-gray-400 text-white font-semibold rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-opacity-50" type="button">
        <i class="fa-solid fa-folder"></i> {{ t('common.List') }}
      </button>
      <div v-if="pageinfo.isOwner">
        <button @click="fnEdit" class="px-4 py-2 uppercase bg-blue-400 mr-2 text-white font-semibold rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-opacity-50" type="button">
          Edit
        </button>
        <button @click="fnDelete" class="px-4 py-2 uppercase bg-orange-400 text-white font-semibold rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-opacity-50" type="button">
          Delete
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { onMounted, ref,defineProps,watch,computed } from 'vue';
  import { Loading } from "@/components";
  import { BoardRepository,MemberRepository } from '@/repositories';
  import store from '@/store';
  import { BoardContent, Member } from '@/entities';
  import { MessageBox } from '@/utility';
  import { useRouter } from 'vue-router';
  import { BoardTypes } from '@/models';
  import { useI18n } from 'vue-i18n';
  import dayjs from 'dayjs';
  import utc from 'dayjs/plugin/utc';
  import localizedFormat from 'dayjs/plugin/localizedFormat'; 

  dayjs.extend(utc);
  dayjs.extend(localizedFormat);
  const { t, locale } = useI18n();
  const boardRep = new BoardRepository();
  const memberRep = new MemberRepository();
  const router = useRouter();
  
  interface BoardProperty {
    code?:string|null|undefined,
    id?:string|null|undefined
  }

  const property = defineProps<BoardProperty>();

  var pageinfo = ref({
    key:0,
    userinfo:new Member(),
    content:new BoardContent(),
    isReady:false,
    isOwner : false
  });

  onMounted(async() => {
    await getUser();
    await GetContent();
    pageinfo.value.key += 1;
    pageinfo.value.isReady = true;
    
  });

  const getUser = async () => {
    let rst = await memberRep.GetUser();
    if (rst.check && rst.data !== null) {
      pageinfo.value.userinfo = rst.data;
    }
  };

  watch(property, async () => {
    await GetContent();
    pageinfo.value.key += 1;
    pageinfo.value.isReady = true;
  });

  const GetContent = async () => {
    if (property.id !== null && property.id !== undefined) {
      let rst = await boardRep.GetContent(property.id);
      if (rst.check && rst.data !== null) {
        pageinfo.value.content = rst.data;
        pageinfo.value.isOwner = pageinfo.value.userinfo.id === pageinfo.value.content.ownerId;
      }
    }
  };


  const goList = () => {
    router.push(`/board/list/${pageinfo.value.content.master.masterCode}`);
  };

  const fnEdit = () => {
    router.push(`/board/${property.code}/form/${pageinfo.value.content.id}`);
  };

  const fnDelete = () => {
    MessageBox.Confirm(t('system.RemoveConfirm'), async () => {
      let rst = await boardRep.DeleteContent(pageinfo.value.content.id);
      if (rst.check) {
        MessageBox.Success(t('system.Deleted'), () => {
          router.push(`/board/list/${property.code}`);
        });
      }
    });
  };
</script>