<template>
  <Loading :loading="!pageinfo.isReady" />
  <div class="flex flex-col items-center py-8 px-4 w-full">
    <h2 class="text-4xl text-gray-600 noto-serif-kr mb-4">Publish Books</h2>
      <!-- Features Section -->
      <section class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 max-w-3xl w-full mb-8 mx-auto bg-opacity-0">
      <div class="flex flex-col items-center bg-pink-100 p-6 rounded-lg shadow-xl transition-transform transform hover:scale-105 overflow-hidden cursor" v-for="(book,index) in pageinfo.books" :key="`book_${index}`" @click="fnView(book)">
          <div class="absolute top-0 left-0 bg-red-500 text-white text-xs font-bold px-2 py-1 rounded-br-lg" v-if="book.isReviewOpen">
              Request Review
          </div>
          <img :src="fnCover(book)" alt="" class="w-full mb-2">
          <h3 class="text-xl font-semibold text-pink-700">{{ book.title }}</h3>
          <p class="text-gray-600 text-center p-3">{{ StringHelper.truncateText(book.description, 50) }}</p>
      </div>
      </section>
  </div>
</template>

<script setup lang="ts">
import { Loading } from "@/components";
import { Book } from '@/entities';
import { onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { ArticleRepository } from '@/repositories';
import { MessageBox, ValidateHelper,StringHelper } from '@/utility';
import { AuthService } from '@/services';
import router from '@/router';

const { t, locale } = useI18n();
const auth = new AuthService();
const articleRep = new ArticleRepository();
const validate = new ValidateHelper();

var pageinfo = ref({
  isReady:false,
  books:[] as Book[]
});

onMounted(async () => {
  await getOpenBooks();
  pageinfo.value.isReady = true;
});

const getOpenBooks = async () => {
  let rst = await articleRep.GetOpenBooks({ curPage : 1, pageSize : 10 });
  if (rst.check) {
    pageinfo.value.books = rst.data;
  }
};

const fnCover = (book:Book):string => {
  let result = "https://via.placeholder.com/180x260";
  if (validate.IsNullOrWhiteSpace(book.cover) === false) {
    result = book.cover;
  } else if (validate.IsNullOrWhiteSpace(book.thumbnail) == false) {
    result = book.thumbnail;
  }

  return result;
};

const fnView = async (book:Book) => {
  var user = await auth.getUser();
  if (!validate.IsNullOrWhiteSpace(user?.access_token)) {
    router.push(`/book/publish/${book.id}`);
  } else {
    MessageBox.Confirm("로그인이 필요합니다. 로그인하시겠습니까?", () => {
      router.push(`/login`);
    });
  }
};
</script>
