import { Condition } from "./Condition";
import { MemberShip } from "./MemberShip";

export class MyMemberShip {
    public id:string;
    public memberShipId:string;
    public memberShip:MemberShip;
    public memberId:string;
    public expiredDate:string;
    public orderNo:string;
    public condition:Condition;

    constructor() {
        this.id = "";
        this.memberShipId = "";
        this.memberShip = new MemberShip();
        this.memberId = "";
        this.expiredDate = "";
        this.orderNo = "";
        this.condition = new Condition();
    }
}

