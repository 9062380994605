<template>
    <Loading :loading="pageinfo.isLoading" />

    <div class="w-full flex justify-between mb-1">
    <div class="ml-2">
      <h3 class="text-2xl subcolor uppercase"><b>{{ t('site.Reviews') }}</b></h3>
    </div>
    <div class="text-sm mr-2">
      <router-link to="/main" class="text-gray-400 uppercase text-sm"><small>HOME</small></router-link>
      <span class="px-2 text-gray-300">&gt;</span>
      <router-link :to="`/book/articles/${pageinfo.book.id}`" class="text-gray-400 uppercase text-sm"><small>{{ t('site.Books') }}</small></router-link>
      <span class="px-2 text-gray-300">&gt;</span>
      <a href="#" class="text-gray-500 uppercase text-md"><b>{{ t('site.Reviews') }}</b></a>
    </div>
  </div>

    <div :key="pageinfo.key">
        <Bookinfo :book="pageinfo.book" />
    <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white mt-4">

    <div class="block w-full overflow-x-auto">
      <div class="w-full p-5"><h4 class="text-xl">{{ pageinfo.review.title }}</h4></div>
      <div class="w-full flex justify-between mt-3 mb-3 p-3 bg-gray-100" v-if="pageinfo.reviewer !== null && pageinfo.reviewer !== undefined">
      <h6><small>{{t('common.Owner')}} : </small> <b>{{ pageinfo.reviewer.nickName }}</b></h6>
      <span><small>{{ t('common.RegistDateTime') }} : </small>{{ dayjs.utc(pageinfo.review.condition.registDate).local().format('YYYY-MM-DD HH:mm') }}</span>
      </div>
      <div class="p-5 text-lg" v-html="pageinfo.review.content"></div>
    </div>
    <hr class="mt-4" />
    <div class="p-4">
      <div class="w-full flex justify-between">
        <div>
          <button @click="fnBookList" class="px-4 py-2 uppercase mainbg text-white font-semibold rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-opacity-50" type="button">{{t('site.gotoBook')}}</button>
          <button @click="fnReviewEdit" v-if="pageinfo.review.condition.createdBy === pageinfo.userinfo.id"
            class="ml-2 px-4 py-2 uppercase subbg text-white font-semibold rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-opacity-50" type="button">{{ t('site.EditReview') }}</button>
          <button @click="fnReviewDelete" v-if="pageinfo.review.condition.createdBy === pageinfo.userinfo.id"
            class="ml-2 px-4 py-2 uppercase bg-orange-200 text-white font-semibold rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-opacity-50" type="button">{{ t('site.ReviewDelete') }}</button>
        </div>
        <div>
          <button @click="fnReivewList" class="px-4 py-2 uppercase bg-blue-500 text-white font-semibold rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-opacity-50" type="button">{{ t('site.reviewList') }}</button>
        </div>
      </div>
    </div>
</div>
</div>
</template>

<script setup lang="ts">
import { Loading,Bookinfo } from '@/components';
import { onMounted, ref, defineProps } from 'vue';
import { ArticleRepository,MemberRepository } from '@/repositories';
import {  Book, Chapter, Member, PublicUser, Review } from '@/entities';
import { MessageBox } from '@/utility';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import localizedFormat from 'dayjs/plugin/localizedFormat'; 

dayjs.extend(utc);
dayjs.extend(localizedFormat);

const articleRep = new ArticleRepository();
const memberRep = new MemberRepository();
const router = useRouter();
const { t, locale } = useI18n();

interface bookConfigProperty {
    bookid?:string|null|undefined,
    reviewId?:string|null|undefined
}

const property = defineProps<bookConfigProperty>();

var pageinfo = ref({
    key:0,
    isLoading:true,
    book:new Book(),
    userinfo:new Member(),
    chapters:[] as Chapter[],
    review:new Review(),
    totalcount:0,
    reviewer:new PublicUser(),
    curpage:1
});

onMounted(async() => {
  await getUser();
    await GetChapters();
    await GetBookInfo();
    if (property.reviewId !== null && property.reviewId !== undefined) {
      await GetReview(property.reviewId);
    }
    pageinfo.value.isLoading = false;
    pageinfo.value.key += 1;
});

const getUser = async () => {
    let rst = await memberRep.GetUser();
    if (rst.check && rst.data !== null) {
      pageinfo.value.userinfo = rst.data;
    }
  };

  const GetReviewer = async () => {
    let rst = await memberRep.GetUserInfo(pageinfo.value.review.condition.createdBy);
    if (rst.check) {
      pageinfo.value.reviewer = rst.data;
      pageinfo.value.key += 1;
    }
  };

  const GetBookInfo = async () => {
    if (property.bookid !== null && property.bookid !== undefined && property.bookid.trim() !== '') {
      let rst = await articleRep.GetBook(property.bookid);
      if (rst.check) {
        pageinfo.value.book = rst.data;
        pageinfo.value.key += 1;
      }
    }
  };

  const GetChapters = async () => {
    if (property.bookid !== null && property.bookid !== undefined && property.bookid.trim() !== '') {
      let rst = await articleRep.GetChapters(property.bookid);
      if (rst.check) {
        pageinfo.value.chapters = rst.data;
        pageinfo.value.key += 1;
      }
    }
  };

  const GetReview = async (revieid:string) => {
    let rst = await articleRep.GetReview(revieid);
    if (rst.check) {
      pageinfo.value.review = rst.data;
      await GetReviewer();
      pageinfo.value.key += 1;
    }
  };

const fnReivewList = () => {
    router.push(`/book/${property.bookid}/reviews`);
};

const fnBookList = () => {
  router.push(`/review/book/${pageinfo.value.book.id}`);
};

const fnReviewEdit = () => {
  router.push(`/review/book/${pageinfo.value.book.id}/edit/${property.reviewId}`);
};

const fnReviewDelete = () => {
  MessageBox.Confirm(t('system.RemoveConfirm'), async () => {
    let rst = await articleRep.DeleteReview(pageinfo.value.review.id);
    if (rst.check) {
      MessageBox.Success(t('system.Deleted'), () => {
        router.push(`/review/book/${pageinfo.value.book.id}`);
      });
    }
  });
}
</script>