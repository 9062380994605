<template>
  <Loading :loading="!pageinfo.isReady" />
  <div class="flex flex-col items-left py-8 px-4 max-w-3xl mx-auto bg-opacity-10 rounded-lg">
    <h1 class="text-3xl font-bold mb-6 text-align-center">GN2STUDIO 개인정보 보호정책</h1>
    
    <hr />

    <p class="mt-4 mb-4 item-left">
      <b>GN2STUDIO</b> (이하 "서비스")는 사용자의 개인정보 보호를 매우 중요하게 생각합니다. <br/>
      본 개인정보 보호정책은 서비스가 수집하는 정보와 그 사용 방식에 대해 설명하며, 사용자의 개인정보가 안전하게 보호될 수 있도록 하는 방침을 명확히 합니다.
    </p>

    <h2 class="text-2xl font-semibold mb-3">1. 수집하는 개인정보의 종류</h2>
    <p class="mb-4">
      서비스는 사용자의 <span class="font-semibold">이메일</span>만을 수집합니다. <br/>
      이메일은 주로 사용자 계정 인증 및 서비스 관련 중요한 공지사항 전달을 위해 사용됩니다. <br/>
      그 외 어떠한 개인정보도 수집하지 않으며, 추가적인 정보 제공을 요구하지 않습니다.
    </p>

    <h2 class="text-2xl font-semibold mb-3">2. 개인정보의 수집 및 이용 목적</h2>
    <p class="mb-4">
      서비스는 수집된 이메일 정보를 다음과 같은 목적을 위해 사용합니다:
    </p>
    <ul class="list-disc list-inside mb-4">
      <li>사용자 계정 인증 및 보안 유지</li>
      <li>서비스 관련 공지사항 전달</li>
      <li>서비스 운영 및 개선</li>
      <li>유료 구독 서비스의 관리</li>
    </ul>

    <h2 class="text-2xl font-semibold mb-3 mt-3">3. 개인정보의 보관 및 보호</h2>
    <p class="mb-4">
      서비스는 사용자의 개인정보를 보호하기 위해 최신 보안 기술을 적용하고 있습니다. <br/>
      수집된 이메일 정보는 암호화되어 저장되며, 외부에 유출되지 않도록 철저한 보안 조치를 취하고 있습니다. <br/>
      다만, 서비스는 사용자가 사이트 내에 임의로 제공하는 개인정보에 대해서는 책임을 지지 않으며, 해당 정보의 제공을 강력히 권장하지 않습니다.
    </p>

    <h2 class="text-2xl font-semibold mb-3 mt-3">4. 개인정보의 제3자 제공</h2>
    <p class="mb-4">
      서비스는 사용자의 개인정보를 제3자에게 판매, 교환, 대여 또는 제공하지 않습니다. <br/>
      다만, 법적 요구가 있는 경우 또는 사용자의 동의가 있을 경우에 한해 관련 법령에 따라 정보를 제공할 수 있습니다.
    </p>

    <h2 class="text-2xl font-semibold mb-3 mt-3">5. 사용자의 권리</h2>
    <p class="mb-4">
      사용자는 언제든지 자신의 개인정보에 대한 접근, 수정, 삭제 요청을 할 수 있습니다. <br/>
      또한, 이메일 수신을 원하지 않을 경우 해당 서비스에서 구독을 해지할 수 있습니다.
    </p>

    <h2 class="text-2xl font-semibold mb-3 mt-3">6. 쿠키 및 추적 기술</h2>
    <p class="mb-4">
      서비스는 사용자의 경험을 향상시키기 위해 쿠키를 사용할 수 있습니다. <br/>
      쿠키는 웹사이트 방문 기록을 저장하고 분석하는 작은 데이터 파일로, 사용자의 선호도를 기억하는 데 사용됩니다. <br/>
      사용자는 브라우저 설정을 통해 쿠키 수집을 거부할 수 있으며, 이 경우 일부 서비스 이용에 제한이 있을 수 있습니다.
    </p>

    <h2 class="text-2xl font-semibold mb-3 mt-3">7. 개인정보 보호정책의 변경</h2>
    <p class="mb-4">
      서비스는 개인정보 보호정책을 수시로 변경할 수 있으며, 중요한 변경 사항이 있을 경우 이를 사용자에게 공지할 것입니다. <br/>
      변경된 정책은 공지와 동시에 효력이 발생합니다.
    </p>

    <h2 class="text-2xl font-semibold mb-3 mt-3">8. 문의</h2>
    <p class="mb-4">
      개인정보 보호정책에 대한 질문이나 요청사항이 있으시면 언제든지 저희에게 연락해 주시기 바랍니다.<br/>
      <a href="mailto:webmaster@gn2studio.com">webmaster@gn2studio.com</a>
    </p>
    


  </div>
</template>



<script setup lang="ts">
import { Loading } from "@/components";
import { onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';

const { t, locale } = useI18n();
var pageinfo = ref({
  isReady:false
});

onMounted(() => {
  pageinfo.value.isReady = true;
});

</script>